.u-pull {   
  @include bp('medium'){
    position: relative;
    z-index: 10;
  }

  &--strong {
    @include bp('medium'){
      margin-bottom: calc(#{$extension-height} * -1);
      margin-top: calc(#{$extension-height} * 0.13);
    }
  }

  &--weak {
    @include bp('medium'){ 
      margin-bottom: calc(#{$small-extension-height} / -1.5);
      margin-top: calc(220px / 1.5);
    }
  }
}