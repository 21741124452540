.c-products-filter {
  background-color: #f6f6f6;
  padding: 50px 0;
  position: relative;
  @include bp-down('medium'){
    padding: 0;
  }

  &__checks {
    margin-top: 17px;
    @include bp("medium") {
      margin-top: $minimal-margin;
      transform: translateY(9px);
      max-width: 96%;
      margin: 10px auto 0;
    }
  }

  &__response {
    @include bp-down('medium'){
      @include font(sm, bold, center);
      padding-top: 20px;
      padding-bottom: 20px;
      width: 90%;
      margin: auto;
    }
  }

  &__header {
    @include bp-down('medium'){
      margin: 0 auto 35px auto;
      padding-top: 35px;
    }
  }

  &__category-item {
    text-decoration: none;
    cursor: pointer;
    span {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      color: $color-black;
      text-decoration: none;
      background-color: #fff;
      padding: 56px 15px;
      min-height: 160px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      @include bp-down("medium") {
        font-size: 12px;
        line-height: 16px;
        padding: 15px 15px;
        min-height: 80px;
        margin-bottom: 35px;
      }
    }

    &.active {
      span {
        background-color: #fec314;
      }
    }
  }

  &__control {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 15px;
    width: max-content;
    margin: auto;
    border: 1px solid rgba(255,255,255,.2);
    margin-top: 20px;
    transform: translateY(8px);
    @include bp-down('medium'){
      max-width: 80%;
      margin-bottom: 40px;
    }

    &-checkbox {
      margin-right: 25px;
    }
  }

  &__category-image {
    width: 100%;
    height: 270px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include bp-down('medium'){
      height: 140px;
    }
  }

  &__box {
    display: none;
    min-height: 170px;
    margin-top: 40px;
    background-color: #1a587c;
    padding-bottom: 30px;
    padding-top: 25px;
    @include bp-down('medium'){
      margin-top: 0;
    }

    &-heading {
      color: #fff;
      font-size: 20px;
      line-height: 24px;
      max-width: 180px;
      display: block;
      font-weight: 700;
      @include bp-down("medium") {
        margin: auto auto 30px;
        text-align: center;
        max-width: 100%;
      }
    }

    &-slider {
      text-align: center;
      span {
        font-size: 16px;
        line-height: 19px;
        color: #fff;
        svg {
          vertical-align: middle;
          height: 34px;
          width: 34px;
          margin-right: 8px;
          * {
            fill: #fff;
          }
        }
      }
    }

    .range-item {
      width: 311px;
      margin: 0;

      .noUi-tooltip {
        border: none;
        border-radius: 0;
        padding: 3px 8px;
        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: #fff transparent transparent transparent;
          position: absolute;
          bottom: -5px;
          left: 50%;
          margin-left: -5px;
        }
      }

      &.noUi-horizontal {
        height: 1px;
      }

      .noUi-handle {
        border: none;
        background-color: transparent;
        box-shadow: unset;
        outline: unset;
        &:before,
        &:after {
          width: 3px;
        }
      }
      .noUi-connect,
      .noUi-origin {
        top: -7px;
      }
      .noUi-value-large {
        visibility: hidden;
      }
      .noUi-marker-horizontal.noUi-marker {
        height: 10px !important;
        background: rgba(255,255,255,.2);
      }
      .noUi-pips-horizontal {
        top: -15px;
      }
    }

    &-checkbox {
      text-align: center;
      span {
        font-size: 16px;
        line-height: 19px;
        color: #fff;
        svg {
          vertical-align: middle;
          height: 34px;
          width: 34px;
          margin-right: 8px;
          * {
            fill: #fff;
          }
        }
      }
      label {
        border: 1px solid rgba(255, 255, 255, 0.2);
        width: 85%;
        display: block;
        padding: 10px;
        font-size: 16px;
        line-height: 19px;
        color: #fff;
        cursor: pointer;
        margin-top: 10px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__response {
    padding-top: 50px;
    padding-bottom: 50px;
    h2 {
      display: block;
      text-align: center;
      width: 100%;
    }
  }
}

.c-product-item {
  // margin-bottom: $common-margin;

  &__image {
    display: block;
    width: 100%;
    height: 200px;
    background-color: #fff;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    text-decoration: none;
  }

  &__title {
    transition: $global-transition;
    color: $color-black;
    text-decoration: none;
    display: block;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    
    margin-top: 25px;
    margin-bottom: 20px;
  }

  &__icons {
    display: flex;
    flex-direction: column;
    text-align: right;
    width: 55px;
    max-width: 55px;
    height: 100%;
    padding: 15px;
    margin-left: auto;
    margin-right: 0;
  }

  &__icon {
    max-width: 25px;
    max-height: 25px;
    width: auto;
    height: auto;
    margin-bottom: 15px;
  }
}

input.form__acceptance--green + span.form__acceptance--faked::before {
  background-color: transparent !important;
  background: url("../assets/images/not-checked-green.png") !important;
  border: 1px solid transparent !important;
}

input.form__acceptance--green:checked + span.form__acceptance--faked::before {
  background: url("../assets/images/checked-green.png") !important;
}

.noUi-handle-upper {
	display: none;
}

.range-item.noUi-target {
  background: transparent !important;
  border-radius: 0px !important;
  border: 1px solid rgba(255,255,255,0.2) !important;
  box-shadow: unset !important;
}