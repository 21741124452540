.u-extension {
    
    &--high {
        @include bp('medium'){
            padding-top:  $extension-height !important;
        }
    }
    
    &--low {
        @include bp('medium'){
            padding-top:  $small-extension-height !important;
        }
    }
} 