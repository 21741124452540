.c-tabs-and-content {
    &__wrapper {
        display: flex;
        flex-direction: column;
        @include bp('medium'){
            flex-direction: row;
        }
    }
    
    &__tab {
        transition: $global-transition;
        text-align: center;
        width: 100%;
        padding: $small-margin;
        cursor: pointer;
        @include bp('medium'){
            width: auto;
            min-width: 33%;
            max-width: 33%;
        }
        border-top: 1px solid $color-gs;
        border-bottom: 1px solid $color-gs;
        
        &.is-active {
            position: relative;
            background-color: $color-gold;

            @include bp('medium'){
                background-color: $color-white;
                &::after {
                    @include arrow-bottom($color-white);
                    z-index: 3;
                }
                
                &::before {
                    @include arrow-bottom($color-gs);
                    transform: translateY(1px);
                    z-index: 2;
                }
            }
        }
    }

    &__icon {
        display: none;
        pointer-events: none;
        height: $large-margin;
        width: auto;
        @include bp('medium'){
            display: inline;
            margin-bottom: $common-margin;
        }
    }

    &__title {
        pointer-events: none;
        @include font(lg, bold, center, $color-gs);
        @include bp('medium'){
            margin-bottom: $minimal-margin;
        }
    }
    
    &__subtitle {
        pointer-events: none;
        @include font(sm, bold, center, $color-black);
        
        margin-bottom: 0;
    }

    &__content {
        transition: $global-transition;
        pointer-events: none;
        display: none;
        border-bottom: 1px solid $color-gs;
        padding: $common-margin $minimal-margin $small-margin $minimal-margin;
        @include bp('medium'){
            padding: $common-margin $large-margin $small-margin $large-margin;
        }

        &.is-active {
            display: block;
        }
    }
}