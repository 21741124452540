.c-faq{
    &__item{
        display: flex;
        flex-direction: column;
        margin-bottom: 2px;
        &.is-active {
            .c-faq{
                &__header {
                    transition: $global-transition;
                    animation: fadeIn 300ms ease;
                    background: #25516E;
                    color: white;
                }
                &__answer {
                    transition: $global-transition;
                    animation: fadeIn 300ms ease;
                    display: flex;
                }
                &__icon {
                    transition: $global-transition;
                    animation: fadeIn 300ms ease;
                    background: url(../assets/images/arrows/close.svg);
                }
            }
        }
    }

    &__header{
        transition: $global-transition;
        animation: fadeIn 300ms ease;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        min-height: 77px;
        background: #E1E2E3;
        color: #25516E;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        cursor: pointer;
        @include bp-down('medium'){
            padding: 20px;
        }
    }

    &__question {
        @include bp-down('medium'){
            display: block;
            width: calc(85%);
            font-size: 12px;
        }
    }

    &__answer{
        transition: $global-transition;
        border: 2px solid #E1E2E3;
        display: none;
        justify-content: normal;
        align-items: center;
        vertical-align: middle;
        padding: 30px;
        p {
            img {
                display: inline;
                margin: 0px 30px;
            }
        }

        @include bp-down('medium'){
            flex-direction: column;
        }
    }
    &__icon {
        transition: $global-transition;
        animation: fadeIn 300ms ease;
        display: block;
        width: 19px;
        height: 19px;
        background: url(../assets/images/arrows/chevron.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;
        pointer-events: none;
    }
}