.c-product-video {
    &__header {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        @include font(lg, bold, left, $color-black);
        padding: calc(#{$small-margin} * 1.5);
        padding-left: 0;
        width: max-content;
        background-color: $color-gold;
        @include bp-down('medium'){
            width: 100%;
            padding: 15px 0;
        }
        
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 100vw;
            transform: translateX(-100%);
            background-color: inherit;
        }
    }

    &__title {
        @include font(lg, bold, left, $color-black);
        margin-bottom: $minimal-margin;
        margin-top: $common-margin;
    }

    &__subtitle {
        @include font(sm, bold, left, $color-black);
        
    }

    &__video {
        width: 100%;
        margin: $small-margin auto $medium-margin;
        display: flex;
        justify-content: center;
        margin-top: -$small-margin;
        @include bp('large'){
            margin: 0;
            width: auto;
            position: absolute;
            bottom: $large-margin;
            right: 0;
        }
    }

}