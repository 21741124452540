.c-text-image{
    @include bp('medium'){
        background: linear-gradient(to right, #e1e2e3 43%, white 43%);
    }

    &__header {
        margin-bottom: $minimal-margin;
    }
    
    &__subheader {
        display: block;
        @include font(sm, bold, left);
        
        margin-bottom: $medium-margin;
    }
    
    &__content {
        @include font(md, normal, left);
        margin-bottom: 0;
    }

    &__image {
        max-width: 200px;
        max-height: 200px;
        @include bp('medium'){
            float: right;
        }
    }
}