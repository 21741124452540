.c-production-gallery {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    gap: 2px;
    @include bp('medium'){
        gap: 30px;
    }

    &__item {
        max-width: 48%;
        width: 100%;
        height: 150px;
        background-size: cover !important;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        @include bp('medium'){
            width: 370px;
            height: 265px;
        }
    }
    &__thumnail {
    }
}