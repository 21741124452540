.c-carousel-team {
  background: linear-gradient($color-white 20%, $gray-halfwhite 20%);
  @include bp('large'){
    background: linear-gradient($color-white 50%, $gray-halfwhite 50%);
  }
  @media (min-width: 1800px) {
    position: relative;
  }
  
  &__container {
    @media (min-width: 1024px) and (max-width: 1800px) {
      background: linear-gradient(#fff 39px, #f5f5f5 39px);
    }

  }

  &__header {
    margin: auto;
    text-align: left;
    margin-bottom: $common-margin;
    margin-top: 50px;
  }

  &__carousel {
    position: relative;
    transform: translateY(-50px);
    z-index: 3;
  }

  &__image{
      width: 180px;
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include bp-down('medium'){
          margin: auto;
      }
  }

  &__item {
    width: 90% !important;
    height: auto;
    max-width: 100%;
    padding: 0;
    margin: 0 5%;
    position: relative;
    cursor: pointer;
  
    &-desc {
      width: 100%;
      display: flex;
      align-items: start;
      flex-direction: column;
      min-height: 350px;
      justify-content: space-between;
      @include bp('medium'){
          width: 80%;
          min-height: 350px;
          justify-content: space-between;
      }
    }
  }

  &__items {
    background-color: #fff;

    .slick-active {
      opacity: 1;
    }

    &--second{
      position: absolute;
      width: 100%;
      top: 160px;
      z-index: 2;
      opacity: 1;
      @include bp-down('large'){
        display: none;
      }
      
      .slick-slide {
          opacity: 0.4 !important;
          transform: scale(0.8);
      }

      .slick-track {
        background: linear-gradient($color-white 15.2%, $gray-halfwhite 15.2%) !important;
      }


    }
  }

  &__arrow {
    position: absolute;
    top: 12%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 99;

    svg {
      width: 44px;
      height: 20px;
    }
    &--right {
      right: -5px;
      @include bp('medium'){
        right: -45px;
      }
    }
    &--left {
      left: -5px;
      @include bp('medium'){
        left: -45px;
      }
    }
  }

  &__name {
    display: block;
    @include font(lg, bold, left);
    
    margin-bottom: 5px;
  }
  &__work {
    display: block;
    @include font(sm, bold, left);
    
  }
  &__about {
    display: block;
    @include font(sm, normal, left);
  }

  .c-menu--horizontal {
    width: 100%;
    border-top: 1px solid $color-light-grey;
    li {
      transform: scale(1.3)
    }
  }
  .c-menu--icons {
    justify-content: start;
    padding: 20px 0;
  }

  &__bottom {
    width: 100%;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  &__career-btn {
    @include bp('medium'){
      transform: translateY(-40px);
    }
  }

  & .slick-track,
  & .js-slider {
    background: linear-gradient($color-white 12%, $gray-halfwhite 12%);
    @media (max-width: 1023px) {
      background: transparent;
    }
  }
}
  

.js-slider-second .slick-slide {
	@include bp-down('fullhd'){
    visibility: hidden !important;
  }
}