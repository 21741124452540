.c-partners {
    filter: grayscale(1);

    .slick-slide a {
        display: flex !important;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
            height: auto;
            width: auto;
        }
    }

    .slick-track {
        display: flex;
        align-items: center;
    }

    &__carousel {
        position: relative;
    }

    &__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 99;
    
        svg {
          width: 44px;
          height: 20px;
        }

        &--right {
          right: -5px;
          @include bp('medium'){
            right: -45px;
          }
        }
        &--left {
          left: -5px;
          @include bp('medium'){
            left: -45px;
          }
        }
      }
}