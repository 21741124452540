.u-bg {
    &--white {
        background-color: $color-white !important;
    }
    &--gray {
        background-color: $gray-halfwhite !important;
    }
    &--dark-gray {
        background-color: $gray-medium !important;
    }
    &--gs {
        background-color: $color-gs !important;
    }
}