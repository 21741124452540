@mixin arrow-bottom($color) {
    z-index: 3;
    display: block;
    margin:auto;
    position: absolute;
    right: 0;
    bottom: -$minimal-margin;
    left: 0;
    content: "";
    width: 0; 
    height: 0; 
    border-left: $minimal-margin solid transparent;
    border-right: $minimal-margin solid transparent;
    border-top: $minimal-margin solid #{$color};
}