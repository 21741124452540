.c-interactive-office {
    &__content {
        @include font(md, normal, center, $color-black);
        h2 {
            @include font(lg, bold, center, $color-black);
            margin-bottom: $common-margin;
        }
    }

    &__image {
        display: none;
        margin-bottom: $medium-margin;

        &.is-active {
            display: block;
        }
    }

    &__slider {
        position: relative;
        border: 3px solid $gray-halfwhite;
        @include bp('large'){
            width: 25%;
        }
    }

    &__card {
        display: none;

        &.is-active {
            display: block;
        }
    }

    &__title {
        display: block;
        background: $color-gold;
        @include font(sm, bold, center, $color-dark-grey);
        padding: $small-margin 0;
    }

    &__thumbnail {
        margin: $minimal-margin auto;
        text-align: center;
    }

    &__icons {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        @include bp-down('xlarge'){
            margin-bottom: 0;

            .c-menu__item {
                border-bottom: none !important;
            }
        }
    }

    &__text {
        padding: 0 $small-margin;
        margin: $small-margin auto;
        @include font(xs, normal, center, $color-black);
        line-height: 26px;
    }

    &__button {
        margin: $common-margin auto;
        display: block;
        text-align: center;
    }

    &__footer {
        @include font(sm, bold, center, $color-black);
        display: flex;
        justify-content: center;
        margin: $small-margin;
        
        svg {
            cursor: pointer;
            margin: 0 $minimal-margin;
            width: 20px;
            height: auto;
        }
    }

    &__count {
        font-weight: bold;
        margin: 0 15px;
    }

    &__arrow {
        cursor: pointer;
    }
}


.hs-arrow {
	display: none !important;
}
.squares-element {
	text-align: center !important;
	display: flex;
	justify-content: center !important;
}
.imp-tooltip {
    border: 2px solid #e1e2e3 !important;
    transition: none !important;
}
.imp-tooltip h3{
    padding: 0 20px !important;
    position: relative;
}
.ks-arrow{
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    height: 15px;
    width: 15px;
    background-size: contain;
    background-position: center;
}
.imp-fullscreen-tooltip.imp-tooltip-visible .squares-element.sq-col-lg-4 {
    max-width: 33% !important;
}
.imp-fullscreen-tooltip.imp-tooltip-visible {
    display: flex !important;
    flex-direction: column !important;
    align-content: space-between !important;
    justify-content: space-between !important;
}
.imp-initialized {
    @include bp('large'){
        max-width: 85%;
        transform: translateX(-80px);
    }
}
body .imp-tooltip.imp-tooltip-visible {
	@include bp('small'){
        transform: translateX(0%) !important;
    }
	@include bp('xlarge'){
        transform: translateX(50%) !important;
    }
}
.imap {
    margin-top: 50px;
    @include bp('medium'){
        margin-top: 80px;
    }
}

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button,
.imp-tooltip-close-button {
	position: absolute;
	right: 0;
	top: 0;
	width: 44px;
	height: 44px;
	font-size: 24px;
	line-height: 44px;
	text-align: center;
	color: $color-gs;
	cursor: pointer;
    z-index: 99;
    @include bp('medium'){
        right: 5px;
        top: 5px;
        width: 20px;
        height: 20px;
        font-size: 18px;
        line-height: 1;
    }
}           

body .imap .icon-plus-active {
    background: url(/wp-content/themes/glass-system-chisel/dist/assets/images/plus-active.png) !important;
}
.squares-container {
	max-width: 1140px;
	text-align: center;
	justify-content: center;
}