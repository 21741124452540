.lightcase-icon-close::before {
    display: none !important;
}

.lb-data .lb-close {
	display: block;
	float: right;
	width: auto;
	height: auto;
	background-color: #FEC314;
	text-align: center;
	opacity: 1;
	-webkit-transition: opacity .2s;
	-moz-transition: opacity .2s;
	-o-transition: opacity .2s;
	transform: none;
	line-height: 1;
	padding: 10px 22px;
	color: #25516E;
	font-family: Roboto;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 19px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.lb-image {
	width: auto !important;
	height: auto !important;
	max-height: 90vh !important;
	max-width: $wrapper-fullhd !important;
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	@include bp-down('medium'){
		max-width: 85vw;
	}
}

.lb-dataContainer {
	margin-bottom: 30px;
	border-radius: 1px;
}

.lightboxOverlay {
	background-color: #25516E;
	opacity: .9;
}