/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  background: $gray-light;
 
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $top-header-height;
  }

  &__logo {
    display: block;
    height: auto;
    width: 150px;
    transition: $global-transition;
    @include bp('xlarge'){
      height: 40px;
      width: 215px;
    }

    svg {
      height: auto;
      width: auto;
    }
  } 

  &__content {
    @include bp-down('xlarge'){
      position: absolute;
      top: $top-header-height;
      right: 0;
      left: 0;
      display: none;
      flex-direction: column;
      z-index: 100;
      background: $gray-light;
      animation: showMenu $global-transition-time ease-in-out;
      padding: 0;
      min-height: calc(100% - #{$top-header-height});
      overflow-y: scroll;
      background: $color-gs;
      color: $color-white;
      overflow: visible;

      &--hidden {
        display: flex;
        transform: translateX(-100%);
        animation: hideMenu $global-transition-time ease-in-out;
      }

      &--showed {
        display: flex;
        animation: showMenu $global-transition-time ease-in-out;
      }
    }
    @include bp('xlarge'){
      display: flex;
      flex-direction: row;
      height: 100%;
    }
  }

  &__hamburger {
    display: block;
    cursor: pointer;
    svg {
      fill: $color-dark-grey;
    }

    @include bp('xlarge'){
      display: none;
    }
  }

  &__social-mobile {
    @include bp-down('xlarge'){
      display: block;
      transform: translateY(19%);

      .c-menu__link {
        padding: 5px;
      }
    }
  }

  &__small-bar {
    height: 30px;
    background-color: #cccccc;

    .c-menu--icons {
      a {
        padding: 0 5px;
        height: auto;

        img {
        }
      }
    }
  }

  &__login-btn {
    transition: $global-transition;
    background: $color-gs;
    color: white;
    text-decoration: none;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    height: 30px;
    display: flex;
    line-height: 1;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    &:hover {
      color: white;
    }
    &::after {
      background: url("/wp-content/themes/glass-system-chisel/src/assets/images/unlocked.svg");
      display: block;
      content: "";
      width: 19px;
      height: 22px;
      background-repeat: no-repeat;
      margin-left: 10px;
    }

    &--alt {
      background: white;
      color: $color-gs;
      &:hover {
        color: $color-gs;
      }
      &::after {
        background: url("/wp-content/themes/glass-system-chisel/src/assets/images/unlocked-blue.svg");
        display: block;
        content: "";
        width: 19px;
        height: 22px;
        background-repeat: no-repeat;
        margin-left: 10px;
      }
    }
  }

  &--alt-color {
    .menu-item-has-children:hover::after {
      @include bp('xlarge'){
          border-bottom: 15px solid $color-gs !important;
      }
    }
    .wpml-ls-sub-menu::before {
      background: $color-gs;
    }
    .wpml-ls-sub-menu::after {
        border-bottom: 15px solid $color-gs !important;
    }
    .c-menu__nav-category {
      background: $color-gs;
      color: $color-white !important;
      @include bp-down(xlarge){
        color: $color-white !important;
      }
    }
    .c-menu__nav-drop-item.is-active .c-menu__nav-category {
      background: $color-gold !important;
      color: $color-gs !important;
    }
    .c-menu__nav-drop::before {
      background: $color-gs;
    }
    .c-menu__item.menu-item-has-children:hover::after {
      border-bottom: 15px solid $color-gs !important;
    }
    .c-menu__sign svg polygon {
      fill: $color-white !important;
      transition: $global-transition;
    }
    .c-menu__nav-drop-item.is-active .c-menu__sign svg polygon {
      transition: $global-transition;
      fill: $color-gs !important;
    }
  }
}
