/* ==========================================================================
   #LISTS
   ========================================================================== */

li {
  margin-bottom: 0.5rem;

  > ul,
  > ol {
    margin-bottom: 0;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

article ul li {
	list-style: none;
}
article p ul li::before,
article .c-carousel-news__article-content ul li::before {
	content: '';
	display: inline-block;
  background: url('/wp-content/themes/glass-system-chisel/dist/assets/images/bullet-2.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
	height: 14px;
	width: 12px;
	margin-left: -15px;
	margin-right: 10px;
}
