.c-menu {
    margin: 0;

    // menu variants
    &--horizontal {
        display: flex;
        flex-direction: row;
    }

    &--small {
        .c-menu__link {
            @include bp('xlarge'){
                padding: calc(#{$minimal-margin}/2);
            }
        }
    }

    // menu types
    &--primary {
        display: flex;
        flex-direction: column;
        @include font(sm, semibold, left, $gray-unactive);
        @include bp('medium'){
            text-align: center;
        }
        @include bp('xlarge'){
            @include font(xs, semibold, right, $gray-unactive);
            flex-direction: row;
            text-align: right;
            & li:last-child { 
                a {
                    padding-right: 0;
                }
            }
        }
    }

    &--bottom {
        @include bp-down('medium'){
            flex-direction: column !important;
            width: 100%;
        }

        .c-menu__link {
            transition: $global-transition;
            color: $color-white;
            font-weight: 900;
            @include bp-down('medium'){
                width: 100%;
                padding-left: 0;
                font-size: 16px;
            }
        }
        .c-menu__link:hover {
            transition: $global-transition;
            color: $color-gold;
        }

        .c-menu__item {
            @include bp-down('xlarge'){
                border: none;
            }
        }
    }

    &--icons {
        margin: auto;
        margin-bottom: $small-margin;
        @include bp('xlarge'){    
            justify-content: center;
            margin-bottom: auto;
        }
        @include bp-down('xlarge'){    
            margin-bottom: 0 !important;
            margin-left: 15px !important;
            margin-top: -10px;
            .c-menu__item {
                border-bottom: none !important;
                margin-top: -5px;
            }
        }
    }


    &__icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        max-width: 80px;

        img {
            width: 20px;
            margin: 3px 0 3px 6px;
        }
    }


    // menu elements
    &__item {
        display: block;
        @include bp('xlarge'){
            display: flex;
            align-items: center;
            margin: 0 0 0 $minimal-margin;
            &:first-of-type{
                margin-left: 0;
            }
        }
        @include bp-down('xlarge'){
            color: $color-white;
            border-bottom: 1px solid $color-white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            margin-bottom: 0;
        }

        &.menu-item-has-children:hover::after {
            @include bp('xlarge'){
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid $color-gold;
                content: '';
                position: absolute;
                z-index: 100;
                top: 82px;
                margin: 0 24px;
            }
        }
    }

    &__link {
        display: block;
        transition: $global-transition;
        text-decoration: none;
        padding: $minimal-margin;
        &:hover {
            color: $color-gs;
        }
        @include bp-down('xlarge'){
            width: max-content;
            &:hover {
                color: $color-gold;
            }
        }

        img{
            max-width: 16px;
        }
    }

    &__products {
        width: 100%;
        align-content: flex-start;
    }

    &__nav-drop {
        display: none;
        position: absolute;
        z-index: 999;
        @include bp('xlarge'){
            display: none;
            left: 0;
            right: 0;
            background: linear-gradient(90deg, $gray-medium 50%, $gray 50%);
            margin: 0;
            top: 95px;
            color: $color-black;
            list-style: none;
            text-align: left;
        }
        @include bp-down('xlarge'){
            display: flex;
            position: absolute;
            z-index: 999;
            top: 0;
            left: 0;
            right: 0;
            transform: translateX(200%);
            background: $gray;
            width: 100%;
            list-style: none;
            padding: 0;
            margin: 0;
            min-height: 100%;
        }

        a {
            color: $color-black;
            text-decoration: none;   
        }

        &::before {
            @include bp('xlarge'){
                display: block;
                content: '';
                height: 66px;
                width: 200vw;
                background: $color-gold;
                position: absolute;
                z-index: -1;
            }
        }

        &--second {
            animation: fadeIn $global-transition-time;
            list-style: none;
            padding: 0 16px;
            margin: 20px 0;
            @include bp-down('xlarge'){
                display: none;
                padding: 20px 40px;
                margin: 0;
            }
        }
    }

    &__back {
        display: flex;
        width: 100%;
        justify-content: space-between;
        background: $color-gs;
        padding-left: 20px;

        .c-menu__link-arrow {
            justify-content: flex-start !important;
            align-items: center;
            transform: rotate(180deg);
        }
    }

    &__item:hover &__nav-drop, &__nav-drop:hover {
        @include bp('xlarge'){
            transition: $global-transition;
            display: flex;
        }
    }

    &__nav-drop-item {
        @include bp('xlarge'){
            padding: 0 !important;
            background: $gray-medium;
            margin-bottom: 0;
            &:nth-of-type(even){
                background: $gray;
            }
        }
        @include bp-down('xlarge'){
            padding: 0 !important;
            margin-bottom: 0 !important;
        }

        &--second {
            a {
                transition: $global-transition;
                @include font(sm, normal, left);
                text-transform: none !important;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;


                svg {
                    transition: $global-transition;
                    transition-duration: 0.05s;
                }
            }

            a:hover {
                color: $color-gs;
                svg {
                    margin-left: 5px;
                }
            }
        }

        &.is-active {
            transition: $global-transition;
            .c-menu__nav-category {
                background: $color-gold;
            }
            .c-menu__nav-drop--second {
                transition: $global-transition;
                display: block;
            }
            .c-menu__sign {
                transition: $global-transition;
                transform: rotate(270deg);
            }
        }
    }

    &__nav-category {
        transition: $global-transition;
        display: block;
        padding: $small-margin 0 $small-margin 16px !important;
        
        @include font(sm, bold, left);
        @include bp('xlarge'){
            background: $color-gold;
        }
        @include bp-down('xlarge'){
            background: transparent;
            text-align: center;
            color: $color-gs !important;
            border-top: 1px solid $gray-border;
            border-bottom: 1px solid $gray-border;
            position: relative;
            padding: 20px !important;
        }
    }

    &__sign{
        transition: $global-transition;
        transform: rotate(90deg);
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
        width: 15px;
        height: 22px;
        margin: auto;

        svg {
            width: 14px;
            height: 14px;
            polygon {fill: $color-gs;}
        }
    }
}

.c-header__content {
    & .c-menu {
        &__link {
            @include bp-down('xlarge'){
                color: $color-white;
                padding: 15px;
                margin: 0;
                
                font-size: 15px;
                display: inline-flex;
                justify-content: space-between;
            }

                &-arrow {
                    @include bp-down('xlarge'){
                        position: static;
                        right: 20px;
                        top: 0;
                        bottom: 0;
                        width: 50%;
                        text-align: right;
                        pointer-events: none;
                        z-index: 10;
                        display: inline-flex;
                        padding: 20px;
                        justify-content: flex-end;
                        cursor: pointer;
                            
                        svg {
                            width: 20px;
                            height: 10px;
                        }
                        svg g {
                            fill: $color-white;
                        }
                    }
                    @include bp('xlarge'){
                        display:none;
                    }
                }
            
        }
    }

    @include bp('xlarge'){
        .current-menu-item > a {
            transition: $global-transition !important;
            color: $color-gs !important;
        }
    }
    @include bp-down('xlarge'){
        .current-menu-item {
            transition: $global-transition !important;
            background: $color-gold !important;
        }
        .current-menu-item > a {
            transition: $global-transition !important;
            color: $color-gs !important;
        }
    }

}


// Language menu
.js-wpml-ls-item-toggle.wpml-ls-item-toggle {
	background: transparent !important;
	border: none;
    width: max-content;
    padding: 5px 25px !important;
    font-size: 12px !important;
    max-width: 65px;
    @include bp-down('xlarge'){
        margin-top: -10px;
    }
}
.wpml-ls-native {
    @include bp('xlarge'){
        font-size: 15px;
        font-weight: 500;
        color: #626262;
    }
}
.wpml-ls-link {
	background: #e0e1e2 !important;
	width: max-content;
	border: none !important;
	font-size: 12px;
	font-weight: normal;
    padding: 5px 25px !important;
    max-width: 65px;
}
.wpml-ls-legacy-dropdown {
	width: max-content;
	max-width: 100%;
	border: none !important;
    margin: 0 5px;  
}
.wpml-ls-legacy-dropdown a.wpml-ls-item-toggle::after {
	border: none !important;
    background: url('/wp-content/themes/glass-system-chisel/src/assets/images/arrow-btn.svg') !important;
    background-repeat: no-repeat !important;
    width: 10px;
    height: 10px;
    @include bp('xlarge'){
        right: 5px;
    }
}
.c-header__small-bar .wpml-ls-legacy-dropdown a.wpml-ls-item-toggle::after {
    background: url('/wp-content/themes/glass-system-chisel/src/assets/images/arrows/chevron.svg') !important;
    background-repeat: no-repeat !important;
    width: 10px;
    height: 6px;
    background-size: contain !important;
    right: 5px;
}
.wpml-ls-legacy-dropdown .wpml-ls-item {
	padding: 0;
	margin: 0;
	list-style-type: none;
	width: max-content;
}
.wpml-ls-legacy-dropdown {
	width: 65px !important;
	max-width: max-content;
}
.wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
	border-top: none !important;
	max-width: 65px;
	width: 65px;
}
.menu-item-has-children:hover::after {
    @include bp('xlarge'){
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid $color-gold; 
        content: '';
        position: absolute;
        z-index: 100;
        top: 82px;
        margin: 0 24px;
    }
}
.wpml-ls-sub-menu::before {
	content: '';
	display: block;
	position: static;
	background: $color-gold;
	height: 10px;
	width: 100%;
}
.wpml-ls-sub-menu::after {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	content: '';
	display: block;
	height: 10px;
	width: 10px;
	background: transparent;
    margin: auto;
    margin-top: -10px;
	border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid $color-gold !important;
}
.wpml-ls-legacy-dropdown {
	transform: translateX(-10px);
	z-index: 1000 !important;
	position: relative;
}
.wpml-ls-sub-menu {
	transform: translateY(10px);
}
.wpml-ls-sub-menu .wpml-ls-slot-shortcode_actions:nth-of-type(1) a {
	padding-top: 10px !important;
}
.wpml-ls-sub-menu .wpml-ls-slot-shortcode_actions:last-of-type a {
	padding-bottom: 10px !important;
}
