.c-carousel-productions {
  position: relative;
  &__header {
      margin: auto;
      text-align: center;
      margin-bottom: 50px;
  }

  &__carousel{
    position: relative;
  }

  &__item{
    width: 600px !important;
    max-width: 100%;
    padding: 15px;
    &-image{
      display: block;
      height: 406px;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    &-desc{
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      &-icons{
        display: flex;
        align-items: center;
        ul{
          list-style: none;
          margin: 0;
          padding: 0;
          li{
            display: inline-flex;
            align-items: center;
            margin-right: 20px;
            margin-bottom: 0;
            svg{}
            span{
              font-size: 12px;
              
              line-height: 13px;
              color: #000;
              font-weight: 700;
              display: inline-block;
              margin-left: 7px;
              &.inactive{
                color: #8F8F8F;
              }
            }
          }
        }
      }
      &-link{
        transform: translateY(-4px);

        a{
          background-color: $color-gs;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: block;
          position: relative;
          svg{
            position: absolute;
            fill: #fff;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
          }

          &:hover{
            background-color: darken($color-gs, 15%);
          }
        }
      }
    }
  }

  &__items{
    background-color: #fff;

    .slick-active{
      opacity: 1;
    }

    &--second{
      position: absolute;
      width: 100%;
      top: 65px;
      z-index: -1;
      opacity: 0.5;
      .c-carousel-productions__item-image{
        height: 260px;
      }
    }
  }

  &__arrow{
    position: absolute;
    top: 43%;
    transform: translateY(-50%);
    cursor: pointer;
    svg{
      width: 44px;
      height: 20px;
    }
    &--right{
      right: -45px;
    }
    &--left{
      left: -45px;
    }
  }
}

