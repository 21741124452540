/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
@font-face {
   font-family: 'Roboto';
   src: url('../../dist/assets/fonts/Roboto-Regular.ttf') format('truetype');
   font-weight: normal;
   font-style: normal;
}
@font-face {
   font-family: 'Roboto';
   src: url('../../dist/assets/fonts/Roboto-Bold.ttf') format('truetype');
   font-weight: bold;
   font-style: normal;
}  
@font-face {
   font-family: 'Roboto';
   src: url('../../dist/assets/fonts/Roboto-Medium.ttf') format('truetype');
   font-weight: 500;
   font-style: normal;
}  