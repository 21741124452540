.contact {

  &__text {
    padding-top: $medium-margin;
    @include bp('medium'){
      padding-top: 0;
    }

    p {
      margin-bottom:  $common-margin;
      @include bp('medium') {
        margin-bottom: $common-margin;
      }
    }

    h2, h3 {
      margin-bottom: $minimal-margin;
      @include font(sm, bold, left);
      @include bp('medium'){
        margin-bottom: $common-margin;
        @include font(lg, bold, left);
      }
    }
  }

  &__data {
    @include font(sm, normal, left);
    margin-bottom: $small-margin;
    @include bp('medium'){
      margin-bottom: $common-margin;
    }
  }

  &__tel {
    margin-bottom: $small-margin;
    @include bp('medium'){
      margin-bottom: $common-margin;
    }
    p,
    a {
      font-size: 16px;
      line-height: 24px;
      margin: 0 !important;
      text-decoration: none;
      color: $color-black;
      transition: $global-transition;
      @include bp-down('medium'){
        @include font(sm, normal, left);
      }
    }

    a:hover {
      color: $color-gs;
    }
  }

  &__email {
    margin-bottom: $medium-margin;
    @include bp('medium'){
      margin-bottom: $common-margin;
    }

    p,
    a {
      font-size: 16px;
      line-height: 24px;
      margin: 0 !important;
      color: $color-black;
      transition: $global-transition;
    }

    a {
      color: $color-gs;
    }

    a:hover {
      color: $gray-unactive;
    }
  }

  &__content {
    @include bp-down('medium'){
      padding: 0 $minimal-margin;
    }
  }

  &__form {
    @include bp-down('medium'){
      padding: $small-margin;
    }
    @include bp('medium'){
      padding: $small-margin;
    }
    @include bp('large'){
      padding-left: $small-margin;
      padding-right: 0;
    }
  }

  &__header {
    display: block;
    text-align: center;
  }

  &__page, &__page a, &__page p {
    @include font(sm, normal, left);
    @include bp('small'){
      font-size: 13px !important;
    }
  }

  &__page a {
    color: $color-gs;
  }

  &__c-btn-container {
    text-align: center !important;
  }

  .wpcf7-list-item-label {
    transition: $global-transition;

    @include bp('medium') {
      transform: translateY(-3px);
    }
  }

  .wpcf7-not-valid-tip {
      margin-bottom: 30px;
  }

  .form__input,
  .form__input::placeholder,
  .form__acceptance--faked::before {
    border-color: $gray-border !important;
    color: $color-black !important;
  }

  .form__input{
    color: $color-black !important;
  }

  .invalid .wpcf7-response-output {
    margin-top: 0 !important;
    @include bp('medium'){
      margin-top: 30px !important;
      margin-bottom: -50px !important;
    }
  }
}
