.lb-next, .lb-prev {
  @include bp('large'){
    margin-top: 45vh;
    background-position: center center !important;
    width: 50px !important;
    height: 50px !important;
    opacity: 1 !important;
  }
}

.lb-next {
  background: url("/wp-content/themes/glass-system-chisel/templates/svg/arrow-right.svg") no-repeat scroll right 48% transparent !important;
  @include bp-down('large'){
    background: url("/wp-content/themes/glass-system-chisel/templates/svg/arrow-right-black.svg") no-repeat scroll right 48% transparent !important;
    transform: translate3d(-10px, 0, 0);
  }
  @include bp('large'){
    transform: translate3d(-50px, -100%, 0);
  }
}
.lb-prev {
  background: url("/wp-content/themes/glass-system-chisel/templates/svg/arrow-left.svg") no-repeat scroll left 48% transparent !important;
  @include bp-down('large'){
    background: url("/wp-content/themes/glass-system-chisel/templates/svg/arrow-left-black.svg") no-repeat scroll left 48% transparent !important;
    transform: translate3d(10px, 0, 0);
  }
  @include bp('large'){
    transform: translate3d(50px, -100%, 0);
  }
}
.lb-outerContainer {
  @include bp('large'){
    background: transparent !important;
    width: 100% !important;
    margin: auto 0 !important;
  }
}
.lightbox .lb-image {
	@include bp('large'){
    margin: auto !important;
	  background: white !important;
  }
}