.u-margin {
    &--large {
        margin: $large-margin 0;
    }
    &--medium {
        margin: $medium-margin 0;
    }
    &--common {
        margin: $common-margin 0;
    }
    &--small {
        margin: $small-margin 0;
    }
    &--small\@small {
        @include bp-down('medium'){
            margin: $small-margin 0;
        }
    }

    &--x-large\@large {
        @include bp('large'){
            margin: 0 $large-margin;
        }
    }
    &--x-minimal\@small {
        @include bp-down('medium'){
            margin: 0 $minimal-margin; 
        }
    }

    &--top-large {
        @include bp('medium'){
            margin-top: 120px;
        }
    }

    &--logo-mobile {
        @include bp-down('medium'){
            margin-top: $common-margin;
            margin-bottom: 0;
        }
    }
}