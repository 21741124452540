.u-text{
  
  &--bold {
    font-weight: 600 !important;
  }
  &--normal {
    font-weight: 400 !important;
  }

  &--center {
    text-align: center !important;
  }
  &--left {
    text-align: left !important;
  }
  &--right {
    text-align: right !important;
  }
}