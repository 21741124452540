.c-tabs-photo {

    &__tab {
        transition: $global-transition;
        display: flex;
        margin: 0 10px;
        padding: 12px 20px !important;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        background-color: #E1E2E3 !important;
        color: #787878 !important;
        justify-content: center;
        @include bp-down('medium'){
            margin: 5px 10px;
            width: 100%;
        }
        &.is-active {
            transition: $global-transition;
            background-color: #25516E !important;
            color: white !important;
        }
    }
    &__desc {
        max-width: 850px;
        margin-top: -20px !important;
        text-align: center;
    }
    &__img {
        display: flex;
        height: 70vh;
        width: auto;
        width: 100%;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        background-position: center !important;
        @include bp-down('medium'){
            height: 70vw;
            background-position: bottom center !important;
        }
        margin-top: 63px;
    }
    &__photo {
        position: relative;
    }
    &__desc, &__photo {
        transition: $global-transition;
        display: none;

        &.is-active {
            transition: $global-transition;
            display: flex;
        }
    }
    &__click-btn {
        position: absolute;
        display: flex;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        background-color: #FEC314;
        color: #25516E;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        text-align: center;
        border: none;
        padding: 12px 18px;
        cursor: pointer;
        width: 210px;
        text-align: center;
        justify-content: center;
        text-decoration: none;
        cursor: pointer;
        transition: $global-transition;

        &:hover {
            transition: $global-transition;
            color: #25516E;
        }
    }
}