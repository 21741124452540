.c-picture-grid {
    &__container {
        @include bp('medium'){
            width: 200%;
        }
    }
    &__box {
        min-height: 265px;
        padding: $minimal-margin;
    }
    &__image {
        height: 245px;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        width: 100%;
    }
    &__title {
        display: block;
        @include font(md, bold, center, $color-gs);
        
        margin-bottom: $common-margin;
        width: 100%;
    }
    &__text {
        @include font(sm, normal, center);
    }
}