.c-downloads {
    &__file {
        margin-bottom: 50px;
        padding-bottom: 20px;
        border-bottom: 1px solid lightgrey;
        position: relative;

        &--secondary {
            width: 400px;
            max-width: 100%;
            padding-bottom: 10px !important;

            @include bp-down('small'){
                width: 200px;
            }
        }

        &--tetriary {
            max-width: 100%;
            padding: 0;
            margin: 0;
        }
    }
    &__image-container {
        display: block;
        width: 100%;
        max-height: 240px;
        height: 240px;
        text-align: left;

        &--left {
            max-height: 120px;
            max-width: 85px;
            width: auto;
            margin-right: 15px;
        }

        &--draw {
            cursor: pointer;
            max-height: 269px;
            max-width: 100%;
            height: 269px;
            width: 370px;
            margin-right: 30px;
            position: relative;
            background-repeat: no-repeat !important;
            background-position: center !important;
            background-size: contain !important;
        }
    }
    &__image {
        max-width: 100%;
        max-height: 240px;
        width: auto;
        height: auto;
        display: block;
        margin: auto 0;
    }
    &__footer {
        position: relative;
        display: flex;
        justify-content: flex-start;
        height: 60px;

        &--tetriary {
            width: 370px;
            max-width: 100%;
        }
    }
    &__name {
        transition: $global-transition;
        color: #000000;
        font-family: Roboto;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 29px;
        max-width: 220px;
        text-decoration: none;
        &:hover {
            color: $color-gs;
        }

        &--secondary {
            max-width: 170px;
        }

        &--tetriary {
            max-width: calc(100% - 30px);
            padding-bottom: 20px;
        }
    }
    &__icon {
        position: absolute;
        top: 0;
        right: 0;
    }
    &__tag {
        margin: 20px 0; 
        color: #25516E;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        border-radius: 3px;
        background-color: #FEC314;
        width: max-content;
        padding: 3px 13px;

        &--secondary {
            margin-top: 0;
        }
    }
    &__filter {
        @include bp-down('small'){
            flex-direction: column !important ;
            align-items: flex-start !important;
        }
    }
    &__selection {
        cursor: pointer;
        margin-left: 30px;
        background: white;
        color: black;
        padding: 10px 8px;
        width: 370px;
        border: 1px solid #8F8F8F;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        &::-ms-expand {
            display: none;
        }
        background: url("/wp-content/themes/glass-system-chisel/dist/assets/images/arrows/arrow-filled-bottom.png");
        background-position: calc(100% - 15px) center;
        background-repeat: no-repeat;
        background-size: 14px;

        @include bp-down('small'){
            max-width: 100%;
            display: block;
            margin: 0;
        }
    
        option {
            background-color: #FFFFFF;
            color: #000000;
            font-family: Roboto;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
        }
    }

    &__draw {
        @include bp-down('small'){
          display: flex;
          flex-direction: column;
          max-width: 100%;
          justify-content: center;
          align-items: center;
        }
      }
}