.c-image-attrs {
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    @include bp-down('xlarge'){
        background: none !important;
    }

    &__header {
        text-align: center;
        margin-bottom: $medium-margin;
    }

    &__subheader {
        @include font(md, normal, center, $color-black);
        margin-bottom: $medium-margin;
    }

    &__image {
        position: static;
        display: block;
        margin: auto;
        @include bp('medium'){   
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        height: auto;
        @include bp('medium'){
            height: auto;
            align-items: baseline;
            padding: 0 5%;
        }
        @include bp('large'){
            padding: 0 10%;
            gap: 0;
            justify-content: space-around;
        }
        @include bp('xlarge'){
            justify-content: space-between;;
            padding: 0 34% 0 0%;
        }
    }

    &__item {
        z-index: 5;
        display: block;
        margin-bottom: $common-margin;
        @include bp('medium'){
            margin-bottom: $medium-margin;
            width: 30%;
        }
        @include bp('large'){
            width: 45%;
        }
        @include bp('xlarge'){
            width: 370px;
            margin-bottom: $medium-margin;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__top {
        margin-bottom: $small-margin;
    }

    &__name {
        @include font(sm, bold, left, $color-gs);
        margin-left: $minimal-margin;
    }

    &__text {
        @include bp('medium'){
            max-width: 370px;
        }
    }

}