@mixin font($type: null, $weight: normal, $align: left, $color: null) {
    
    // Common for all types
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: $align;

    // Weight
    @if $weight == "normal" {
        font-weight: 400;
    }
    @if $weight == "semibold" {
        font-weight: 500;
    }
    @if $weight == "bold" {
        font-weight: 600;
    }

    // Sizing
    @if $type == "super" {
        font-size: 50px;
        line-height: 58px;
    }
    @if $type == "xl" {
        font-size: 40px;
        line-height: 47px;
    }
    @if $type == "lg" {
        font-size: 24px;
        line-height: 28px;
    }
    @if $type == "md" {
        font-size: 20px;
        line-height: 34px;
    }
    @if $type == "xsm" {
        font-size: 18px;
        line-height: 26px;
    }
    @if $type == "sm" {
        font-size: 16px;
        line-height: 26px;
    }
    @if $type == "xs" {
        font-size: 14px;
        line-height: 16px;
    }
    @if $type == "xxs" {
        font-size: 12px;
        line-height: 20px;
    }

    // Color
    @if $color {
        color: $color;
    }
}