.c-featured-content {

    &_content {
        @include font(md, normal, center, $color-black);
        margin-bottom: $small-margin;
    }
    
    &__box{
        background: $color-white;
    }
    &__image{
        min-height: 265px;
        height: 265px;
        width: 100%;
        position: relative;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
    }
    &__desc{
        padding: $small-margin;
        justify-content: space-between;
    }
    &__title{
        @include font(md, bold, center, $color-gs);
        margin-bottom: $minimal-margin;
        
    }
    &__subtitle{
        @include font(sm, bold, center);
        margin-bottom: $small-margin;
    }
    &__content{
        @include font(sm, normal, center);
        margin-bottom: $small-margin;
    }
    &__item {
        @include bp-down('large'){
            padding: 0 !important;
            margin-bottom: $small-margin;
        }
    }


    &--alt {
        position: relative;
        background: transparent !important;

        &::before{
            display: block;
            content: "";
            position: absolute;
            width: 100%;
            height: 650px;
            top: 0;
            background: #F6F6F6;
            z-index: -1;
            transform: translateY(-75%);
        }

        .c-featured-content {
            &__box {
                background: #F6F6F6;
            }
            &__title {
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                line-height: 1.4;
            }
            &__content {
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 24px;
                text-align: center;
            }
        }
    }
}