.c-research{
    &__header {
        @include font(super, bold, left);
        margin-top: $large-margin;
        margin-bottom: $large-margin;
        @include bp-down('medium'){
            @include font(md, bold, left);
            margin-top: 0;
            margin-bottom: $common-margin;
        }
    }
    &__content {
        @include font(sm, normal, left);
        @include bp('medium'){
            @include font(md, normal, left);
        }
        p {
            margin-bottom: $small-margin;
            @include bp('medium'){
            margin-bottom: $medium-margin;
            }
        }
        h2, h3 {
            margin: $small-margin 0;
            @include font(md, bold, left);
            @include bp('medium'){
            margin: $medium-margin 0;
            @include font(lg, bold, left);
            }
        }
    }
}