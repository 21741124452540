.c-compare-images {
    &__title {
        margin-bottom: 0;
    }

    &__subtitle {
        @include font(sm, bold, left);
        
    }

    &__icons {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        @include bp('medium'){
            justify-content: space-between;
        }
    }

    &__item {
        margin: $small-margin $small-margin;
        @include font(sm, bold, center, $color-gs);
        
        @include bp('small'){
            margin: $small-margin $common-margin;
        }
        @include bp('large'){
            margin: $small-margin 0;
        }

        img {
            margin-bottom: $small-margin;
        }
    }
}


.cocoen {
    margin-bottom: $small-margin;
    overflow: visible;
    @include bp('medium'){
        max-width: fit-content;
        max-width: max-content;
    }

    &-drag {
        background-color: $color-gs;
        width: 4px;
    
        &::before {
            cursor: pointer;
            display: flex;
            position: absolute;
            background-color: #25516e;
            padding: 30px;
            border-radius: 50%;
            border: none;
            transform: translateX(-30px);
            top: 0;
            bottom: 0;
            margin: auto;
            height: 30px;
            width: 30px;
        }
        
        &::after {
            content: "";
            background: url(/wp-content/themes/glass-system-chisel/templates/svg/resizer.svg);
            width: 58px;
            height: 10px;
            z-index: 1;
            position: absolute;
            background-repeat: no-repeat;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: scale(1.5);
        }
    }
}

.cocoen > div, .cocoen {
    @include bp('medium'){
        // padding-left: 25px !important;
    }
}