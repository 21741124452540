@charset "UTF-8";
/*
  Project: Glass System
  Author: KERRIS Group
 */
/* Slider */
@import url(/node_modules/nouislider/distribute/nouislider.min.css);
@import url(vendor/cocoen.min.css);
@import url(/node_modules/lightbox2/src/css/lightbox.css);
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-width: 100%;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: font-url("../../src/styles/vendor/fonts/slick.eot");
  src: font-url("../../src/styles/vendor/fonts/slick.eot?#iefix") format("embedded-opentype"), font-url("../../src/styles/vendor/fonts/slick.woff") format("woff"), font-url("../../src/styles/vendor/fonts/slick.ttf") format("truetype"), font-url("../../src/styles/vendor/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Fonts
========================================================================== */
/* Breakpoints
========================================================================== */
/* Colors
========================================================================== */
/* 
    ========================================================================
    #INUIT VARIABLES
    ======================================================================== 

    The inuit variables file contains any variables which has to overwrite 
    default variables.
*/
/*
    This core file sets up inuitcss’ most important setup variables. They
    underpin a lot of how the framework functions and should be modified and
    preconfigured with caution.

    Baseline grid lines height.
    Every spacing metric should be based on this.
*/
/*
    How many grid lines should our spacing unit variants span?
    Each value should be an unitless integer.
*/
/*
    Base typographical styles.
*/
/*
    Base headings.
*/
/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-margin-top {}
 *   .u-padding-left-large {}
 *   .u-margin-right-small {}
 *   .u-padding {}
 *   .u-padding-right-none {}
 *   .u-padding-horizontal {}
 *   .u-padding-vertical-small {}
 */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showMenu {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes hideMenu {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/*
    ==========================================================================||
    Overwrites inuitcss/utilities/widths as it will be used alongside o-flex
    instead of o-layout
    ==========================================================================||
    Inherited from inuitcss
*/
/*
    ==========================================================================||
    Mixin to spit out width classes similar to inuitcss except that
    u-push/u-pull is replaced with u-offset which its direction is influenced
    by o-flex's direction and if $inuit-offsets is set to true

    e.g.
    <div class="u-flex-1/2"></div>
    <div class="u-flex-1/2 u-flex-offset-1/2"></div>
    ==========================================================================||
*/
/* 
    ==========================================================================||
    Various mixins to make development easier should there be different
    behaviour on different breakpoints.
    ==========================================================================||
    ==========================================================================||
    Mixin: inuit-flex-direction($direction)
    Defines flex-direction property for flex container
    ==========================================================================||
    Parameter(s)
    $direction [string] (default: "row") - accepted values:
    - row
    - row-reverse
    - column
    - column-reverse
    ==========================================================================||
*/
/*
    ==========================================================================||
    Mixin: inuit-flex-position($position)
    Defines position of child elements within container
    ==========================================================================||
    Parameter(s)
    $position [string] (default: "lock-top-left") - accepted values:
    - centered
    - centered-v
    - centered-h
    - lock-left
    - lock-right
    - lock-top
    - lock-bottom
    - lock-top-left
    - lock-top-right
    - lock-bottom-left
    - lock-bottom-right
    ==========================================================================||
*/
/*
    ==========================================================================||
    Mixin: inuit-flex-wrap($wrap)
    Defines flex-wrap property for flex container
    ==========================================================================||
    Parameter(s)
    $wrap [string] (default: "wrap") - accepted values:
    - wrap
    - wrap-reverse
    - nowrap
    ==========================================================================||
*/
/*
    ==========================================================================||
    Mixin: inuit-flex-spacing($spacing)
    Defines justify-content property for flex container
    ==========================================================================||
    Parameter(s)
    $spacing [string] (default: "") - accepted values:
    - space-between
    - space-around
    ==========================================================================||
*/
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
@font-face {
  font-family: 'Roboto';
  src: url("../../dist/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("../../dist/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("../../dist/assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

.form {
  transition: all 250ms ease-in-out;
}

.form__container {
  transition: all 250ms ease-in-out;
  width: 100%;
  padding: 10px !important;
}

@media (min-width: 768px) {
  .form__container {
    max-width: 472px;
  }
}

.form label {
  font-size: 16px;
  line-height: 24px;
  display: none;
}

.form__input {
  transition: all 250ms ease-in-out;
  width: 100%;
  border: 1px solid #333;
  margin-bottom: 35px;
  color: #000;
}

.form__input, .form__input::placeholder {
  background-color: #fff;
  color: #000 !important;
  opacity: 1;
  font-size: 16px;
  line-height: 24px;
}

.form__input {
  color: #000;
  padding: 15px;
}

.form__form-info {
  font-size: 14px;
  margin: -20px 0 20px;
}

.form__textarea {
  height: 160px;
}

.form__acceptance {
  transition: all 250ms ease-in-out;
  visibility: hidden;
  position: absolute;
  left: -3333333em;
}

.form__acceptance ~ .wpcf7-list-item-label {
  transition: all 250ms ease-in-out;
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  width: max-content;
  margin-bottom: 0;
  cursor: pointer;
  max-width: calc(85vw - 50px);
  transform: translateY(-5px);
}

@media (min-width: 768px) {
  .form__acceptance ~ .wpcf7-list-item-label {
    max-width: calc(40vw - 100px);
  }
}

@media (min-width: 1280px) {
  .form__acceptance ~ .wpcf7-list-item-label {
    max-width: 400px;
  }
}

.form__acceptance + .form__acceptance--faked::before {
  transition: all 250ms ease-in-out;
  content: "";
  display: inline-block;
  background-color: #fff;
  background: url("../assets/images/not-checked.png");
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border: 1px solid #333;
}

.form__acceptance:checked + .form__acceptance--faked::before {
  transition: all 250ms ease-in-out;
  content: "";
  background: url("../assets/images/checked.png");
}

/*
 * Contact form 7
 */
span.wpcf7-list-item {
  display: block;
  margin: 0 !important;
}

.wpcf7-response-output {
  transition: all 250ms ease-in-out;
  animation: fadeIn 250ms;
  display: block;
  font-size: 14px;
  line-height: 1.5;
  border: none;
  font-weight: bold;
  text-align: center;
  padding: 15px 10px !important;
  margin: 0 !important;
  border: none !important;
  margin-top: 30px !important;
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
  transition: all 250ms ease-in-out;
  animation: fadeIn 250ms;
  background: crimson;
  color: #fff;
}

.wpcf7 form.success .wpcf7-response-output,
.wpcf7 form.sent .wpcf7-response-output {
  transition: all 250ms ease-in-out;
  animation: fadeIn 250ms;
  background: #248093;
  color: #fff;
}

.wpcf7-not-valid-tip {
  transition: all 250ms ease-in-out;
  animation: fadeIn 250ms;
  font-size: 12px !important;
  line-height: 1.2 !important;
  color: crimson !important;
  text-align: right;
  margin-top: -55px;
  margin-right: 5px;
}

.wpcf7-form-control.wpcf7-submit {
  margin: 0 0 0 auto;
  display: block;
}

@media (min-width: 768px) {
  .wpcf7-form-control.wpcf7-submit {
    margin: 0;
    display: inline;
  }
}

.wpcf7-form-control.wpcf7-acceptance label {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.contact-form__submit {
  margin: 30px auto 0 !important;
  display: flex !important;
}

@media (min-width: 1024px) {
  .lb-next, .lb-prev {
    margin-top: 45vh;
    background-position: center center !important;
    width: 50px !important;
    height: 50px !important;
    opacity: 1 !important;
  }
}

.lb-next {
  background: url("/wp-content/themes/glass-system-chisel/templates/svg/arrow-right.svg") no-repeat scroll right 48% transparent !important;
}

@media (max-width: 1023.98px) {
  .lb-next {
    background: url("/wp-content/themes/glass-system-chisel/templates/svg/arrow-right-black.svg") no-repeat scroll right 48% transparent !important;
    transform: translate3d(-10px, 0, 0);
  }
}

@media (min-width: 1024px) {
  .lb-next {
    transform: translate3d(-50px, -100%, 0);
  }
}

.lb-prev {
  background: url("/wp-content/themes/glass-system-chisel/templates/svg/arrow-left.svg") no-repeat scroll left 48% transparent !important;
}

@media (max-width: 1023.98px) {
  .lb-prev {
    background: url("/wp-content/themes/glass-system-chisel/templates/svg/arrow-left-black.svg") no-repeat scroll left 48% transparent !important;
    transform: translate3d(10px, 0, 0);
  }
}

@media (min-width: 1024px) {
  .lb-prev {
    transform: translate3d(50px, -100%, 0);
  }
}

@media (min-width: 1024px) {
  .lb-outerContainer {
    background: transparent !important;
    width: 100% !important;
    margin: auto 0 !important;
  }
}

@media (min-width: 1024px) {
  .lightbox .lb-image {
    margin: auto !important;
    background: white !important;
  }
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  h1 {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }
}

h2 {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}

@media (min-width: 768px) {
  h2 {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 50px;
  }
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #000;
  font-family: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  font-size: 0.8125rem;
  /* [1] */
  /* 13px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@media (min-width: 768px) {
  html {
    font-size: 0.875em;
    /* 14px */
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 0.9375em;
    /* 15px */
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1em;
    /* 16px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #666;
  text-decoration: underline;
}

a:hover {
  color: #999;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

article ul li {
  list-style: none;
}

article p ul li::before,
article .c-carousel-news__article-content ul li::before {
  content: '';
  display: inline-block;
  background: url("/wp-content/themes/glass-system-chisel/dist/assets/images/bullet-2.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 14px;
  width: 12px;
  margin-left: -15px;
  margin-right: 10px;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

.pt-0 {
  padding-top: 0 !important;
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/*
    ==========================================================================||
    By default, items in will be left aligned, wrappable and not growable
    ==========================================================================||
*/
.o-flex {
  display: flex;
}

.o-flex--auto,
.o-flex--grids {
  flex-wrap: wrap;
  margin-left: -30px;
}

.o-flex--auto > .o-flex__item,
.o-flex--grids > .o-flex__item {
  padding-left: 30px;
}

.o-flex--auto > .o-flex__item {
  flex: 1;
}

.o-flex--grids {
  align-items: flex-start;
  flex-flow: row wrap;
}

.o-flex--grids > .o-flex__item {
  flex: 0 0 100%;
  width: 100%;
}

/*
    ==========================================================================||
    Ensures that height of all .o-flex__item to match one another
    ==========================================================================||
  */
.o-flex--grids-stretched {
  align-items: stretch;
}

/*
    ==========================================================================||
    Modifiers for alignment of items
    --centered: center all items vertically and horizontally
    --centered-v: center all items vertically only
    --centered-h: center all items horizontally only
    ==========================================================================||
*/
.o-flex--centered {
  align-items: center;
  justify-content: center;
}

.o-flex--centered-v {
  align-items: center;
}

.o-flex--centered-h {
  justify-content: center;
}

/*
    ==========================================================================||
    Modifiers for layout (direction) of items
    --reversed: flex-direction = row-reverse
    --column: flex-direction = column
    --column-reversed: flex-direction = column-reverse
    ==========================================================================||
*/
.o-flex--reversed {
  flex-direction: row-reverse;
}

.o-flex--relative {
  position: relative;
}

/*
    ==========================================================================||
    Note: flex-direction column/column-reverse will take full width of parent
    container and is not affected by flex-basis to set width

    Reference: https://codepen.io/townivan/post/flexbox-flex-direction-column
    ==========================================================================||
*/
.o-flex--column {
  flex-direction: column;
}

.o-flex--column-reversed {
  flex-direction: column-reverse;
}

/*
    ==========================================================================||
    Modifiers for wrapping of items
    --wrap: flex-wrap = wrap == using .o-flex--grids
    --wrap-reverse: flex-wrap = wrap-reverse
    ==========================================================================||
*/
.o-flex--nowrap {
  flex-wrap: nowrap;
}

.o-flex--wrap {
  flex-wrap: wrap;
}

.o-flex--wrap-reverse {
  flex-wrap: wrap-reverse;
}

/*
    ==========================================================================||
    Modifiers for adding spacing between elements
    --space-between: adds spacing between items only
    --space-around: adds spacing on both side of all items
    ==========================================================================||
*/
.o-flex--space-between {
  justify-content: space-between;
}

.o-flex--space-around {
  justify-content: space-around;
}

/*
    ==========================================================================||
    Modifiers for locking elements in respect to container
    --lock-left: lock elements to left side
    --lock-right: lock elements to right side
    --lock-top: lock elements to top side
    --lock-bottom: lock elements to bottom side
    --lock-top-left: lock elements to top left side
    --lock-top-right: lock elements to top right side
    --lock-bottom-left: lock elements to bottom left side
    --lock-bottom-right: lock elements to bottom right side
    ==========================================================================||
*/
.o-flex--lock-left {
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.o-flex--lock-right {
  align-content: center;
  align-items: center;
  justify-content: flex-end;
}

.o-flex--lock-center {
  align-content: center;
  align-items: center;
  justify-content: center;
}

.o-flex--lock-top {
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
}

.o-flex--lock-bottom {
  align-content: flex-end;
  align-items: flex-end;
  justify-content: center;
}

.o-flex--lock-top-left {
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}

.o-flex--lock-top-right {
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-end;
}

.o-flex--lock-bottom-left {
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-start;
}

.o-flex--lock-bottom-right {
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}

.o-wrapper:after {
  clear: both !important;
}

@media (min-width: 1280px) {
  .o-wrapper {
    max-width: 1170px;
    width: 1170px;
  }
}

@media (min-width: 1024px) {
  .o-wrapper--wider {
    width: 110%;
    transform: translateX(-4.5%);
  }
}

.c-archive__container {
  position: relative;
}

.c-archive .c-breadcrumbs {
  position: absolute;
  left: 0;
  top: 20px;
}

.c-archive__title {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 50px;
  line-height: 58px;
  margin-bottom: 0;
}

.c-archive__subtitle {
  background: none;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
}

.c-archive__productions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}

.c-archive__productions .c-carousel-productions__item {
  max-width: 100%;
}

@media (min-width: 768px) {
  .c-archive__productions .c-carousel-productions__item {
    max-width: 50%;
  }
}

@media (max-width: 767.98px) {
  .c-archive__productions .c-carousel-productions__item-image {
    height: 260px;
  }
}

.eu-banners {
  max-width: 100%;
  margin: auto;
  transition: all 250ms ease-in-out;
}

@media (min-width: 768px) {
  .eu-banners {
    margin-bottom: 85px !important;
  }
}

.c-breadcrumbs {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #25516e;
  position: absolute;
  top: -35px;
  z-index: 10;
}

@media (max-width: 767.98px) {
  .c-breadcrumbs {
    line-height: 24px;
    top: -60px;
  }
}

.c-breadcrumbs--static {
  position: static;
}

.c-breadcrumbs__separator {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #25516e;
}

.c-breadcrumbs a {
  transition: all 250ms ease-in-out;
  color: #8d8d8d;
  text-decoration: none;
}

.c-breadcrumbs a:hover {
  color: #25516e;
}

.c-breadcrumbs span span * {
  margin-left: 2px;
  margin-right: 2px;
}

.c-breadcrumbs span span:first-child {
  margin-left: 0;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  width: auto;
  letter-spacing: 0;
  background-color: #25516e;
  text-decoration: none;
  transition: all 250ms ease-in-out;
  width: max-content;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  /* Style variants
  ========================================================================== */
}

@media (min-width: 768px) {
  .c-btn {
    padding: 12px 35px;
  }
}

.c-btn svg {
  fill: #fff;
}

.c-btn--larger {
  justify-content: center;
  width: 100%;
  padding: 15px 30px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

@media (min-width: 768px) {
  .c-btn--larger {
    padding: 25px 30px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    width: max-content;
  }
}

.c-btn--extra-wide {
  display: block;
  padding: 20px 85px;
}

@media (max-width: 479.98px) {
  .c-btn--full-width {
    width: calc(100vw - 50px);
    margin-top: 20px;
  }
}

.c-btn--smaller {
  padding: 7px 35px !important;
}

.c-btn--corner {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 768px) {
  .c-btn--corner {
    left: 0;
    right: auto;
  }
}

.c-btn--with-icon {
  display: flex;
  align-items: center;
}

.c-btn__icon {
  margin-left: 20px;
}

.c-btn--white {
  background: #fff;
  color: #25516e;
}

.c-btn--white svg {
  transition: all 250ms ease-in-out;
  fill: #25516e !important;
}

.c-btn--white:hover {
  transition: all 250ms ease-in-out;
  background: #25516e;
  color: #fff;
}

.c-btn--white:hover svg {
  transition: all 250ms ease-in-out;
  fill: #fff !important;
}

.c-btn--marged {
  margin: 10px 0 0 0;
}

@media (min-width: 768px) {
  .c-btn--marged {
    margin: 0 7px;
  }
}

.c-btn--p-less {
  padding: 10px;
}

.c-btn:hover, .c-btn:active, .c-btn:focus {
  color: #fff;
}

.c-btn:hover, .c-btn:focus {
  background-color: #333;
}

.c-carousel-news {
  position: relative;
}

.c-carousel-news__header {
  margin: auto;
  text-align: center;
  margin-bottom: 50px;
}

.c-carousel-news__carousel {
  position: relative;
}

.c-carousel-news__item {
  width: 90% !important;
  height: 265px;
  max-width: 100%;
  padding: 0;
  margin: 0 5%;
  position: relative;
  cursor: pointer;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.c-carousel-news__item-desc {
  transition: all 250ms ease-in-out;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 35px;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 767.98px) {
  .c-carousel-news__item-desc {
    font-size: 20px;
  }
}

.c-carousel-news__item.is-active {
  transition: all 250ms ease-in-out;
}

.c-carousel-news__item.is-active .c-carousel-news__item-desc {
  transition: all 250ms ease-in-out;
  background: rgba(254, 195, 20, 0.75);
  color: #000;
}

.c-carousel-news__item-subtitle {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: none;
}

.c-carousel-news__items {
  background-color: #fff;
}

.c-carousel-news__items .slick-active {
  opacity: 1;
}

.c-carousel-news__items--second {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -1;
  opacity: 0.3;
}

@media (max-width: 1023.98px) {
  .c-carousel-news__items--second {
    display: none;
  }
}

.c-carousel-news__items--second .slick-slide {
  transform: scale(0.8);
}

.c-carousel-news__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;
}

.c-carousel-news__arrow svg {
  width: 44px;
  height: 20px;
}

.c-carousel-news__arrow--right {
  right: -5px;
}

@media (min-width: 768px) {
  .c-carousel-news__arrow--right {
    right: -45px;
  }
}

.c-carousel-news__arrow--left {
  left: -5px;
}

@media (min-width: 768px) {
  .c-carousel-news__arrow--left {
    left: -45px;
  }
}

.c-carousel-news__article {
  padding-left: 5% !important;
  padding-right: 5% !important;
}

@media (min-width: 768px) {
  .c-carousel-news__article {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
}

.c-carousel-news__article-title {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
}

@media (min-width: 768px) {
  .c-carousel-news__article-title {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 600;
    font-size: 50px;
    line-height: 58px;
    margin-bottom: 50px;
  }
}

.c-carousel-news__article-content {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

@media (min-width: 768px) {
  .c-carousel-news__article-content {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
  }
}

.c-carousel-news__article-content p {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .c-carousel-news__article-content p {
    margin-bottom: 50px;
  }
}

.c-carousel-news__article-content h2, .c-carousel-news__article-content h3 {
  margin: 20px 0;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
}

@media (min-width: 768px) {
  .c-carousel-news__article-content h2, .c-carousel-news__article-content h3 {
    margin: 50px 0;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }
}

.c-carousel-news__date {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 20px;
}

.c-carousel-timeline__slide .c-carousel-news__item {
  max-width: 100%;
}

@media (min-width: 768px) {
  .c-carousel-timeline__slide .c-carousel-news__item {
    margin: 0 5%;
    width: 90% !important;
    height: 300px !important;
  }
}

@media (max-width: 767.98px) {
  .c-carousel-timeline__slide .c-carousel-news__item {
    margin: 0;
    width: 100% !important;
  }
}

@media (max-width: 767.98px) {
  .c-carousel-timeline__slide .c-carousel-news__arrow--right {
    right: -25px !important;
  }
}

@media (max-width: 767.98px) {
  .c-carousel-timeline__slide .c-carousel-news__arrow--left {
    left: -25px !important;
  }
}

.c-carousel-productions {
  position: relative;
}

.c-carousel-productions__header {
  margin: auto;
  text-align: center;
  margin-bottom: 50px;
}

.c-carousel-productions__carousel {
  position: relative;
}

.c-carousel-productions__item {
  width: 600px !important;
  max-width: 100%;
  padding: 15px;
}

.c-carousel-productions__item-image {
  display: block;
  height: 406px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.c-carousel-productions__item-desc {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.c-carousel-productions__item-desc-icons {
  display: flex;
  align-items: center;
}

.c-carousel-productions__item-desc-icons ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-carousel-productions__item-desc-icons ul li {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 0;
}

.c-carousel-productions__item-desc-icons ul li span {
  font-size: 12px;
  line-height: 13px;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin-left: 7px;
}

.c-carousel-productions__item-desc-icons ul li span.inactive {
  color: #8F8F8F;
}

.c-carousel-productions__item-desc-link {
  transform: translateY(-4px);
}

.c-carousel-productions__item-desc-link a {
  background-color: #25516e;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
}

.c-carousel-productions__item-desc-link a svg {
  position: absolute;
  fill: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.c-carousel-productions__item-desc-link a:hover {
  background-color: #122735;
}

.c-carousel-productions__items {
  background-color: #fff;
}

.c-carousel-productions__items .slick-active {
  opacity: 1;
}

.c-carousel-productions__items--second {
  position: absolute;
  width: 100%;
  top: 65px;
  z-index: -1;
  opacity: 0.5;
}

.c-carousel-productions__items--second .c-carousel-productions__item-image {
  height: 260px;
}

.c-carousel-productions__arrow {
  position: absolute;
  top: 43%;
  transform: translateY(-50%);
  cursor: pointer;
}

.c-carousel-productions__arrow svg {
  width: 44px;
  height: 20px;
}

.c-carousel-productions__arrow--right {
  right: -45px;
}

.c-carousel-productions__arrow--left {
  left: -45px;
}

.c-carousel-research {
  position: relative;
}

.c-carousel-research__header {
  margin: auto;
  text-align: center;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .c-carousel-research__header {
    margin-bottom: 50px;
  }
}

.c-carousel-research__carousel {
  position: relative;
}

.c-carousel-research__item {
  width: 90% !important;
  height: auto;
  max-width: 100%;
  padding: 0;
  margin: 0 5%;
  position: relative;
  cursor: pointer;
}

.c-carousel-research__item-icon {
  width: 100%;
  height: 265px;
  background-repeat: no-repeat;
  background-size: 115px;
  background-position: center;
  background-color: #ccc;
}

.c-carousel-research__item-desc {
  transition: all 250ms ease-in-out;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  color: #25516e;
  background: #f5f5f5;
  height: 180px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 35px 35px;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.2;
}

.c-carousel-research__item-desc a {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  color: #25516e;
  text-decoration: none;
}

.c-carousel-research__item.is-active {
  transition: all 250ms ease-in-out;
}

.c-carousel-research__item-subtitle {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
  text-transform: none;
}

.c-carousel-research__btn {
  color: #fff !important;
}

.c-carousel-research__items {
  background-color: #fff;
}

.c-carousel-research__items .slick-active {
  opacity: 1;
}

.c-carousel-research__items .is-current-post .c-carousel-research__item-desc,
.c-carousel-research__items .is-current-post .c-carousel-research__item-icon {
  background-color: #FEC314 !important;
  color: #000 !important;
}

.c-carousel-research__items--second {
  position: absolute;
  width: 100%;
  top: 30px;
  z-index: -1;
  opacity: 0.3;
}

@media (max-width: 1023.98px) {
  .c-carousel-research__items--second {
    display: none;
  }
}

.c-carousel-research__items--second .slick-slide {
  opacity: 0.8 !important;
  transform: scale(0.8);
}

.c-carousel-research__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;
}

.c-carousel-research__arrow svg {
  width: 44px;
  height: 20px;
}

.c-carousel-research__arrow--right {
  right: -5px;
}

@media (min-width: 768px) {
  .c-carousel-research__arrow--right {
    right: -45px;
  }
}

.c-carousel-research__arrow--left {
  left: -5px;
}

@media (min-width: 768px) {
  .c-carousel-research__arrow--left {
    left: -45px;
  }
}

.c-carousel-research__article {
  padding-left: 5% !important;
  padding-right: 5% !important;
}

@media (min-width: 768px) {
  .c-carousel-research__article {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
}

.c-carousel-research__article-title {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
}

@media (min-width: 768px) {
  .c-carousel-research__article-title {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 600;
    font-size: 50px;
    line-height: 58px;
    margin-bottom: 50px;
  }
}

.c-carousel-research__article-content {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

@media (min-width: 768px) {
  .c-carousel-research__article-content {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
  }
}

.c-carousel-research__article-content p {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .c-carousel-research__article-content p {
    margin-bottom: 50px;
  }
}

.c-carousel-research__article-content h2, .c-carousel-research__article-content h3 {
  margin: 20px 0;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
}

@media (min-width: 768px) {
  .c-carousel-research__article-content h2, .c-carousel-research__article-content h3 {
    margin: 50px 0;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }
}

body.single-research .c-carousel-research__items--second {
  top: -50px;
}

body.single-research .c-carousel-research__items a {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  color: #25516e;
  text-decoration: none;
}

.c-carousel-team {
  background: linear-gradient(#fff 20%, #f5f5f5 20%);
}

@media (min-width: 1024px) {
  .c-carousel-team {
    background: linear-gradient(#fff 50%, #f5f5f5 50%);
  }
}

@media (min-width: 1800px) {
  .c-carousel-team {
    position: relative;
  }
}

@media (min-width: 1024px) and (max-width: 1800px) {
  .c-carousel-team__container {
    background: linear-gradient(#fff 39px, #f5f5f5 39px);
  }
}

.c-carousel-team__header {
  margin: auto;
  text-align: left;
  margin-bottom: 35px;
  margin-top: 50px;
}

.c-carousel-team__carousel {
  position: relative;
  transform: translateY(-50px);
  z-index: 3;
}

.c-carousel-team__image {
  width: 180px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 767.98px) {
  .c-carousel-team__image {
    margin: auto;
  }
}

.c-carousel-team__item {
  width: 90% !important;
  height: auto;
  max-width: 100%;
  padding: 0;
  margin: 0 5%;
  position: relative;
  cursor: pointer;
}

.c-carousel-team__item-desc {
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  min-height: 350px;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .c-carousel-team__item-desc {
    width: 80%;
    min-height: 350px;
    justify-content: space-between;
  }
}

.c-carousel-team__items {
  background-color: #fff;
}

.c-carousel-team__items .slick-active {
  opacity: 1;
}

.c-carousel-team__items--second {
  position: absolute;
  width: 100%;
  top: 160px;
  z-index: 2;
  opacity: 1;
}

@media (max-width: 1023.98px) {
  .c-carousel-team__items--second {
    display: none;
  }
}

.c-carousel-team__items--second .slick-slide {
  opacity: 0.4 !important;
  transform: scale(0.8);
}

.c-carousel-team__items--second .slick-track {
  background: linear-gradient(#fff 15.2%, #f5f5f5 15.2%) !important;
}

.c-carousel-team__arrow {
  position: absolute;
  top: 12%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;
}

.c-carousel-team__arrow svg {
  width: 44px;
  height: 20px;
}

.c-carousel-team__arrow--right {
  right: -5px;
}

@media (min-width: 768px) {
  .c-carousel-team__arrow--right {
    right: -45px;
  }
}

.c-carousel-team__arrow--left {
  left: -5px;
}

@media (min-width: 768px) {
  .c-carousel-team__arrow--left {
    left: -45px;
  }
}

.c-carousel-team__name {
  display: block;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 5px;
}

.c-carousel-team__work {
  display: block;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

.c-carousel-team__about {
  display: block;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.c-carousel-team .c-menu--horizontal {
  width: 100%;
  border-top: 1px solid #999;
}

.c-carousel-team .c-menu--horizontal li {
  transform: scale(1.3);
}

.c-carousel-team .c-menu--icons {
  justify-content: start;
  padding: 20px 0;
}

.c-carousel-team__bottom {
  width: 100%;
}

.c-carousel-team__bottom svg {
  width: 20px;
  height: 20px;
}

@media (min-width: 768px) {
  .c-carousel-team__career-btn {
    transform: translateY(-40px);
  }
}

.c-carousel-team .slick-track,
.c-carousel-team .js-slider {
  background: linear-gradient(#fff 12%, #f5f5f5 12%);
}

@media (max-width: 1023px) {
  .c-carousel-team .slick-track,
  .c-carousel-team .js-slider {
    background: transparent;
  }
}

@media (max-width: 1899.98px) {
  .js-slider-second .slick-slide {
    visibility: hidden !important;
  }
}

.c-carousel-timeline {
  position: relative;
  transition: all 250ms ease-in-out;
}

.c-carousel-timeline::before {
  transition: all 250ms ease-in-out;
  content: '';
  width: 43%;
  height: 30%;
  background: #e1e2e3;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 350px;
}

@media (max-width: 767.98px) {
  .c-carousel-timeline::before {
    display: none;
  }
}

.c-carousel-timeline__header {
  transition: all 250ms ease-in-out;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .c-carousel-timeline__header {
    padding-bottom: 50px !important;
  }
}

.c-carousel-timeline__line {
  transition: all 250ms ease-in-out;
  position: relative;
  height: 1px;
  overflow: visible;
  background: #999;
  width: 85%;
  margin: 10px auto;
  padding: 0 10px;
  align-items: flex-end;
}

@media (max-width: 767.98px) {
  .c-carousel-timeline__line {
    width: 95%;
    padding: 0;
    margin-bottom: 30px;
  }
}

.c-carousel-timeline__nav {
  transition: all 250ms ease-in-out;
  position: relative;
}

.c-carousel-timeline__arrow {
  transition: all 250ms ease-in-out;
  position: absolute;
  bottom: -13px;
  cursor: pointer;
  display: block;
}

@media (max-width: 767.98px) {
  .c-carousel-timeline__arrow {
    bottom: -50px;
  }
}

.c-carousel-timeline__arrow svg {
  transition: all 250ms ease-in-out;
  height: auto;
  width: 30px;
}

.c-carousel-timeline__arrow--right {
  right: 20px;
}

@media (max-width: 767.98px) {
  .c-carousel-timeline__arrow--right {
    right: 80px;
  }
}

.c-carousel-timeline__arrow--left {
  left: 20px;
}

@media (max-width: 767.98px) {
  .c-carousel-timeline__arrow--left {
    left: 80px;
  }
}

.c-carousel-timeline__step {
  transition: all 250ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -50px;
  transform: translateY(12px);
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .c-carousel-timeline__step {
    margin-top: -45px;
  }
}

.c-carousel-timeline__step.is-active {
  transition: all 250ms ease-in-out;
}

.c-carousel-timeline__step.is-active .c-carousel-timeline__point {
  pointer-events: none;
  transition: all 250ms ease-in-out;
  background: #25516e;
}

.c-carousel-timeline__step.is-active .c-carousel-timeline__label {
  pointer-events: none;
  transition: all 250ms ease-in-out;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #25516e;
  display: block;
}

@media (max-width: 767.98px) {
  .c-carousel-timeline__step.is-active .c-carousel-timeline__label {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #25516e;
  }
}

.c-carousel-timeline__point {
  transition: all 250ms ease-in-out;
  background: transparent;
  border-radius: 50%;
  margin: auto;
  margin-top: 20px;
  pointer-events: none;
  z-index: -1;
}

.c-carousel-timeline__point circle {
  pointer-events: none;
  transition: all 250ms ease-in-out;
  fill: #FEC314;
}

.c-carousel-timeline__label {
  pointer-events: none;
  transition: all 250ms ease-in-out;
  max-width: min-content;
  min-width: 60px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #666;
}

@media (max-width: 767.98px) {
  .c-carousel-timeline__label {
    display: none;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #666;
  }
}

.c-carousel-timeline__image {
  transition: all 250ms ease-in-out;
  min-height: 330px;
  height: 330px;
  width: 100%;
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

@media (max-width: 767.98px) {
  .c-carousel-timeline__image {
    margin-bottom: 20px;
  }
}

.c-carousel-timeline__caption {
  transition: all 250ms ease-in-out;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #000;
  position: static;
  background: transparent;
  min-height: 86px;
  display: flex;
  align-items: center;
  justify-content: start;
  display: flex !important;
  align-items: center;
  padding: 15px 30px;
}

@media (max-width: 479.98px) {
  .c-carousel-timeline__caption {
    align-items: flex-start;
    min-height: 30px;
    padding: 15px 0;
  }
}

.c-carousel-timeline__slide {
  width: 100%;
  display: none;
}

.c-carousel-timeline__slide.is-active {
  display: block;
}

.c-carousel-timeline__slide .slick-track {
  display: flex;
  justify-content: center;
}

.c-carousel-timeline__container {
  min-height: 430px;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .c-carousel-timeline__container {
    margin-top: 50px;
  }
}

.c-carousel-timeline .c-carousel-news__items {
  background-color: transparent;
}

.c-carousel-timeline__arrow--secondary {
  position: absolute;
  top: 40.5%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;
}

.c-carousel-timeline.stepsnames-extended .c-carousel-timeline__step .c-carousel-timeline__label {
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 0;
  max-width: max-content !important;
}

.c-carousel-timeline.stepsnames-extended .c-carousel-timeline__step.is-active .c-carousel-timeline__label {
  font-weight: bold !important;
}

.c-carousel-timeline.graybar-extended::before {
  content: '';
  height: 115%;
  top: 270px;
}

.c-chessboard__header {
  padding-top: 20px;
}

.c-chessboard__box {
  background: #f5f5f5;
  padding: 20px;
}

@media (min-width: 768px) {
  .c-chessboard__box:nth-of-type(2), .c-chessboard__box:nth-of-type(3), .c-chessboard__box:nth-of-type(6), .c-chessboard__box:nth-of-type(7), .c-chessboard__box:nth-of-type(9), .c-chessboard__box:nth-of-type(10) {
    background: #e0e1e2;
  }
}

@media (min-width: 768px) {
  .c-chessboard__box:nth-of-type(odd) {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (min-width: 768px) {
  .c-chessboard__box:nth-of-type(even) {
    padding-left: 20px;
    padding-right: 35px;
  }
}

@media (max-width: 767.98px) {
  .c-chessboard__box:nth-of-type(even) {
    background: #e0e1e2;
  }
}

.c-chessboard__top {
  margin-bottom: 20px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #25516e;
}

.c-chessboard__title {
  margin-left: 20px;
}

.c-chessboard__icon {
  width: 55px;
  height: 55px;
}

.c-compare-images__title {
  margin-bottom: 0;
}

.c-compare-images__subtitle {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

.c-compare-images__icons {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .c-compare-images__icons {
    justify-content: space-between;
  }
}

.c-compare-images__item {
  margin: 20px 20px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #25516e;
}

@media (min-width: 480px) {
  .c-compare-images__item {
    margin: 20px 35px;
  }
}

@media (min-width: 1024px) {
  .c-compare-images__item {
    margin: 20px 0;
  }
}

.c-compare-images__item img {
  margin-bottom: 20px;
}

.cocoen {
  margin-bottom: 20px;
  overflow: visible;
}

@media (min-width: 768px) {
  .cocoen {
    max-width: fit-content;
    max-width: max-content;
  }
}

.cocoen-drag {
  background-color: #25516e;
  width: 4px;
}

.cocoen-drag::before {
  cursor: pointer;
  display: flex;
  position: absolute;
  background-color: #25516e;
  padding: 30px;
  border-radius: 50%;
  border: none;
  transform: translateX(-30px);
  top: 0;
  bottom: 0;
  margin: auto;
  height: 30px;
  width: 30px;
}

.cocoen-drag::after {
  content: "";
  background: url(/wp-content/themes/glass-system-chisel/templates/svg/resizer.svg);
  width: 58px;
  height: 10px;
  z-index: 1;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: scale(1.5);
}

.contact__text {
  padding-top: 50px;
}

@media (min-width: 768px) {
  .contact__text {
    padding-top: 0;
  }
}

.contact__text p {
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .contact__text p {
    margin-bottom: 35px;
  }
}

.contact__text h2, .contact__text h3 {
  margin-bottom: 10px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

@media (min-width: 768px) {
  .contact__text h2, .contact__text h3 {
    margin-bottom: 35px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }
}

.contact__data {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .contact__data {
    margin-bottom: 35px;
  }
}

.contact__tel {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .contact__tel {
    margin-bottom: 35px;
  }
}

.contact__tel p,
.contact__tel a {
  font-size: 16px;
  line-height: 24px;
  margin: 0 !important;
  text-decoration: none;
  color: #000;
  transition: all 250ms ease-in-out;
}

@media (max-width: 767.98px) {
  .contact__tel p,
  .contact__tel a {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
}

.contact__tel a:hover {
  color: #25516e;
}

.contact__email {
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .contact__email {
    margin-bottom: 35px;
  }
}

.contact__email p,
.contact__email a {
  font-size: 16px;
  line-height: 24px;
  margin: 0 !important;
  color: #000;
  transition: all 250ms ease-in-out;
}

.contact__email a {
  color: #25516e;
}

.contact__email a:hover {
  color: #626262;
}

@media (max-width: 767.98px) {
  .contact__content {
    padding: 0 10px;
  }
}

@media (max-width: 767.98px) {
  .contact__form {
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .contact__form {
    padding: 20px;
  }
}

@media (min-width: 1024px) {
  .contact__form {
    padding-left: 20px;
    padding-right: 0;
  }
}

.contact__header {
  display: block;
  text-align: center;
}

.contact__page, .contact__page a, .contact__page p {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

@media (min-width: 480px) {
  .contact__page, .contact__page a, .contact__page p {
    font-size: 13px !important;
  }
}

.contact__page a {
  color: #25516e;
}

.contact__c-btn-container {
  text-align: center !important;
}

.contact .wpcf7-list-item-label {
  transition: all 250ms ease-in-out;
}

@media (min-width: 768px) {
  .contact .wpcf7-list-item-label {
    transform: translateY(-3px);
  }
}

.contact .wpcf7-not-valid-tip {
  margin-bottom: 30px;
}

.contact .form__input,
.contact .form__input::placeholder,
.contact .form__acceptance--faked::before {
  border-color: #8d8d8d !important;
  color: #000 !important;
}

.contact .form__input {
  color: #000 !important;
}

.contact .invalid .wpcf7-response-output {
  margin-top: 0 !important;
}

@media (min-width: 768px) {
  .contact .invalid .wpcf7-response-output {
    margin-top: 30px !important;
    margin-bottom: -50px !important;
  }
}

.c-downloads__file {
  margin-bottom: 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  position: relative;
}

.c-downloads__file--secondary {
  width: 400px;
  max-width: 100%;
  padding-bottom: 10px !important;
}

@media (max-width: 479.98px) {
  .c-downloads__file--secondary {
    width: 200px;
  }
}

.c-downloads__file--tetriary {
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.c-downloads__image-container {
  display: block;
  width: 100%;
  max-height: 240px;
  height: 240px;
  text-align: left;
}

.c-downloads__image-container--left {
  max-height: 120px;
  max-width: 85px;
  width: auto;
  margin-right: 15px;
}

.c-downloads__image-container--draw {
  cursor: pointer;
  max-height: 269px;
  max-width: 100%;
  height: 269px;
  width: 370px;
  margin-right: 30px;
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
}

.c-downloads__image {
  max-width: 100%;
  max-height: 240px;
  width: auto;
  height: auto;
  display: block;
  margin: auto 0;
}

.c-downloads__footer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: 60px;
}

.c-downloads__footer--tetriary {
  width: 370px;
  max-width: 100%;
}

.c-downloads__name {
  transition: all 250ms ease-in-out;
  color: #000000;
  font-family: Roboto;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 29px;
  max-width: 220px;
  text-decoration: none;
}

.c-downloads__name:hover {
  color: #25516e;
}

.c-downloads__name--secondary {
  max-width: 170px;
}

.c-downloads__name--tetriary {
  max-width: calc(100% - 30px);
  padding-bottom: 20px;
}

.c-downloads__icon {
  position: absolute;
  top: 0;
  right: 0;
}

.c-downloads__tag {
  margin: 20px 0;
  color: #25516E;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border-radius: 3px;
  background-color: #FEC314;
  width: max-content;
  padding: 3px 13px;
}

.c-downloads__tag--secondary {
  margin-top: 0;
}

@media (max-width: 479.98px) {
  .c-downloads__filter {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

.c-downloads__selection {
  cursor: pointer;
  margin-left: 30px;
  background: white;
  color: black;
  padding: 10px 8px;
  width: 370px;
  border: 1px solid #8F8F8F;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background: url("/wp-content/themes/glass-system-chisel/dist/assets/images/arrows/arrow-filled-bottom.png");
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
  background-size: 14px;
}

.c-downloads__selection::-ms-expand {
  display: none;
}

@media (max-width: 479.98px) {
  .c-downloads__selection {
    max-width: 100%;
    display: block;
    margin: 0;
  }
}

.c-downloads__selection option {
  background-color: #FFFFFF;
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

@media (max-width: 479.98px) {
  .c-downloads__draw {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.c-faq__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
}

.c-faq__item.is-active .c-faq__header {
  transition: all 250ms ease-in-out;
  animation: fadeIn 300ms ease;
  background: #25516E;
  color: white;
}

.c-faq__item.is-active .c-faq__answer {
  transition: all 250ms ease-in-out;
  animation: fadeIn 300ms ease;
  display: flex;
}

.c-faq__item.is-active .c-faq__icon {
  transition: all 250ms ease-in-out;
  animation: fadeIn 300ms ease;
  background: url(../assets/images/arrows/close.svg);
}

.c-faq__header {
  transition: all 250ms ease-in-out;
  animation: fadeIn 300ms ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  min-height: 77px;
  background: #E1E2E3;
  color: #25516E;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .c-faq__header {
    padding: 20px;
  }
}

@media (max-width: 767.98px) {
  .c-faq__question {
    display: block;
    width: calc(85%);
    font-size: 12px;
  }
}

.c-faq__answer {
  transition: all 250ms ease-in-out;
  border: 2px solid #E1E2E3;
  display: none;
  justify-content: normal;
  align-items: center;
  vertical-align: middle;
  padding: 30px;
}

.c-faq__answer p img {
  display: inline;
  margin: 0px 30px;
}

@media (max-width: 767.98px) {
  .c-faq__answer {
    flex-direction: column;
  }
}

.c-faq__icon {
  transition: all 250ms ease-in-out;
  animation: fadeIn 300ms ease;
  display: block;
  width: 19px;
  height: 19px;
  background: url(../assets/images/arrows/chevron.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  pointer-events: none;
}

.c-featured-content_content {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  color: #000;
  margin-bottom: 20px;
}

.c-featured-content__box {
  background: #fff;
}

.c-featured-content__image {
  min-height: 265px;
  height: 265px;
  width: 100%;
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.c-featured-content__desc {
  padding: 20px;
  justify-content: space-between;
}

.c-featured-content__title {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  color: #25516e;
  margin-bottom: 10px;
}

.c-featured-content__subtitle {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
}

.c-featured-content__content {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
}

@media (max-width: 1023.98px) {
  .c-featured-content__item {
    padding: 0 !important;
    margin-bottom: 20px;
  }
}

.c-featured-content--alt {
  position: relative;
  background: transparent !important;
}

.c-featured-content--alt::before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 650px;
  top: 0;
  background: #F6F6F6;
  z-index: -1;
  transform: translateY(-75%);
}

.c-featured-content--alt .c-featured-content__box {
  background: #F6F6F6;
}

.c-featured-content--alt .c-featured-content__title {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  line-height: 1.4;
}

.c-featured-content--alt .c-featured-content__content {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.footer {
  padding: 20px;
}

@media (max-width: 1023.98px) {
  .footer-blue > div {
    flex-direction: column !important;
  }
  .footer-blue > div .c-header__login-btn {
    margin: 20px;
  }
}

.footer-blue__credits {
  transition: all 250ms ease-in-out;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ccc;
  font-size: 12px;
  text-decoration: none;
}

@media (max-width: 479.98px) {
  .footer-blue__credits {
    display: block;
    margin: 20px 0 0;
    text-align: left;
  }
}

.footer-blue__credits:hover {
  transition: all 250ms ease-in-out;
  color: #FEC314;
}

@media (min-width: 768px) {
  .footer {
    padding: 0;
    margin-top: 50px;
  }
}

.footer__logo {
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .footer__logo {
    padding: 25px 0 0 0 !important;
  }
}

.footer__logo svg {
  display: block;
  height: auto;
  width: auto;
}

@media (min-width: 768px) {
  .footer__logo svg {
    height: 55px;
    width: auto;
  }
}

.footer__menus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .footer__menus {
    flex-direction: row;
  }
}

.footer__menu {
  text-decoration: none;
  list-style: none;
  padding: 0;
  margin-left: 0;
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .footer__menu {
    margin-bottom: 0;
  }
}

.footer__menu-header {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .footer__menu-header {
    margin-bottom: 50px;
  }
}

.footer__menu li a {
  font-size: 16px;
  line-height: 19px;
  color: #25516e;
  text-decoration: underline;
}

.footer__sm-links {
  display: flex;
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .footer__sm-links {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .footer__sm-links ul {
    margin-left: 0;
  }
}

.footer__sm-links a img {
  max-width: 24px;
}

.footer__sm-link {
  margin-left: 30px;
}

.footer__sm-link:first-of-type {
  margin-left: 0;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  background: #f6f6f6;
}

.c-header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 66px;
}

.c-header__logo {
  display: block;
  height: auto;
  width: 150px;
  transition: all 250ms ease-in-out;
}

@media (min-width: 1280px) {
  .c-header__logo {
    height: 40px;
    width: 215px;
  }
}

.c-header__logo svg {
  height: auto;
  width: auto;
}

@media (max-width: 1279.98px) {
  .c-header__content {
    position: absolute;
    top: 66px;
    right: 0;
    left: 0;
    display: none;
    flex-direction: column;
    z-index: 100;
    background: #f6f6f6;
    animation: showMenu 250ms ease-in-out;
    padding: 0;
    min-height: calc(100% - 66px);
    overflow-y: scroll;
    background: #25516e;
    color: #fff;
    overflow: visible;
  }
  .c-header__content--hidden {
    display: flex;
    transform: translateX(-100%);
    animation: hideMenu 250ms ease-in-out;
  }
  .c-header__content--showed {
    display: flex;
    animation: showMenu 250ms ease-in-out;
  }
}

@media (min-width: 1280px) {
  .c-header__content {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
}

.c-header__hamburger {
  display: block;
  cursor: pointer;
}

.c-header__hamburger svg {
  fill: #333;
}

@media (min-width: 1280px) {
  .c-header__hamburger {
    display: none;
  }
}

@media (max-width: 1279.98px) {
  .c-header__social-mobile {
    display: block;
    transform: translateY(19%);
  }
  .c-header__social-mobile .c-menu__link {
    padding: 5px;
  }
}

.c-header__small-bar {
  height: 30px;
  background-color: #cccccc;
}

.c-header__small-bar .c-menu--icons a {
  padding: 0 5px;
  height: auto;
}

.c-header__login-btn {
  transition: all 250ms ease-in-out;
  background: #25516e;
  color: white;
  text-decoration: none;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  height: 30px;
  display: flex;
  line-height: 1;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.c-header__login-btn:hover {
  color: white;
}

.c-header__login-btn::after {
  background: url("/wp-content/themes/glass-system-chisel/src/assets/images/unlocked.svg");
  display: block;
  content: "";
  width: 19px;
  height: 22px;
  background-repeat: no-repeat;
  margin-left: 10px;
}

.c-header__login-btn--alt {
  background: white;
  color: #25516e;
}

.c-header__login-btn--alt:hover {
  color: #25516e;
}

.c-header__login-btn--alt::after {
  background: url("/wp-content/themes/glass-system-chisel/src/assets/images/unlocked-blue.svg");
  display: block;
  content: "";
  width: 19px;
  height: 22px;
  background-repeat: no-repeat;
  margin-left: 10px;
}

@media (min-width: 1280px) {
  .c-header--alt-color .menu-item-has-children:hover::after {
    border-bottom: 15px solid #25516e !important;
  }
}

.c-header--alt-color .wpml-ls-sub-menu::before {
  background: #25516e;
}

.c-header--alt-color .wpml-ls-sub-menu::after {
  border-bottom: 15px solid #25516e !important;
}

.c-header--alt-color .c-menu__nav-category {
  background: #25516e;
  color: #fff !important;
}

@media (max-width: 1279.98px) {
  .c-header--alt-color .c-menu__nav-category {
    color: #fff !important;
  }
}

.c-header--alt-color .c-menu__nav-drop-item.is-active .c-menu__nav-category {
  background: #FEC314 !important;
  color: #25516e !important;
}

.c-header--alt-color .c-menu__nav-drop::before {
  background: #25516e;
}

.c-header--alt-color .c-menu__item.menu-item-has-children:hover::after {
  border-bottom: 15px solid #25516e !important;
}

.c-header--alt-color .c-menu__sign svg polygon {
  fill: #fff !important;
  transition: all 250ms ease-in-out;
}

.c-header--alt-color .c-menu__nav-drop-item.is-active .c-menu__sign svg polygon {
  transition: all 250ms ease-in-out;
  fill: #25516e !important;
}

.c-hero {
  position: relative;
}

.c-hero--full {
  min-height: calc(100vh - 66px);
  height: calc(100vh - 66px);
  min-width: 100%;
  width: auto;
  background-size: cover;
  background-position: center center;
}

.c-hero--full .c-hero__small-wrapper {
  padding: 4%;
}

.c-hero--small {
  background: transparent;
  overflow-y: visible;
  margin: 50px 0 0 0;
}

@media (max-width: 767.98px) {
  .c-hero--small {
    margin: 70px 0 0 0;
  }
}

@media (min-width: 768px) {
  .c-hero--small .c-hero__title,
  .c-hero--small .c-hero__subtitle {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 40px;
    line-height: 47px;
  }
}

@media (max-width: 767.98px) {
  .c-hero--small .c-hero__title,
  .c-hero--small .c-hero__subtitle {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 10px;
  }
}

.c-hero--small .c-hero__container {
  max-height: 200px;
}

@media (min-width: 768px) {
  .c-hero--small .c-hero__container {
    max-height: unset;
    background: transparent;
  }
}

.c-hero--small .c-hero__small-wrapper {
  padding: 4%;
}

.c-hero--with-margin {
  margin-bottom: 50px;
}

.c-hero__image {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  height: auto;
}

@media (max-width: 767.98px) {
  .c-hero__image.baner {
    transform: scale(1.75);
  }
}

@media (max-width: 479.98px) {
  .c-hero__image.baner {
    transform: scale(2.5);
  }
}

.c-hero__container {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  color: #fff;
  text-align: center !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .c-hero__container {
    background: transparent;
  }
}

.c-hero__title, .c-hero__subtitle {
  margin: 0;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
}

@media (min-width: 768px) {
  .c-hero__title, .c-hero__subtitle {
    max-width: max-content;
    margin: auto;
    padding: 10px 20px;
  }
}

@media (min-width: 768px) {
  .c-hero__title {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 50px;
    line-height: 58px;
  }
}

@media (min-width: 768px) {
  .c-hero__subtitle {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: 400;
    font-size: 50px;
    line-height: 58px;
  }
}

.c-hero__buttons {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

@media (min-width: 480px) {
  .c-hero__buttons {
    flex-direction: row;
  }
}

.c-hero__download-btn {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 50px auto;
  justify-content: center;
}

@media (max-width: 767.98px) {
  .c-hero__download-btn {
    margin: 30px 0 0;
  }
}

.c-hero__content {
  display: block;
  width: 100%;
  background: #f5f5f5;
}

.c-hero__introduction {
  padding: 50px 10px;
}

@media (min-width: 768px) {
  .c-hero__introduction {
    padding: 85px 0;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
  }
}

.c-hero__introduction p:last-of-type {
  margin-bottom: 0;
}

.c-hero--product .c-hero__title {
  background: none;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 50px;
  line-height: 58px;
  padding: 0;
  margin: 0 0 20px 0;
}

@media (max-width: 767.98px) {
  .c-hero--product .c-hero__title {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }
}

.c-hero--product .c-hero__subtitle {
  background: none;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  padding: 0;
}

@media (max-width: 767.98px) {
  .c-hero--product .c-hero__subtitle {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
  }
}

.c-hero--product .product-attribute {
  margin-bottom: 35px;
  line-height: 1.3;
}

.c-hero--product .c-hero__image {
  position: static;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: auto;
}

@media (min-width: 768px) {
  .c-hero--product .c-hero__image {
    position: absolute;
    left: 0;
    height: 100%;
    max-height: 100%;
    width: auto;
    max-width: unset;
  }
}

@media (max-width: 767.98px) {
  .c-hero--product .c-wing:nth-of-type(1) {
    order: 2;
  }
  .c-hero--product .c-wing:nth-of-type(1) {
    order: 1;
  }
  .c-hero--product .c-wing::before {
    content: none;
  }
}

.c-hero__content-wrapper {
  padding: 35px 20px;
}

@media (min-width: 768px) {
  .c-hero__content-wrapper {
    padding: calc(35px * 2) 0;
    margin-right: calc(35px * 2);
  }
}

.c-hero__short-desc {
  margin: 35px 0;
}

.c-hero__production-icons {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .c-hero__production-icons {
    margin-bottom: 50px;
    transform: scale(1.8);
  }
}

@media (min-width: 768px) {
  body.home .c-hero__small-wrapper {
    margin-top: 250px;
  }
}

.c-image-attrs {
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

@media (max-width: 1279.98px) {
  .c-image-attrs {
    background: none !important;
  }
}

.c-image-attrs__header {
  text-align: center;
  margin-bottom: 50px;
}

.c-image-attrs__subheader {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  color: #000;
  margin-bottom: 50px;
}

.c-image-attrs__image {
  position: static;
  display: block;
  margin: auto;
}

@media (min-width: 768px) {
  .c-image-attrs__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.c-image-attrs__wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: auto;
}

@media (min-width: 768px) {
  .c-image-attrs__wrapper {
    height: auto;
    align-items: baseline;
    padding: 0 5%;
  }
}

@media (min-width: 1024px) {
  .c-image-attrs__wrapper {
    padding: 0 10%;
    gap: 0;
    justify-content: space-around;
  }
}

@media (min-width: 1280px) {
  .c-image-attrs__wrapper {
    justify-content: space-between;
    padding: 0 34% 0 0%;
  }
}

.c-image-attrs__item {
  z-index: 5;
  display: block;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .c-image-attrs__item {
    margin-bottom: 50px;
    width: 30%;
  }
}

@media (min-width: 1024px) {
  .c-image-attrs__item {
    width: 45%;
  }
}

@media (min-width: 1280px) {
  .c-image-attrs__item {
    width: 370px;
    margin-bottom: 50px;
  }
}

.c-image-attrs__item:last-of-type {
  margin-bottom: 0;
}

.c-image-attrs__top {
  margin-bottom: 20px;
}

.c-image-attrs__name {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #25516e;
  margin-left: 10px;
}

@media (min-width: 768px) {
  .c-image-attrs__text {
    max-width: 370px;
  }
}

.c-interactive-office__content {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  color: #000;
}

.c-interactive-office__content h2 {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #000;
  margin-bottom: 35px;
}

.c-interactive-office__image {
  display: none;
  margin-bottom: 50px;
}

.c-interactive-office__image.is-active {
  display: block;
}

.c-interactive-office__slider {
  position: relative;
  border: 3px solid #f5f5f5;
}

@media (min-width: 1024px) {
  .c-interactive-office__slider {
    width: 25%;
  }
}

.c-interactive-office__card {
  display: none;
}

.c-interactive-office__card.is-active {
  display: block;
}

.c-interactive-office__title {
  display: block;
  background: #FEC314;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #333;
  padding: 20px 0;
}

.c-interactive-office__thumbnail {
  margin: 10px auto;
  text-align: center;
}

.c-interactive-office__icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media (max-width: 1279.98px) {
  .c-interactive-office__icons {
    margin-bottom: 0;
  }
  .c-interactive-office__icons .c-menu__item {
    border-bottom: none !important;
  }
}

.c-interactive-office__text {
  padding: 0 20px;
  margin: 20px auto;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  line-height: 26px;
}

.c-interactive-office__button {
  margin: 35px auto;
  display: block;
  text-align: center;
}

.c-interactive-office__footer {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #000;
  display: flex;
  justify-content: center;
  margin: 20px;
}

.c-interactive-office__footer svg {
  cursor: pointer;
  margin: 0 10px;
  width: 20px;
  height: auto;
}

.c-interactive-office__count {
  font-weight: bold;
  margin: 0 15px;
}

.c-interactive-office__arrow {
  cursor: pointer;
}

.hs-arrow {
  display: none !important;
}

.squares-element {
  text-align: center !important;
  display: flex;
  justify-content: center !important;
}

.imp-tooltip {
  border: 2px solid #e1e2e3 !important;
  transition: none !important;
}

.imp-tooltip h3 {
  padding: 0 20px !important;
  position: relative;
}

.ks-arrow {
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
  background-size: contain;
  background-position: center;
}

.imp-fullscreen-tooltip.imp-tooltip-visible .squares-element.sq-col-lg-4 {
  max-width: 33% !important;
}

.imp-fullscreen-tooltip.imp-tooltip-visible {
  display: flex !important;
  flex-direction: column !important;
  align-content: space-between !important;
  justify-content: space-between !important;
}

@media (min-width: 1024px) {
  .imp-initialized {
    max-width: 85%;
    transform: translateX(-80px);
  }
}

@media (min-width: 480px) {
  body .imp-tooltip.imp-tooltip-visible {
    transform: translateX(0%) !important;
  }
}

@media (min-width: 1280px) {
  body .imp-tooltip.imp-tooltip-visible {
    transform: translateX(50%) !important;
  }
}

.imap {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .imap {
    margin-top: 80px;
  }
}

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button,
.imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: #25516e;
  cursor: pointer;
  z-index: 99;
}

@media (min-width: 768px) {
  .imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button,
  .imp-tooltip-close-button {
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    font-size: 18px;
    line-height: 1;
  }
}

body .imap .icon-plus-active {
  background: url(/wp-content/themes/glass-system-chisel/dist/assets/images/plus-active.png) !important;
}

.squares-container {
  max-width: 1140px;
  text-align: center;
  justify-content: center;
}

.lightcase-icon-close::before {
  display: none !important;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: auto;
  height: auto;
  background-color: #FEC314;
  text-align: center;
  opacity: 1;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  -o-transition: opacity .2s;
  transform: none;
  line-height: 1;
  padding: 10px 22px;
  color: #25516E;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.lb-image {
  width: auto !important;
  height: auto !important;
  max-height: 90vh !important;
  max-width: 1170px !important;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 767.98px) {
  .lb-image {
    max-width: 85vw;
  }
}

.lb-dataContainer {
  margin-bottom: 30px;
  border-radius: 1px;
}

.lightboxOverlay {
  background-color: #25516E;
  opacity: .9;
}

@media (min-width: 768px) {
  .c-map-contact {
    margin-bottom: -150px;
    margin-top: -230px;
    z-index: 10;
    position: relative;
  }
}

@media (min-width: 1024px) {
  .c-map-contact__header {
    margin: 120px 0 50px !important;
  }
}

.c-map-contact__canvas {
  height: 700px;
}

.c-menu {
  margin: 0;
}

.c-menu--horizontal {
  display: flex;
  flex-direction: row;
}

@media (min-width: 1280px) {
  .c-menu--small .c-menu__link {
    padding: calc(10px/2);
  }
}

.c-menu--primary {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #626262;
}

@media (min-width: 768px) {
  .c-menu--primary {
    text-align: center;
  }
}

@media (min-width: 1280px) {
  .c-menu--primary {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: right;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #626262;
    flex-direction: row;
    text-align: right;
  }
  .c-menu--primary li:last-child a {
    padding-right: 0;
  }
}

@media (max-width: 767.98px) {
  .c-menu--bottom {
    flex-direction: column !important;
    width: 100%;
  }
}

.c-menu--bottom .c-menu__link {
  transition: all 250ms ease-in-out;
  color: #fff;
  font-weight: 900;
}

@media (max-width: 767.98px) {
  .c-menu--bottom .c-menu__link {
    width: 100%;
    padding-left: 0;
    font-size: 16px;
  }
}

.c-menu--bottom .c-menu__link:hover {
  transition: all 250ms ease-in-out;
  color: #FEC314;
}

@media (max-width: 1279.98px) {
  .c-menu--bottom .c-menu__item {
    border: none;
  }
}

.c-menu--icons {
  margin: auto;
  margin-bottom: 20px;
}

@media (min-width: 1280px) {
  .c-menu--icons {
    justify-content: center;
    margin-bottom: auto;
  }
}

@media (max-width: 1279.98px) {
  .c-menu--icons {
    margin-bottom: 0 !important;
    margin-left: 15px !important;
    margin-top: -10px;
  }
  .c-menu--icons .c-menu__item {
    border-bottom: none !important;
    margin-top: -5px;
  }
}

.c-menu__icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 80px;
}

.c-menu__icons img {
  width: 20px;
  margin: 3px 0 3px 6px;
}

.c-menu__item {
  display: block;
}

@media (min-width: 1280px) {
  .c-menu__item {
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
  }
  .c-menu__item:first-of-type {
    margin-left: 0;
  }
}

@media (max-width: 1279.98px) {
  .c-menu__item {
    color: #fff;
    border-bottom: 1px solid #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
  }
}

@media (min-width: 1280px) {
  .c-menu__item.menu-item-has-children:hover::after {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #FEC314;
    content: '';
    position: absolute;
    z-index: 100;
    top: 82px;
    margin: 0 24px;
  }
}

.c-menu__link {
  display: block;
  transition: all 250ms ease-in-out;
  text-decoration: none;
  padding: 10px;
}

.c-menu__link:hover {
  color: #25516e;
}

@media (max-width: 1279.98px) {
  .c-menu__link {
    width: max-content;
  }
  .c-menu__link:hover {
    color: #FEC314;
  }
}

.c-menu__link img {
  max-width: 16px;
}

.c-menu__products {
  width: 100%;
  align-content: flex-start;
}

.c-menu__nav-drop {
  display: none;
  position: absolute;
  z-index: 999;
}

@media (min-width: 1280px) {
  .c-menu__nav-drop {
    display: none;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, #e0e1e2 50%, #e6e7e8 50%);
    margin: 0;
    top: 95px;
    color: #000;
    list-style: none;
    text-align: left;
  }
}

@media (max-width: 1279.98px) {
  .c-menu__nav-drop {
    display: flex;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    transform: translateX(200%);
    background: #e6e7e8;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    min-height: 100%;
  }
}

.c-menu__nav-drop a {
  color: #000;
  text-decoration: none;
}

@media (min-width: 1280px) {
  .c-menu__nav-drop::before {
    display: block;
    content: '';
    height: 66px;
    width: 200vw;
    background: #FEC314;
    position: absolute;
    z-index: -1;
  }
}

.c-menu__nav-drop--second {
  animation: fadeIn 250ms;
  list-style: none;
  padding: 0 16px;
  margin: 20px 0;
}

@media (max-width: 1279.98px) {
  .c-menu__nav-drop--second {
    display: none;
    padding: 20px 40px;
    margin: 0;
  }
}

.c-menu__back {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #25516e;
  padding-left: 20px;
}

.c-menu__back .c-menu__link-arrow {
  justify-content: flex-start !important;
  align-items: center;
  transform: rotate(180deg);
}

@media (min-width: 1280px) {
  .c-menu__item:hover .c-menu__nav-drop, .c-menu__nav-drop:hover {
    transition: all 250ms ease-in-out;
    display: flex;
  }
}

@media (min-width: 1280px) {
  .c-menu__nav-drop-item {
    padding: 0 !important;
    background: #e0e1e2;
    margin-bottom: 0;
  }
  .c-menu__nav-drop-item:nth-of-type(even) {
    background: #e6e7e8;
  }
}

@media (max-width: 1279.98px) {
  .c-menu__nav-drop-item {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
}

.c-menu__nav-drop-item--second a {
  transition: all 250ms ease-in-out;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-transform: none !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.c-menu__nav-drop-item--second a svg {
  transition: all 250ms ease-in-out;
  transition-duration: 0.05s;
}

.c-menu__nav-drop-item--second a:hover {
  color: #25516e;
}

.c-menu__nav-drop-item--second a:hover svg {
  margin-left: 5px;
}

.c-menu__nav-drop-item.is-active {
  transition: all 250ms ease-in-out;
}

.c-menu__nav-drop-item.is-active .c-menu__nav-category {
  background: #FEC314;
}

.c-menu__nav-drop-item.is-active .c-menu__nav-drop--second {
  transition: all 250ms ease-in-out;
  display: block;
}

.c-menu__nav-drop-item.is-active .c-menu__sign {
  transition: all 250ms ease-in-out;
  transform: rotate(270deg);
}

.c-menu__nav-category {
  transition: all 250ms ease-in-out;
  display: block;
  padding: 20px 0 20px 16px !important;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

@media (min-width: 1280px) {
  .c-menu__nav-category {
    background: #FEC314;
  }
}

@media (max-width: 1279.98px) {
  .c-menu__nav-category {
    background: transparent;
    text-align: center;
    color: #25516e !important;
    border-top: 1px solid #8d8d8d;
    border-bottom: 1px solid #8d8d8d;
    position: relative;
    padding: 20px !important;
  }
}

.c-menu__sign {
  transition: all 250ms ease-in-out;
  transform: rotate(90deg);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  width: 15px;
  height: 22px;
  margin: auto;
}

.c-menu__sign svg {
  width: 14px;
  height: 14px;
}

.c-menu__sign svg polygon {
  fill: #25516e;
}

@media (max-width: 1279.98px) {
  .c-header__content .c-menu__link {
    color: #fff;
    padding: 15px;
    margin: 0;
    font-size: 15px;
    display: inline-flex;
    justify-content: space-between;
  }
}

@media (max-width: 1279.98px) {
  .c-header__content .c-menu__link-arrow {
    position: static;
    right: 20px;
    top: 0;
    bottom: 0;
    width: 50%;
    text-align: right;
    pointer-events: none;
    z-index: 10;
    display: inline-flex;
    padding: 20px;
    justify-content: flex-end;
    cursor: pointer;
  }
  .c-header__content .c-menu__link-arrow svg {
    width: 20px;
    height: 10px;
  }
  .c-header__content .c-menu__link-arrow svg g {
    fill: #fff;
  }
}

@media (min-width: 1280px) {
  .c-header__content .c-menu__link-arrow {
    display: none;
  }
}

@media (min-width: 1280px) {
  .c-header__content .current-menu-item > a {
    transition: all 250ms ease-in-out !important;
    color: #25516e !important;
  }
}

@media (max-width: 1279.98px) {
  .c-header__content .current-menu-item {
    transition: all 250ms ease-in-out !important;
    background: #FEC314 !important;
  }
  .c-header__content .current-menu-item > a {
    transition: all 250ms ease-in-out !important;
    color: #25516e !important;
  }
}

.js-wpml-ls-item-toggle.wpml-ls-item-toggle {
  background: transparent !important;
  border: none;
  width: max-content;
  padding: 5px 25px !important;
  font-size: 12px !important;
  max-width: 65px;
}

@media (max-width: 1279.98px) {
  .js-wpml-ls-item-toggle.wpml-ls-item-toggle {
    margin-top: -10px;
  }
}

@media (min-width: 1280px) {
  .wpml-ls-native {
    font-size: 15px;
    font-weight: 500;
    color: #626262;
  }
}

.wpml-ls-link {
  background: #e0e1e2 !important;
  width: max-content;
  border: none !important;
  font-size: 12px;
  font-weight: normal;
  padding: 5px 25px !important;
  max-width: 65px;
}

.wpml-ls-legacy-dropdown {
  width: max-content;
  max-width: 100%;
  border: none !important;
  margin: 0 5px;
}

.wpml-ls-legacy-dropdown a.wpml-ls-item-toggle::after {
  border: none !important;
  background: url("/wp-content/themes/glass-system-chisel/src/assets/images/arrow-btn.svg") !important;
  background-repeat: no-repeat !important;
  width: 10px;
  height: 10px;
}

@media (min-width: 1280px) {
  .wpml-ls-legacy-dropdown a.wpml-ls-item-toggle::after {
    right: 5px;
  }
}

.c-header__small-bar .wpml-ls-legacy-dropdown a.wpml-ls-item-toggle::after {
  background: url("/wp-content/themes/glass-system-chisel/src/assets/images/arrows/chevron.svg") !important;
  background-repeat: no-repeat !important;
  width: 10px;
  height: 6px;
  background-size: contain !important;
  right: 5px;
}

.wpml-ls-legacy-dropdown .wpml-ls-item {
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: max-content;
}

.wpml-ls-legacy-dropdown {
  width: 65px !important;
  max-width: max-content;
}

.wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
  border-top: none !important;
  max-width: 65px;
  width: 65px;
}

@media (min-width: 1280px) {
  .menu-item-has-children:hover::after {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #FEC314;
    content: '';
    position: absolute;
    z-index: 100;
    top: 82px;
    margin: 0 24px;
  }
}

.wpml-ls-sub-menu::before {
  content: '';
  display: block;
  position: static;
  background: #FEC314;
  height: 10px;
  width: 100%;
}

.wpml-ls-sub-menu::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  content: '';
  display: block;
  height: 10px;
  width: 10px;
  background: transparent;
  margin: auto;
  margin-top: -10px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #FEC314 !important;
}

.wpml-ls-legacy-dropdown {
  transform: translateX(-10px);
  z-index: 1000 !important;
  position: relative;
}

.wpml-ls-sub-menu {
  transform: translateY(10px);
}

.wpml-ls-sub-menu .wpml-ls-slot-shortcode_actions:nth-of-type(1) a {
  padding-top: 10px !important;
}

.wpml-ls-sub-menu .wpml-ls-slot-shortcode_actions:last-of-type a {
  padding-bottom: 10px !important;
}

.c-network__map {
  text-align: right;
}

.c-network__map svg {
  width: 85%;
  height: auto;
}

.c-network__map svg .is-current {
  transition: all 250ms ease-in-out;
  fill: #25516e !important;
  opacity: 1 !important;
}

.c-network__map svg .is-active {
  fill: #FEC314;
  cursor: pointer;
}

@media (min-width: 768px) {
  .c-network__map svg .is-active {
    opacity: 0.8;
  }
  .c-network__map svg .is-active:hover {
    opacity: 1;
  }
}

.c-network__filter-box {
  background: #1a587c;
  padding: 35px 50px;
}

.c-network__title {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  color: #fff;
  margin-bottom: 10px;
}

.c-network__subtitle {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
}

.c-network__box {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
}

.c-network__label {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 17px;
}

.c-network__select {
  width: 100%;
  background: transparent;
  border: 1px solid #fff;
  padding: 15px 10px;
  color: #fff;
}

.c-network-item__title {
  background: #e0e1e2;
  padding: 20px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  color: #25516e;
}

.c-network-item__data {
  min-height: 130px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.c-network-item__data-container {
  padding: 20px 10px;
  border: 1px solid #e0e1e2;
}

.c-network-item__btn {
  padding: 10px 20px;
}

.c-newsletter {
  background-color: #f5f5f5;
}

.c-newsletter__image {
  padding-top: 55px;
  height: 370px;
  color: white;
  background: url("../assets/images/newsletter.png");
  margin-bottom: 30px;
  width: 100%;
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

@media (min-width: 768px) {
  .c-newsletter__image {
    margin-bottom: 0;
    margin-right: 30px;
  }
}

@media (min-width: 1280px) {
  .c-newsletter__image {
    width: 470px !important;
    margin-right: 128px;
  }
}

.c-newsletter__heading {
  position: absolute;
  top: 35%;
  right: unset;
  bottom: 0;
  left: 20%;
  z-index: 10;
  margin: auto;
  max-width: max-content;
  width: 100%;
}

@media (min-width: 768px) {
  .c-newsletter__heading {
    width: 50%;
  }
}

.c-newsletter__header {
  margin-bottom: 15px;
}

.c-newsletter__subtitle {
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .c-newsletter--higher {
    margin-top: -150px;
  }
}

@media (max-width: 767.98px) {
  .c-newsletter__form {
    padding: 0 20px !important;
  }
}

.c-newsletter__form .wpcf7-response-output {
  transform: translateY(-10px);
}

@media (max-width: 767.98px) {
  .c-newsletter__form .wpcf7-response-output {
    transform: translateY(-30px);
  }
}

.c-partners {
  filter: grayscale(1);
}

.c-partners .slick-slide a {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.c-partners .slick-slide a img {
  max-width: 100%;
  height: auto;
  width: auto;
}

.c-partners .slick-track {
  display: flex;
  align-items: center;
}

.c-partners__carousel {
  position: relative;
}

.c-partners__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;
}

.c-partners__arrow svg {
  width: 44px;
  height: 20px;
}

.c-partners__arrow--right {
  right: -5px;
}

@media (min-width: 768px) {
  .c-partners__arrow--right {
    right: -45px;
  }
}

.c-partners__arrow--left {
  left: -5px;
}

@media (min-width: 768px) {
  .c-partners__arrow--left {
    left: -45px;
  }
}

.c-photo-file__container {
  width: 100%;
  max-width: 770px;
}

.c-photo-file__header {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 35px;
  text-align: center;
  color: white !important;
}

.c-photo-file__btn {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin: 0;
  padding: 18px 20px;
}

.c-photo-file__btn .c-btn__icon {
  margin-left: 7px;
}

.c-photo-file__btn .c-btn__icon svg {
  height: 10px;
  width: 10px;
}

.c-photo-file__image {
  min-height: 279px;
  height: 279px;
  width: 100%;
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.c-photo-file__right {
  height: 100%;
  justify-content: space-evenly;
}

@media (min-width: 768px) {
  .c-picture-grid__container {
    width: 200%;
  }
}

.c-picture-grid__box {
  min-height: 265px;
  padding: 10px;
}

.c-picture-grid__image {
  height: 245px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  width: 100%;
}

.c-picture-grid__title {
  display: block;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  color: #25516e;
  margin-bottom: 35px;
  width: 100%;
}

.c-picture-grid__text {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.c-portrait__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  align-items: center;
}

.c-portrait__image, .c-portrait__content {
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .c-portrait__image, .c-portrait__content {
    width: 45%;
    height: auto;
  }
}

.c-portrait__image {
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: center;
}

.c-portrait__image img {
  transform: translateY(-20%);
  max-width: 65%;
  height: 100%;
  width: 100%;
}

@media (max-width: 767.98px) {
  .c-portrait__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.c-portrait__header {
  text-align: center;
  margin-bottom: calc(85px * 1.75);
}

@media (max-width: 767.98px) {
  .c-portrait__header {
    margin-bottom: calc(85px * 1);
  }
}

.c-portrait__content {
  text-align: center;
}

.c-portrait__name {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  display: block;
  margin-bottom: 0;
}

.c-portrait__position {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  display: block;
}

.c-portrait__text {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  display: block;
  margin: 20px 0;
}

.c-portrait__btn {
  display: block;
  margin: 0;
}

@media (min-width: 768px) {
  .c-portrait__btn {
    margin-top: 35px;
  }
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}

.c-post:after {
  clear: both !important;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.product-attribute {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .product-attribute {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .product-attribute:first-of-type {
    margin-top: 35px;
  }
}

.product-attribute:last-of-type {
  margin-bottom: 35px;
}

.product-attribute__icon {
  width: 57px;
  height: 57px;
  margin-right: 35px;
}

.product-attribute__value {
  display: block;
  width: calc(100% - 100px);
}

.product-description__content p:last-of-type {
  margin-bottom: 0;
}

.product-details {
  display: flex;
  flex-direction: column;
}

.product-details__expose {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

@media (min-width: 1280px) {
  .product-details__expose {
    flex-direction: row;
  }
}

.product-details__expose-tab {
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

@media (max-width: 767.98px) {
  .product-details__expose-tab {
    margin-bottom: 20px;
  }
}

.product-details__expose-tab:hover {
  transform: scale(1.02);
}

.product-details__expose-tab.is-active {
  transition: all 250ms ease-in-out;
}

.product-details__expose-tab.is-active .product-details__expose-name {
  background: #FEC314;
}

.product-details__expose-img {
  transition: all 250ms ease-in-out;
  pointer-events: none;
  min-height: 265px;
  height: 265px;
  width: 100%;
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.product-details__expose-name {
  transition: all 250ms ease-in-out;
  padding: 20px;
  background: #fff;
  min-height: 105px;
  height: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  pointer-events: none;
}

.product-details__expose-title {
  transition: all 250ms ease-in-out;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  color: #000;
  margin-bottom: 5px;
  line-height: 1;
  pointer-events: none;
}

.product-details__expose-subtitle {
  transition: all 250ms ease-in-out;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  color: #000;
  line-height: 1;
  pointer-events: none;
}

.product-details__expose-icons {
  transition: all 250ms ease-in-out;
  pointer-events: none;
  height: 100%;
}

.product-details__expose-icons svg, .product-details__expose-icons img {
  max-width: 70px;
  width: 70px;
  height: auto;
  margin: 10px auto;
}

.product-details__content {
  margin-bottom: 50px;
}

.product-details__content.is-unactive {
  display: none !important;
}

.product-details__tab {
  flex-direction: column;
  display: none;
  transition: all 250ms ease-in-out;
}

@media (min-width: 768px) {
  .product-details__tab {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}

.product-details__tab.active {
  display: flex;
}

.product-details__tech-img {
  margin: 30px auto;
  transition: all 250ms ease-in-out;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

@media (min-width: 1280px) {
  .product-details__tech-img {
    margin: 0;
    max-width: 650px;
    position: absolute;
    width: auto;
    height: auto;
    max-height: 90%;
    right: -160px;
  }
}

.product-details__table {
  transition: all 250ms ease-in-out;
  display: table;
  margin: auto;
  border-collapse: collapse;
  width: auto;
  min-width: 80vw;
}

@media (min-width: 1024px) {
  .product-details__table {
    min-width: 800px;
  }
}

.product-details__table tr:nth-child(odd) {
  background-color: #f6f6f6;
}

.product-details__table td,
.product-details__table th {
  padding: 20px;
}

.product-details__table td:first-of-type,
.product-details__table th:first-of-type {
  font-weight: bold;
}

.product-details__table th {
  background-color: #25516e;
  color: #fff;
}

.product-details__table,
.product-details__table th,
.product-details__table td {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .product-details__tech-img + .product-details__table {
    max-width: 57%;
  }
}

.product-details__material {
  transition: all 250ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.product-details__material img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.product-details__file {
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #e0e1e2;
  flex-grow: 1 !important;
  max-width: 100% !important;
}

.product-details__file-image {
  max-width: 57px;
  max-height: 57px;
}

.product-details__file-title {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-left: 20px;
}

.product-details__file-link {
  text-decoration: none;
  color: #000;
  transition: all 250ms ease-in-out;
}

.product-details__file-link:hover {
  color: #25516e;
}

.product-details__file.o-flex--grow {
  flex-grow: 1 !important;
  max-width: 100% !important;
}

@media (max-width: 479.98px) {
  .product-details__file--bigger {
    flex-direction: column;
    text-align: center;
  }
}

.product-details__file--bigger .product-details__file-image {
  max-width: 267px;
  max-height: 300px;
}

@media (max-width: 479.98px) {
  .product-details__file--bigger .product-details__file-title {
    display: block;
    text-align: center;
  }
}

.product-details--flex {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.product-details--files {
  flex-direction: row;
  width: 100%;
}

.product-details--bottom.active {
  animation: fadeIn 250ms;
}

.product-details--wrapped {
  flex-wrap: wrap;
}

.product-details--wrapped .product-details__material {
  margin-bottom: 35px;
  width: 50%;
}

@media (min-width: 480px) {
  .product-details--wrapped .product-details__material {
    width: 30%;
  }
}

@media (min-width: 768px) {
  .product-details--wrapped .product-details__material {
    width: auto;
  }
}

.gallery__header {
  margin-bottom: 50px;
}

.gallery__photos {
  display: flex;
  flex-direction: row;
}

@media (min-width: 768px) {
  .gallery__photos {
    justify-content: space-between;
  }
}

@media (max-width: 767.98px) {
  .gallery__photos {
    flex-grow: 1;
    flex-wrap: wrap;
  }
}

.gallery__small-img {
  margin-bottom: 20px;
  max-width: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .gallery__small-img {
    max-width: 370px;
  }
}

.gallery__btn {
  display: block;
  margin: 20px auto;
}

@media (max-width: 767.98px) {
  .gallery .c-production-gallery__item {
    flex-grow: 1;
    max-width: 100%;
    width: 48%;
    margin: 1px;
  }
}

.product-nav {
  transition: all 250ms ease-in-out;
  list-style: none;
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  padding: 0;
  width: 100%;
  margin: 0;
  margin-top: 35px;
}

@media (min-width: 768px) {
  .product-nav {
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 50px;
  }
}

.product-nav li {
  display: block;
  background: #e0e1e2;
  color: #000;
  transition: all 250ms ease-in-out;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  padding: 15px 20px;
  margin: 0;
  margin-right: 15px;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .product-nav li {
    margin-bottom: 10px;
    margin-right: 0;
  }
}

.product-nav li.active {
  color: #fff;
  background-color: #25516e;
}

.c-product-video__header {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #000;
  padding: calc(20px * 1.5);
  padding-left: 0;
  width: max-content;
  background-color: #FEC314;
}

@media (max-width: 767.98px) {
  .c-product-video__header {
    width: 100%;
    padding: 15px 0;
  }
}

.c-product-video__header::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100vw;
  transform: translateX(-100%);
  background-color: inherit;
}

.c-product-video__title {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #000;
  margin-bottom: 10px;
  margin-top: 35px;
}

.c-product-video__subtitle {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #000;
}

.c-product-video__video {
  width: 100%;
  margin: 20px auto 50px;
  display: flex;
  justify-content: center;
  margin-top: -20px;
}

@media (min-width: 1024px) {
  .c-product-video__video {
    margin: 0;
    width: auto;
    position: absolute;
    bottom: 85px;
    right: 0;
  }
}

.c-production-gallery {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap: 2px;
}

@media (min-width: 768px) {
  .c-production-gallery {
    gap: 30px;
  }
}

.c-production-gallery__item {
  max-width: 48%;
  width: 100%;
  height: 150px;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

@media (min-width: 768px) {
  .c-production-gallery__item {
    width: 370px;
    height: 265px;
  }
}

.c-products-filter {
  background-color: #f6f6f6;
  padding: 50px 0;
  position: relative;
}

@media (max-width: 767.98px) {
  .c-products-filter {
    padding: 0;
  }
}

.c-products-filter__checks {
  margin-top: 17px;
}

@media (min-width: 768px) {
  .c-products-filter__checks {
    margin-top: 10px;
    transform: translateY(9px);
    max-width: 96%;
    margin: 10px auto 0;
  }
}

@media (max-width: 767.98px) {
  .c-products-filter__response {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 90%;
    margin: auto;
  }
}

@media (max-width: 767.98px) {
  .c-products-filter__header {
    margin: 0 auto 35px auto;
    padding-top: 35px;
  }
}

.c-products-filter__category-item {
  text-decoration: none;
  cursor: pointer;
}

.c-products-filter__category-item span {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  padding: 56px 15px;
  min-height: 160px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767.98px) {
  .c-products-filter__category-item span {
    font-size: 12px;
    line-height: 16px;
    padding: 15px 15px;
    min-height: 80px;
    margin-bottom: 35px;
  }
}

.c-products-filter__category-item.active span {
  background-color: #fec314;
}

.c-products-filter__control {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 15px;
  width: max-content;
  margin: auto;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 20px;
  transform: translateY(8px);
}

@media (max-width: 767.98px) {
  .c-products-filter__control {
    max-width: 80%;
    margin-bottom: 40px;
  }
}

.c-products-filter__control-checkbox {
  margin-right: 25px;
}

.c-products-filter__category-image {
  width: 100%;
  height: 270px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 767.98px) {
  .c-products-filter__category-image {
    height: 140px;
  }
}

.c-products-filter__box {
  display: none;
  min-height: 170px;
  margin-top: 40px;
  background-color: #1a587c;
  padding-bottom: 30px;
  padding-top: 25px;
}

@media (max-width: 767.98px) {
  .c-products-filter__box {
    margin-top: 0;
  }
}

.c-products-filter__box-heading {
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  max-width: 180px;
  display: block;
  font-weight: 700;
}

@media (max-width: 767.98px) {
  .c-products-filter__box-heading {
    margin: auto auto 30px;
    text-align: center;
    max-width: 100%;
  }
}

.c-products-filter__box-slider {
  text-align: center;
}

.c-products-filter__box-slider span {
  font-size: 16px;
  line-height: 19px;
  color: #fff;
}

.c-products-filter__box-slider span svg {
  vertical-align: middle;
  height: 34px;
  width: 34px;
  margin-right: 8px;
}

.c-products-filter__box-slider span svg * {
  fill: #fff;
}

.c-products-filter__box .range-item {
  width: 311px;
  margin: 0;
}

.c-products-filter__box .range-item .noUi-tooltip {
  border: none;
  border-radius: 0;
  padding: 3px 8px;
}

.c-products-filter__box .range-item .noUi-tooltip:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
}

.c-products-filter__box .range-item.noUi-horizontal {
  height: 1px;
}

.c-products-filter__box .range-item .noUi-handle {
  border: none;
  background-color: transparent;
  box-shadow: unset;
  outline: unset;
}

.c-products-filter__box .range-item .noUi-handle:before, .c-products-filter__box .range-item .noUi-handle:after {
  width: 3px;
}

.c-products-filter__box .range-item .noUi-connect,
.c-products-filter__box .range-item .noUi-origin {
  top: -7px;
}

.c-products-filter__box .range-item .noUi-value-large {
  visibility: hidden;
}

.c-products-filter__box .range-item .noUi-marker-horizontal.noUi-marker {
  height: 10px !important;
  background: rgba(255, 255, 255, 0.2);
}

.c-products-filter__box .range-item .noUi-pips-horizontal {
  top: -15px;
}

.c-products-filter__box-checkbox {
  text-align: center;
}

.c-products-filter__box-checkbox span {
  font-size: 16px;
  line-height: 19px;
  color: #fff;
}

.c-products-filter__box-checkbox span svg {
  vertical-align: middle;
  height: 34px;
  width: 34px;
  margin-right: 8px;
}

.c-products-filter__box-checkbox span svg * {
  fill: #fff;
}

.c-products-filter__box-checkbox label {
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 85%;
  display: block;
  padding: 10px;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.c-products-filter__response {
  padding-top: 50px;
  padding-bottom: 50px;
}

.c-products-filter__response h2 {
  display: block;
  text-align: center;
  width: 100%;
}

.c-product-item__image {
  display: block;
  width: 100%;
  height: 200px;
  background-color: #fff;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  text-decoration: none;
}

.c-product-item__title {
  transition: all 250ms ease-in-out;
  color: #000;
  text-decoration: none;
  display: block;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 20px;
}

.c-product-item__icons {
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 55px;
  max-width: 55px;
  height: 100%;
  padding: 15px;
  margin-left: auto;
  margin-right: 0;
}

.c-product-item__icon {
  max-width: 25px;
  max-height: 25px;
  width: auto;
  height: auto;
  margin-bottom: 15px;
}

input.form__acceptance--green + span.form__acceptance--faked::before {
  background-color: transparent !important;
  background: url("../assets/images/not-checked-green.png") !important;
  border: 1px solid transparent !important;
}

input.form__acceptance--green:checked + span.form__acceptance--faked::before {
  background: url("../assets/images/checked-green.png") !important;
}

.noUi-handle-upper {
  display: none;
}

.range-item.noUi-target {
  background: transparent !important;
  border-radius: 0px !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  box-shadow: unset !important;
}

.related__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.c-research__header {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 50px;
  line-height: 58px;
  margin-top: 85px;
  margin-bottom: 85px;
}

@media (max-width: 767.98px) {
  .c-research__header {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    margin-top: 0;
    margin-bottom: 35px;
  }
}

.c-research__content {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

@media (min-width: 768px) {
  .c-research__content {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
  }
}

.c-research__content p {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .c-research__content p {
    margin-bottom: 50px;
  }
}

.c-research__content h2, .c-research__content h3 {
  margin: 20px 0;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
}

@media (min-width: 768px) {
  .c-research__content h2, .c-research__content h3 {
    margin: 50px 0;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }
}

.c-tabs-and-content__wrapper {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .c-tabs-and-content__wrapper {
    flex-direction: row;
  }
}

.c-tabs-and-content__tab {
  transition: all 250ms ease-in-out;
  text-align: center;
  width: 100%;
  padding: 20px;
  cursor: pointer;
  border-top: 1px solid #25516e;
  border-bottom: 1px solid #25516e;
}

@media (min-width: 768px) {
  .c-tabs-and-content__tab {
    width: auto;
    min-width: 33%;
    max-width: 33%;
  }
}

.c-tabs-and-content__tab.is-active {
  position: relative;
  background-color: #FEC314;
}

@media (min-width: 768px) {
  .c-tabs-and-content__tab.is-active {
    background-color: #fff;
  }
  .c-tabs-and-content__tab.is-active::after {
    z-index: 3;
    display: block;
    margin: auto;
    position: absolute;
    right: 0;
    bottom: -10px;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    z-index: 3;
  }
  .c-tabs-and-content__tab.is-active::before {
    z-index: 3;
    display: block;
    margin: auto;
    position: absolute;
    right: 0;
    bottom: -10px;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #25516e;
    transform: translateY(1px);
    z-index: 2;
  }
}

.c-tabs-and-content__icon {
  display: none;
  pointer-events: none;
  height: 85px;
  width: auto;
}

@media (min-width: 768px) {
  .c-tabs-and-content__icon {
    display: inline;
    margin-bottom: 35px;
  }
}

.c-tabs-and-content__title {
  pointer-events: none;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #25516e;
}

@media (min-width: 768px) {
  .c-tabs-and-content__title {
    margin-bottom: 10px;
  }
}

.c-tabs-and-content__subtitle {
  pointer-events: none;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #000;
  margin-bottom: 0;
}

.c-tabs-and-content__content {
  transition: all 250ms ease-in-out;
  pointer-events: none;
  display: none;
  border-bottom: 1px solid #25516e;
  padding: 35px 10px 20px 10px;
}

@media (min-width: 768px) {
  .c-tabs-and-content__content {
    padding: 35px 85px 20px 85px;
  }
}

.c-tabs-and-content__content.is-active {
  display: block;
}

.c-tabs-photo__tab {
  transition: all 250ms ease-in-out;
  display: flex;
  margin: 0 10px;
  padding: 12px 20px !important;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  background-color: #E1E2E3 !important;
  color: #787878 !important;
  justify-content: center;
}

@media (max-width: 767.98px) {
  .c-tabs-photo__tab {
    margin: 5px 10px;
    width: 100%;
  }
}

.c-tabs-photo__tab.is-active {
  transition: all 250ms ease-in-out;
  background-color: #25516E !important;
  color: white !important;
}

.c-tabs-photo__desc {
  max-width: 850px;
  margin-top: -20px !important;
  text-align: center;
}

.c-tabs-photo__img {
  display: flex;
  height: 70vh;
  width: auto;
  width: 100%;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  margin-top: 63px;
}

@media (max-width: 767.98px) {
  .c-tabs-photo__img {
    height: 70vw;
    background-position: bottom center !important;
  }
}

.c-tabs-photo__photo {
  position: relative;
}

.c-tabs-photo__desc, .c-tabs-photo__photo {
  transition: all 250ms ease-in-out;
  display: none;
}

.c-tabs-photo__desc.is-active, .c-tabs-photo__photo.is-active {
  transition: all 250ms ease-in-out;
  display: flex;
}

.c-tabs-photo__click-btn {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #FEC314;
  color: #25516E;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  border: none;
  padding: 12px 18px;
  cursor: pointer;
  width: 210px;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

.c-tabs-photo__click-btn:hover {
  transition: all 250ms ease-in-out;
  color: #25516E;
}

.c-tbg__background {
  background-position-y: center;
  background-position-x: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.c-tbg__wrapper {
  padding: 85px 0;
}

@media (min-width: 768px) {
  .c-tbg__wrapper {
    width: 45%;
  }
}

.c-tbg__header {
  margin-bottom: 35px;
}

.c-tbg__text {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 35px;
}

.c-tbg__btn {
  display: block;
}

@media (min-width: 768px) {
  .c-text-image {
    background: linear-gradient(to right, #e1e2e3 43%, white 43%);
  }
}

.c-text-image__header {
  margin-bottom: 10px;
}

.c-text-image__subheader {
  display: block;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 50px;
}

.c-text-image__content {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 0;
}

.c-text-image__image {
  max-width: 200px;
  max-height: 200px;
}

@media (min-width: 768px) {
  .c-text-image__image {
    float: right;
  }
}

.c-tiles__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.c-tiles__item {
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 60vh;
  margin-bottom: 10px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

@media (min-width: 768px) {
  .c-tiles__item {
    width: 45%;
    margin: 1px;
    height: 49vh;
    margin-bottom: 1px;
  }
}

.c-tiles__overlay {
  position: absolute;
  height: auto;
  width: 100%;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 35px 35px;
}

@media (max-width: 1599.98px) {
  .c-tiles__overlay {
    height: 70%;
    display: flex;
    flex-direction: column;
  }
}

.c-tiles__label {
  display: block;
  background: #FEC314;
  width: max-content;
  margin: auto;
  padding: 0 20px;
  border-radius: 5px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #333;
  margin-bottom: 10px;
}

.c-tiles__title {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  margin-bottom: 10px;
}

.c-tiles__subtitle {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
}

.c-tiles__btn {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border-radius: 2px;
  display: block;
  margin: auto;
}

.video {
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .video {
    margin-bottom: 25px;
  }
}

.video__player {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: auto;
  width: 100%;
  height: auto;
  position: relative;
}

@media (min-width: 768px) {
  .video__player {
    max-width: 570px;
    height: 320px;
  }
}

.video__player iframe {
  animation: fadeIn 250ms;
  animation: fadeIn 250ms;
}

.video__player.done {
  transition: all 250ms ease-in-out;
  animation: fadeIn 250ms;
}

@media (min-width: 768px) {
  .video__header {
    margin-bottom: 90px;
  }
}

@media (min-width: 768px) {
  .video--higher {
    margin-top: -100px;
  }
}

.video__c-btn {
  width: 85px;
  height: 85px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

.video__c-btn .oval {
  transition: all 250ms ease-in-out;
}

.video__c-btn:hover .oval {
  fill: #333;
}

.c-video__container .video__player {
  max-width: 770px;
  height: auto;
}

.c-wing {
  display: block;
  width: 100%;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 2.2rem;
  padding: 0;
  position: relative;
}

@media (min-width: 1024px) {
  .c-wing {
    flex-direction: row;
    font-size: 20px;
  }
}

.c-wing__wrapper {
  display: flex;
  flex-direction: column;
  justify-items: start;
  position: relative;
}

@media (min-width: 1024px) {
  .c-wing__wrapper {
    flex-direction: row;
  }
}

.c-wing--left {
  position: relative;
  background-color: #f6f6f6;
}

@media (min-width: 1024px) {
  .c-wing--left {
    padding: 65px 65px 65px 0;
    width: 48%;
  }
  .c-wing--left::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    background-color: #f6f6f6;
    width: 100vw;
    transform: translateX(-100%);
  }
}

.c-wing--right {
  position: relative;
  background-color: #e0e1e2;
}

@media (min-width: 1024px) {
  .c-wing--right {
    padding: 65px 30px 65px 30px;
    width: 51%;
  }
  .c-wing--right::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-color: #e0e1e2;
    width: 100vw;
    transform: translateX(100%);
  }
}

.c-wing--shorter {
  height: max-content;
}

@media (min-width: 1024px) {
  .c-wing--flat {
    padding-top: 50px !important;
  }
}

.c-wing--transparent {
  background-color: transparent;
}

.c-wing--transparent::after, .c-wing--transparent::before {
  display: none;
}

.c-wing--white {
  background-color: #fff;
}

.c-wing--white::after, .c-wing--white::before {
  background-color: #fff;
}

.c-wing--p-less {
  padding: 0 !important;
}

@media (min-width: 1024px) {
  .c-wing--p-less-up {
    padding: 0 !important;
  }
}

.c-wing--flex {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: start;
  justify-items: start;
}

.c-wing--absolute-content {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .c-wing--wider {
    width: 55%;
  }
}

.c-wing--wider-left::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100vw;
  transform: translateX(-100%);
  background-color: inherit;
}

.c-wing--wider-right::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100vw;
  transform: translateX(100%);
}

@media (min-width: 768px) {
  .c-wing--80 {
    width: 75%;
  }
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-bg--white {
  background-color: #fff !important;
}

.u-bg--gray {
  background-color: #f5f5f5 !important;
}

.u-bg--dark-gray {
  background-color: #e0e1e2 !important;
}

.u-bg--gs {
  background-color: #25516e !important;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  clear: both !important;
}

@media (min-width: 768px) {
  .u-extension--high {
    padding-top: 420px !important;
  }
}

@media (min-width: 768px) {
  .u-extension--low {
    padding-top: 220px !important;
  }
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

@media (min-width: 1280px) {
  .u-hidden\@xlarge {
    display: none;
  }
}

@media (max-width: 1279.98px) {
  .u-hidden\@till-xlarge {
    display: none;
  }
}

.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: #1a587cb0;
  z-index: 123;
}

.spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.cube1, .cube2 {
  background-color: #ffffff;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0) translateY(42px) rotate(-270deg) scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0) translateY(42px) rotate(-270deg) scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

.u-margin--large {
  margin: 85px 0;
}

.u-margin--medium {
  margin: 50px 0;
}

.u-margin--common {
  margin: 35px 0;
}

.u-margin--small {
  margin: 20px 0;
}

@media (max-width: 767.98px) {
  .u-margin--small\@small {
    margin: 20px 0;
  }
}

@media (min-width: 1024px) {
  .u-margin--x-large\@large {
    margin: 0 85px;
  }
}

@media (max-width: 767.98px) {
  .u-margin--x-minimal\@small {
    margin: 0 10px;
  }
}

@media (min-width: 768px) {
  .u-margin--top-large {
    margin-top: 120px;
  }
}

@media (max-width: 767.98px) {
  .u-margin--logo-mobile {
    margin-top: 35px;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .u-pull {
    position: relative;
    z-index: 10;
  }
}

@media (min-width: 768px) {
  .u-pull--strong {
    margin-bottom: calc(420px * -1);
    margin-top: calc(420px * 0.13);
  }
}

@media (min-width: 768px) {
  .u-pull--weak {
    margin-bottom: calc(220px / -1.5);
    margin-top: calc(220px / 1.5);
  }
}

.u-shadow--light {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.u-space--large {
  padding: 85px 0;
}

@media (max-width: 767.98px) {
  .u-space--large {
    padding: 50px 0;
  }
}

.u-space--medium {
  padding: 50px 0;
}

@media (max-width: 767.98px) {
  .u-space--medium {
    padding: 35px 0;
  }
}

.u-space--common {
  padding: 35px 0;
}

.u-space--small {
  padding: 20px 0;
}

@media (max-width: 767.98px) {
  .u-space--small\@small {
    padding: 20px 0;
  }
}

@media (min-width: 1024px) {
  .u-space--x-large {
    padding: 0 85px;
  }
}

@media (max-width: 767.98px) {
  .u-space--x-minimal\@small {
    padding: 0 10px;
  }
}

@media (min-width: 768px) {
  .u-space--bottom-none {
    padding-bottom: 0;
  }
}

@media (min-width: 768px) {
  .u-space--left-large {
    padding-left: 85px;
  }
}

@media (max-width: 767.98px) {
  .u-space--info-mobile {
    padding: 0 35px;
  }
}

.u-text--bold {
  font-weight: 600 !important;
}

.u-text--normal {
  font-weight: 400 !important;
}

.u-text--center {
  text-align: center !important;
}

.u-text--left {
  text-align: left !important;
}

.u-text--right {
  text-align: right !important;
}

.u-flex-1\/1 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/1 {
  margin-left: 100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/1 {
  margin-right: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/1 {
  margin-left: -100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/1 {
  margin-right: -100% !important;
}

.u-flex-1\/2 {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/2 {
  margin-left: 50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/2 {
  margin-right: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/2 {
  margin-left: -50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/2 {
  margin-right: -50% !important;
}

.u-flex-2\/2 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/2 {
  margin-left: 100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/2 {
  margin-right: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/2 {
  margin-left: -100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/2 {
  margin-right: -100% !important;
}

.u-flex-1\/3 {
  flex: 0 0 33.33333% !important;
  max-width: 33.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/3 {
  margin-left: 33.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/3 {
  margin-right: 33.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/3 {
  margin-left: -33.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/3 {
  margin-right: -33.33333% !important;
}

.u-flex-2\/3 {
  flex: 0 0 66.66667% !important;
  max-width: 66.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/3 {
  margin-left: 66.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/3 {
  margin-right: 66.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/3 {
  margin-left: -66.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/3 {
  margin-right: -66.66667% !important;
}

.u-flex-3\/3 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/3 {
  margin-left: 100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/3 {
  margin-right: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/3 {
  margin-left: -100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/3 {
  margin-right: -100% !important;
}

.u-flex-1\/4 {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/4 {
  margin-left: 25% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/4 {
  margin-right: 25% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/4 {
  margin-left: -25% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/4 {
  margin-right: -25% !important;
}

.u-flex-2\/4 {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/4 {
  margin-left: 50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/4 {
  margin-right: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/4 {
  margin-left: -50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/4 {
  margin-right: -50% !important;
}

.u-flex-3\/4 {
  flex: 0 0 75% !important;
  max-width: 75% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/4 {
  margin-left: 75% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/4 {
  margin-right: 75% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/4 {
  margin-left: -75% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/4 {
  margin-right: -75% !important;
}

.u-flex-4\/4 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/4 {
  margin-left: 100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/4 {
  margin-right: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/4 {
  margin-left: -100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/4 {
  margin-right: -100% !important;
}

.u-flex-1\/5 {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/5 {
  margin-left: 20% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/5 {
  margin-right: 20% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/5 {
  margin-left: -20% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/5 {
  margin-right: -20% !important;
}

.u-flex-2\/5 {
  flex: 0 0 40% !important;
  max-width: 40% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/5 {
  margin-left: 40% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/5 {
  margin-right: 40% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/5 {
  margin-left: -40% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/5 {
  margin-right: -40% !important;
}

.u-flex-3\/5 {
  flex: 0 0 60% !important;
  max-width: 60% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/5 {
  margin-left: 60% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/5 {
  margin-right: 60% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/5 {
  margin-left: -60% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/5 {
  margin-right: -60% !important;
}

.u-flex-4\/5 {
  flex: 0 0 80% !important;
  max-width: 80% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/5 {
  margin-left: 80% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/5 {
  margin-right: 80% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/5 {
  margin-left: -80% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/5 {
  margin-right: -80% !important;
}

.u-flex-5\/5 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/5 {
  margin-left: 100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/5 {
  margin-right: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/5 {
  margin-left: -100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/5 {
  margin-right: -100% !important;
}

.u-flex-1\/6 {
  flex: 0 0 16.66667% !important;
  max-width: 16.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/6 {
  margin-left: 16.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/6 {
  margin-right: 16.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/6 {
  margin-left: -16.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/6 {
  margin-right: -16.66667% !important;
}

.u-flex-2\/6 {
  flex: 0 0 33.33333% !important;
  max-width: 33.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/6 {
  margin-left: 33.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/6 {
  margin-right: 33.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/6 {
  margin-left: -33.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/6 {
  margin-right: -33.33333% !important;
}

.u-flex-3\/6 {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/6 {
  margin-left: 50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/6 {
  margin-right: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/6 {
  margin-left: -50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/6 {
  margin-right: -50% !important;
}

.u-flex-4\/6 {
  flex: 0 0 66.66667% !important;
  max-width: 66.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/6 {
  margin-left: 66.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/6 {
  margin-right: 66.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/6 {
  margin-left: -66.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/6 {
  margin-right: -66.66667% !important;
}

.u-flex-5\/6 {
  flex: 0 0 83.33333% !important;
  max-width: 83.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/6 {
  margin-left: 83.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/6 {
  margin-right: 83.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/6 {
  margin-left: -83.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/6 {
  margin-right: -83.33333% !important;
}

.u-flex-6\/6 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/6 {
  margin-left: 100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/6 {
  margin-right: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/6 {
  margin-left: -100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/6 {
  margin-right: -100% !important;
}

.u-flex-1\/7 {
  flex: 0 0 14.28571% !important;
  max-width: 14.28571% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/7 {
  margin-left: 14.28571% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/7 {
  margin-right: 14.28571% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/7 {
  margin-left: -14.28571% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/7 {
  margin-right: -14.28571% !important;
}

.u-flex-2\/7 {
  flex: 0 0 28.57143% !important;
  max-width: 28.57143% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/7 {
  margin-left: 28.57143% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/7 {
  margin-right: 28.57143% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/7 {
  margin-left: -28.57143% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/7 {
  margin-right: -28.57143% !important;
}

.u-flex-3\/7 {
  flex: 0 0 42.85714% !important;
  max-width: 42.85714% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/7 {
  margin-left: 42.85714% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/7 {
  margin-right: 42.85714% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/7 {
  margin-left: -42.85714% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/7 {
  margin-right: -42.85714% !important;
}

.u-flex-4\/7 {
  flex: 0 0 57.14286% !important;
  max-width: 57.14286% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/7 {
  margin-left: 57.14286% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/7 {
  margin-right: 57.14286% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/7 {
  margin-left: -57.14286% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/7 {
  margin-right: -57.14286% !important;
}

.u-flex-5\/7 {
  flex: 0 0 71.42857% !important;
  max-width: 71.42857% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/7 {
  margin-left: 71.42857% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/7 {
  margin-right: 71.42857% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/7 {
  margin-left: -71.42857% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/7 {
  margin-right: -71.42857% !important;
}

.u-flex-6\/7 {
  flex: 0 0 85.71429% !important;
  max-width: 85.71429% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/7 {
  margin-left: 85.71429% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/7 {
  margin-right: 85.71429% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/7 {
  margin-left: -85.71429% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/7 {
  margin-right: -85.71429% !important;
}

.u-flex-7\/7 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/7 {
  margin-left: 100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/7 {
  margin-right: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/7 {
  margin-left: -100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/7 {
  margin-right: -100% !important;
}

.u-flex-1\/8 {
  flex: 0 0 12.5% !important;
  max-width: 12.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/8 {
  margin-left: 12.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/8 {
  margin-right: 12.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/8 {
  margin-left: -12.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/8 {
  margin-right: -12.5% !important;
}

.u-flex-2\/8 {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/8 {
  margin-left: 25% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/8 {
  margin-right: 25% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/8 {
  margin-left: -25% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/8 {
  margin-right: -25% !important;
}

.u-flex-3\/8 {
  flex: 0 0 37.5% !important;
  max-width: 37.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/8 {
  margin-left: 37.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/8 {
  margin-right: 37.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/8 {
  margin-left: -37.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/8 {
  margin-right: -37.5% !important;
}

.u-flex-4\/8 {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/8 {
  margin-left: 50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/8 {
  margin-right: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/8 {
  margin-left: -50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/8 {
  margin-right: -50% !important;
}

.u-flex-5\/8 {
  flex: 0 0 62.5% !important;
  max-width: 62.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/8 {
  margin-left: 62.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/8 {
  margin-right: 62.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/8 {
  margin-left: -62.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/8 {
  margin-right: -62.5% !important;
}

.u-flex-6\/8 {
  flex: 0 0 75% !important;
  max-width: 75% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/8 {
  margin-left: 75% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/8 {
  margin-right: 75% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/8 {
  margin-left: -75% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/8 {
  margin-right: -75% !important;
}

.u-flex-7\/8 {
  flex: 0 0 87.5% !important;
  max-width: 87.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/8 {
  margin-left: 87.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/8 {
  margin-right: 87.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/8 {
  margin-left: -87.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/8 {
  margin-right: -87.5% !important;
}

.u-flex-8\/8 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/8 {
  margin-left: 100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/8 {
  margin-right: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/8 {
  margin-left: -100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/8 {
  margin-right: -100% !important;
}

.u-flex-1\/9 {
  flex: 0 0 11.11111% !important;
  max-width: 11.11111% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/9 {
  margin-left: 11.11111% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/9 {
  margin-right: 11.11111% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/9 {
  margin-left: -11.11111% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/9 {
  margin-right: -11.11111% !important;
}

.u-flex-2\/9 {
  flex: 0 0 22.22222% !important;
  max-width: 22.22222% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/9 {
  margin-left: 22.22222% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/9 {
  margin-right: 22.22222% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/9 {
  margin-left: -22.22222% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/9 {
  margin-right: -22.22222% !important;
}

.u-flex-3\/9 {
  flex: 0 0 33.33333% !important;
  max-width: 33.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/9 {
  margin-left: 33.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/9 {
  margin-right: 33.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/9 {
  margin-left: -33.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/9 {
  margin-right: -33.33333% !important;
}

.u-flex-4\/9 {
  flex: 0 0 44.44444% !important;
  max-width: 44.44444% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/9 {
  margin-left: 44.44444% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/9 {
  margin-right: 44.44444% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/9 {
  margin-left: -44.44444% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/9 {
  margin-right: -44.44444% !important;
}

.u-flex-5\/9 {
  flex: 0 0 55.55556% !important;
  max-width: 55.55556% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/9 {
  margin-left: 55.55556% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/9 {
  margin-right: 55.55556% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/9 {
  margin-left: -55.55556% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/9 {
  margin-right: -55.55556% !important;
}

.u-flex-6\/9 {
  flex: 0 0 66.66667% !important;
  max-width: 66.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/9 {
  margin-left: 66.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/9 {
  margin-right: 66.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/9 {
  margin-left: -66.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/9 {
  margin-right: -66.66667% !important;
}

.u-flex-7\/9 {
  flex: 0 0 77.77778% !important;
  max-width: 77.77778% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/9 {
  margin-left: 77.77778% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/9 {
  margin-right: 77.77778% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/9 {
  margin-left: -77.77778% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/9 {
  margin-right: -77.77778% !important;
}

.u-flex-8\/9 {
  flex: 0 0 88.88889% !important;
  max-width: 88.88889% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/9 {
  margin-left: 88.88889% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/9 {
  margin-right: 88.88889% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/9 {
  margin-left: -88.88889% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/9 {
  margin-right: -88.88889% !important;
}

.u-flex-9\/9 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/9 {
  margin-left: 100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/9 {
  margin-right: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/9 {
  margin-left: -100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/9 {
  margin-right: -100% !important;
}

.u-flex-1\/10 {
  flex: 0 0 10% !important;
  max-width: 10% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/10 {
  margin-left: 10% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/10 {
  margin-right: 10% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/10 {
  margin-left: -10% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/10 {
  margin-right: -10% !important;
}

.u-flex-2\/10 {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/10 {
  margin-left: 20% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/10 {
  margin-right: 20% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/10 {
  margin-left: -20% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/10 {
  margin-right: -20% !important;
}

.u-flex-3\/10 {
  flex: 0 0 30% !important;
  max-width: 30% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/10 {
  margin-left: 30% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/10 {
  margin-right: 30% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/10 {
  margin-left: -30% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/10 {
  margin-right: -30% !important;
}

.u-flex-4\/10 {
  flex: 0 0 40% !important;
  max-width: 40% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/10 {
  margin-left: 40% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/10 {
  margin-right: 40% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/10 {
  margin-left: -40% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/10 {
  margin-right: -40% !important;
}

.u-flex-5\/10 {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/10 {
  margin-left: 50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/10 {
  margin-right: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/10 {
  margin-left: -50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/10 {
  margin-right: -50% !important;
}

.u-flex-6\/10 {
  flex: 0 0 60% !important;
  max-width: 60% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/10 {
  margin-left: 60% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/10 {
  margin-right: 60% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/10 {
  margin-left: -60% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/10 {
  margin-right: -60% !important;
}

.u-flex-7\/10 {
  flex: 0 0 70% !important;
  max-width: 70% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/10 {
  margin-left: 70% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/10 {
  margin-right: 70% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/10 {
  margin-left: -70% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/10 {
  margin-right: -70% !important;
}

.u-flex-8\/10 {
  flex: 0 0 80% !important;
  max-width: 80% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/10 {
  margin-left: 80% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/10 {
  margin-right: 80% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/10 {
  margin-left: -80% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/10 {
  margin-right: -80% !important;
}

.u-flex-9\/10 {
  flex: 0 0 90% !important;
  max-width: 90% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/10 {
  margin-left: 90% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/10 {
  margin-right: 90% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/10 {
  margin-left: -90% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/10 {
  margin-right: -90% !important;
}

.u-flex-10\/10 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/10 {
  margin-left: 100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/10 {
  margin-right: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/10 {
  margin-left: -100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/10 {
  margin-right: -100% !important;
}

.u-flex-1\/11 {
  flex: 0 0 9.09091% !important;
  max-width: 9.09091% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/11 {
  margin-left: 9.09091% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/11 {
  margin-right: 9.09091% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/11 {
  margin-left: -9.09091% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/11 {
  margin-right: -9.09091% !important;
}

.u-flex-2\/11 {
  flex: 0 0 18.18182% !important;
  max-width: 18.18182% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/11 {
  margin-left: 18.18182% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/11 {
  margin-right: 18.18182% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/11 {
  margin-left: -18.18182% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/11 {
  margin-right: -18.18182% !important;
}

.u-flex-3\/11 {
  flex: 0 0 27.27273% !important;
  max-width: 27.27273% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/11 {
  margin-left: 27.27273% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/11 {
  margin-right: 27.27273% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/11 {
  margin-left: -27.27273% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/11 {
  margin-right: -27.27273% !important;
}

.u-flex-4\/11 {
  flex: 0 0 36.36364% !important;
  max-width: 36.36364% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/11 {
  margin-left: 36.36364% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/11 {
  margin-right: 36.36364% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/11 {
  margin-left: -36.36364% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/11 {
  margin-right: -36.36364% !important;
}

.u-flex-5\/11 {
  flex: 0 0 45.45455% !important;
  max-width: 45.45455% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/11 {
  margin-left: 45.45455% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/11 {
  margin-right: 45.45455% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/11 {
  margin-left: -45.45455% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/11 {
  margin-right: -45.45455% !important;
}

.u-flex-6\/11 {
  flex: 0 0 54.54545% !important;
  max-width: 54.54545% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/11 {
  margin-left: 54.54545% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/11 {
  margin-right: 54.54545% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/11 {
  margin-left: -54.54545% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/11 {
  margin-right: -54.54545% !important;
}

.u-flex-7\/11 {
  flex: 0 0 63.63636% !important;
  max-width: 63.63636% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/11 {
  margin-left: 63.63636% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/11 {
  margin-right: 63.63636% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/11 {
  margin-left: -63.63636% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/11 {
  margin-right: -63.63636% !important;
}

.u-flex-8\/11 {
  flex: 0 0 72.72727% !important;
  max-width: 72.72727% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/11 {
  margin-left: 72.72727% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/11 {
  margin-right: 72.72727% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/11 {
  margin-left: -72.72727% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/11 {
  margin-right: -72.72727% !important;
}

.u-flex-9\/11 {
  flex: 0 0 81.81818% !important;
  max-width: 81.81818% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/11 {
  margin-left: 81.81818% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/11 {
  margin-right: 81.81818% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/11 {
  margin-left: -81.81818% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/11 {
  margin-right: -81.81818% !important;
}

.u-flex-10\/11 {
  flex: 0 0 90.90909% !important;
  max-width: 90.90909% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/11 {
  margin-left: 90.90909% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/11 {
  margin-right: 90.90909% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/11 {
  margin-left: -90.90909% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/11 {
  margin-right: -90.90909% !important;
}

.u-flex-11\/11 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/11 {
  margin-left: 100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/11 {
  margin-right: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/11 {
  margin-left: -100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/11 {
  margin-right: -100% !important;
}

.u-flex-1\/12 {
  flex: 0 0 8.33333% !important;
  max-width: 8.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/12 {
  margin-left: 8.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/12 {
  margin-right: 8.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/12 {
  margin-left: -8.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/12 {
  margin-right: -8.33333% !important;
}

.u-flex-2\/12 {
  flex: 0 0 16.66667% !important;
  max-width: 16.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/12 {
  margin-left: 16.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/12 {
  margin-right: 16.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/12 {
  margin-left: -16.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/12 {
  margin-right: -16.66667% !important;
}

.u-flex-3\/12 {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/12 {
  margin-left: 25% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/12 {
  margin-right: 25% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/12 {
  margin-left: -25% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/12 {
  margin-right: -25% !important;
}

.u-flex-4\/12 {
  flex: 0 0 33.33333% !important;
  max-width: 33.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/12 {
  margin-left: 33.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/12 {
  margin-right: 33.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/12 {
  margin-left: -33.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/12 {
  margin-right: -33.33333% !important;
}

.u-flex-5\/12 {
  flex: 0 0 41.66667% !important;
  max-width: 41.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/12 {
  margin-left: 41.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/12 {
  margin-right: 41.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/12 {
  margin-left: -41.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/12 {
  margin-right: -41.66667% !important;
}

.u-flex-6\/12 {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/12 {
  margin-left: 50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/12 {
  margin-right: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/12 {
  margin-left: -50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/12 {
  margin-right: -50% !important;
}

.u-flex-7\/12 {
  flex: 0 0 58.33333% !important;
  max-width: 58.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/12 {
  margin-left: 58.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/12 {
  margin-right: 58.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/12 {
  margin-left: -58.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/12 {
  margin-right: -58.33333% !important;
}

.u-flex-8\/12 {
  flex: 0 0 66.66667% !important;
  max-width: 66.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/12 {
  margin-left: 66.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/12 {
  margin-right: 66.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/12 {
  margin-left: -66.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/12 {
  margin-right: -66.66667% !important;
}

.u-flex-9\/12 {
  flex: 0 0 75% !important;
  max-width: 75% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/12 {
  margin-left: 75% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/12 {
  margin-right: 75% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/12 {
  margin-left: -75% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/12 {
  margin-right: -75% !important;
}

.u-flex-10\/12 {
  flex: 0 0 83.33333% !important;
  max-width: 83.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/12 {
  margin-left: 83.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/12 {
  margin-right: 83.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/12 {
  margin-left: -83.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/12 {
  margin-right: -83.33333% !important;
}

.u-flex-11\/12 {
  flex: 0 0 91.66667% !important;
  max-width: 91.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/12 {
  margin-left: 91.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/12 {
  margin-right: 91.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/12 {
  margin-left: -91.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/12 {
  margin-right: -91.66667% !important;
}

.u-flex-12\/12 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-12\/12 {
  margin-left: 100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-12\/12 {
  margin-right: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-12\/12 {
  margin-left: -100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-12\/12 {
  margin-right: -100% !important;
}

.u-flex-1\/24 {
  flex: 0 0 4.16667% !important;
  max-width: 4.16667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/24 {
  margin-left: 4.16667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/24 {
  margin-right: 4.16667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/24 {
  margin-left: -4.16667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/24 {
  margin-right: -4.16667% !important;
}

.u-flex-2\/24 {
  flex: 0 0 8.33333% !important;
  max-width: 8.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/24 {
  margin-left: 8.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/24 {
  margin-right: 8.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/24 {
  margin-left: -8.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/24 {
  margin-right: -8.33333% !important;
}

.u-flex-3\/24 {
  flex: 0 0 12.5% !important;
  max-width: 12.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/24 {
  margin-left: 12.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/24 {
  margin-right: 12.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/24 {
  margin-left: -12.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/24 {
  margin-right: -12.5% !important;
}

.u-flex-4\/24 {
  flex: 0 0 16.66667% !important;
  max-width: 16.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/24 {
  margin-left: 16.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/24 {
  margin-right: 16.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/24 {
  margin-left: -16.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/24 {
  margin-right: -16.66667% !important;
}

.u-flex-5\/24 {
  flex: 0 0 20.83333% !important;
  max-width: 20.83333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/24 {
  margin-left: 20.83333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/24 {
  margin-right: 20.83333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/24 {
  margin-left: -20.83333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/24 {
  margin-right: -20.83333% !important;
}

.u-flex-6\/24 {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/24 {
  margin-left: 25% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/24 {
  margin-right: 25% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/24 {
  margin-left: -25% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/24 {
  margin-right: -25% !important;
}

.u-flex-7\/24 {
  flex: 0 0 29.16667% !important;
  max-width: 29.16667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/24 {
  margin-left: 29.16667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/24 {
  margin-right: 29.16667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/24 {
  margin-left: -29.16667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/24 {
  margin-right: -29.16667% !important;
}

.u-flex-8\/24 {
  flex: 0 0 33.33333% !important;
  max-width: 33.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/24 {
  margin-left: 33.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/24 {
  margin-right: 33.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/24 {
  margin-left: -33.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/24 {
  margin-right: -33.33333% !important;
}

.u-flex-9\/24 {
  flex: 0 0 37.5% !important;
  max-width: 37.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/24 {
  margin-left: 37.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/24 {
  margin-right: 37.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/24 {
  margin-left: -37.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/24 {
  margin-right: -37.5% !important;
}

.u-flex-10\/24 {
  flex: 0 0 41.66667% !important;
  max-width: 41.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/24 {
  margin-left: 41.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/24 {
  margin-right: 41.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/24 {
  margin-left: -41.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/24 {
  margin-right: -41.66667% !important;
}

.u-flex-11\/24 {
  flex: 0 0 45.83333% !important;
  max-width: 45.83333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/24 {
  margin-left: 45.83333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/24 {
  margin-right: 45.83333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/24 {
  margin-left: -45.83333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/24 {
  margin-right: -45.83333% !important;
}

.u-flex-12\/24 {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-12\/24 {
  margin-left: 50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-12\/24 {
  margin-right: 50% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-12\/24 {
  margin-left: -50% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-12\/24 {
  margin-right: -50% !important;
}

.u-flex-13\/24 {
  flex: 0 0 54.16667% !important;
  max-width: 54.16667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-13\/24 {
  margin-left: 54.16667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-13\/24 {
  margin-right: 54.16667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-13\/24 {
  margin-left: -54.16667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-13\/24 {
  margin-right: -54.16667% !important;
}

.u-flex-14\/24 {
  flex: 0 0 58.33333% !important;
  max-width: 58.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-14\/24 {
  margin-left: 58.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-14\/24 {
  margin-right: 58.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-14\/24 {
  margin-left: -58.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-14\/24 {
  margin-right: -58.33333% !important;
}

.u-flex-15\/24 {
  flex: 0 0 62.5% !important;
  max-width: 62.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-15\/24 {
  margin-left: 62.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-15\/24 {
  margin-right: 62.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-15\/24 {
  margin-left: -62.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-15\/24 {
  margin-right: -62.5% !important;
}

.u-flex-16\/24 {
  flex: 0 0 66.66667% !important;
  max-width: 66.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-16\/24 {
  margin-left: 66.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-16\/24 {
  margin-right: 66.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-16\/24 {
  margin-left: -66.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-16\/24 {
  margin-right: -66.66667% !important;
}

.u-flex-17\/24 {
  flex: 0 0 70.83333% !important;
  max-width: 70.83333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-17\/24 {
  margin-left: 70.83333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-17\/24 {
  margin-right: 70.83333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-17\/24 {
  margin-left: -70.83333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-17\/24 {
  margin-right: -70.83333% !important;
}

.u-flex-18\/24 {
  flex: 0 0 75% !important;
  max-width: 75% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-18\/24 {
  margin-left: 75% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-18\/24 {
  margin-right: 75% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-18\/24 {
  margin-left: -75% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-18\/24 {
  margin-right: -75% !important;
}

.u-flex-19\/24 {
  flex: 0 0 79.16667% !important;
  max-width: 79.16667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-19\/24 {
  margin-left: 79.16667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-19\/24 {
  margin-right: 79.16667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-19\/24 {
  margin-left: -79.16667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-19\/24 {
  margin-right: -79.16667% !important;
}

.u-flex-20\/24 {
  flex: 0 0 83.33333% !important;
  max-width: 83.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-20\/24 {
  margin-left: 83.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-20\/24 {
  margin-right: 83.33333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-20\/24 {
  margin-left: -83.33333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-20\/24 {
  margin-right: -83.33333% !important;
}

.u-flex-21\/24 {
  flex: 0 0 87.5% !important;
  max-width: 87.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-21\/24 {
  margin-left: 87.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-21\/24 {
  margin-right: 87.5% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-21\/24 {
  margin-left: -87.5% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-21\/24 {
  margin-right: -87.5% !important;
}

.u-flex-22\/24 {
  flex: 0 0 91.66667% !important;
  max-width: 91.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-22\/24 {
  margin-left: 91.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-22\/24 {
  margin-right: 91.66667% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-22\/24 {
  margin-left: -91.66667% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-22\/24 {
  margin-right: -91.66667% !important;
}

.u-flex-23\/24 {
  flex: 0 0 95.83333% !important;
  max-width: 95.83333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-23\/24 {
  margin-left: 95.83333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-23\/24 {
  margin-right: 95.83333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-23\/24 {
  margin-left: -95.83333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-23\/24 {
  margin-right: -95.83333% !important;
}

.u-flex-24\/24 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-24\/24 {
  margin-left: 100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-24\/24 {
  margin-right: 100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-24\/24 {
  margin-left: -100% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-24\/24 {
  margin-right: -100% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-none {
  margin-left: 0 !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-none {
  margin-right: 0 !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-none {
  margin-left: 0 !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-none {
  margin-right: 0 !important;
}

/*
    ==========================================================================||
    Similar to inuitcss utilities.widths, $mq-breakpoints defined allows
    you to do something like this for additional control via markup only

    e.g.
    <div class="o-flex__item u-flex-1/2@mobile"></div>
    <div class="o-flex__item u-flex-1/3@tablet u-flex-offset-1/3@tablet"></div>
    <div class="o-flex__item u-flex-1/4@desktop"></div>
    <div class="o-flex__item u-flex-1/5@wide u-flex-offset-2/5@wide"></div>
    ==========================================================================||
*/
@media (min-width: 20em) {
  .u-flex-1\/1\@mobile {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/1\@mobile {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/1\@mobile {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/1\@mobile {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/1\@mobile {
    margin-right: -100% !important;
  }
  .u-flex-1\/2\@mobile {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/2\@mobile {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/2\@mobile {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/2\@mobile {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/2\@mobile {
    margin-right: -50% !important;
  }
  .u-flex-2\/2\@mobile {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/2\@mobile {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/2\@mobile {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/2\@mobile {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/2\@mobile {
    margin-right: -100% !important;
  }
  .u-flex-1\/3\@mobile {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/3\@mobile {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/3\@mobile {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/3\@mobile {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/3\@mobile {
    margin-right: -33.33333% !important;
  }
  .u-flex-2\/3\@mobile {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/3\@mobile {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/3\@mobile {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/3\@mobile {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/3\@mobile {
    margin-right: -66.66667% !important;
  }
  .u-flex-3\/3\@mobile {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/3\@mobile {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/3\@mobile {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/3\@mobile {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/3\@mobile {
    margin-right: -100% !important;
  }
  .u-flex-1\/4\@mobile {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/4\@mobile {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/4\@mobile {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/4\@mobile {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/4\@mobile {
    margin-right: -25% !important;
  }
  .u-flex-2\/4\@mobile {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/4\@mobile {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/4\@mobile {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/4\@mobile {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/4\@mobile {
    margin-right: -50% !important;
  }
  .u-flex-3\/4\@mobile {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/4\@mobile {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/4\@mobile {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/4\@mobile {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/4\@mobile {
    margin-right: -75% !important;
  }
  .u-flex-4\/4\@mobile {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/4\@mobile {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/4\@mobile {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/4\@mobile {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/4\@mobile {
    margin-right: -100% !important;
  }
  .u-flex-1\/5\@mobile {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/5\@mobile {
    margin-left: 20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/5\@mobile {
    margin-right: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/5\@mobile {
    margin-left: -20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/5\@mobile {
    margin-right: -20% !important;
  }
  .u-flex-2\/5\@mobile {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/5\@mobile {
    margin-left: 40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/5\@mobile {
    margin-right: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/5\@mobile {
    margin-left: -40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/5\@mobile {
    margin-right: -40% !important;
  }
  .u-flex-3\/5\@mobile {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/5\@mobile {
    margin-left: 60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/5\@mobile {
    margin-right: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/5\@mobile {
    margin-left: -60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/5\@mobile {
    margin-right: -60% !important;
  }
  .u-flex-4\/5\@mobile {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/5\@mobile {
    margin-left: 80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/5\@mobile {
    margin-right: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/5\@mobile {
    margin-left: -80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/5\@mobile {
    margin-right: -80% !important;
  }
  .u-flex-5\/5\@mobile {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/5\@mobile {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/5\@mobile {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/5\@mobile {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/5\@mobile {
    margin-right: -100% !important;
  }
  .u-flex-1\/6\@mobile {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/6\@mobile {
    margin-left: 16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/6\@mobile {
    margin-right: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/6\@mobile {
    margin-left: -16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/6\@mobile {
    margin-right: -16.66667% !important;
  }
  .u-flex-2\/6\@mobile {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/6\@mobile {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/6\@mobile {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/6\@mobile {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/6\@mobile {
    margin-right: -33.33333% !important;
  }
  .u-flex-3\/6\@mobile {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/6\@mobile {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/6\@mobile {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/6\@mobile {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/6\@mobile {
    margin-right: -50% !important;
  }
  .u-flex-4\/6\@mobile {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/6\@mobile {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/6\@mobile {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/6\@mobile {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/6\@mobile {
    margin-right: -66.66667% !important;
  }
  .u-flex-5\/6\@mobile {
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/6\@mobile {
    margin-left: 83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/6\@mobile {
    margin-right: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/6\@mobile {
    margin-left: -83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/6\@mobile {
    margin-right: -83.33333% !important;
  }
  .u-flex-6\/6\@mobile {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/6\@mobile {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/6\@mobile {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/6\@mobile {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/6\@mobile {
    margin-right: -100% !important;
  }
  .u-flex-1\/7\@mobile {
    flex: 0 0 14.28571% !important;
    max-width: 14.28571% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/7\@mobile {
    margin-left: 14.28571% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/7\@mobile {
    margin-right: 14.28571% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/7\@mobile {
    margin-left: -14.28571% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/7\@mobile {
    margin-right: -14.28571% !important;
  }
  .u-flex-2\/7\@mobile {
    flex: 0 0 28.57143% !important;
    max-width: 28.57143% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/7\@mobile {
    margin-left: 28.57143% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/7\@mobile {
    margin-right: 28.57143% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/7\@mobile {
    margin-left: -28.57143% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/7\@mobile {
    margin-right: -28.57143% !important;
  }
  .u-flex-3\/7\@mobile {
    flex: 0 0 42.85714% !important;
    max-width: 42.85714% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/7\@mobile {
    margin-left: 42.85714% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/7\@mobile {
    margin-right: 42.85714% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/7\@mobile {
    margin-left: -42.85714% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/7\@mobile {
    margin-right: -42.85714% !important;
  }
  .u-flex-4\/7\@mobile {
    flex: 0 0 57.14286% !important;
    max-width: 57.14286% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/7\@mobile {
    margin-left: 57.14286% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/7\@mobile {
    margin-right: 57.14286% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/7\@mobile {
    margin-left: -57.14286% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/7\@mobile {
    margin-right: -57.14286% !important;
  }
  .u-flex-5\/7\@mobile {
    flex: 0 0 71.42857% !important;
    max-width: 71.42857% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/7\@mobile {
    margin-left: 71.42857% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/7\@mobile {
    margin-right: 71.42857% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/7\@mobile {
    margin-left: -71.42857% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/7\@mobile {
    margin-right: -71.42857% !important;
  }
  .u-flex-6\/7\@mobile {
    flex: 0 0 85.71429% !important;
    max-width: 85.71429% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/7\@mobile {
    margin-left: 85.71429% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/7\@mobile {
    margin-right: 85.71429% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/7\@mobile {
    margin-left: -85.71429% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/7\@mobile {
    margin-right: -85.71429% !important;
  }
  .u-flex-7\/7\@mobile {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/7\@mobile {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/7\@mobile {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/7\@mobile {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/7\@mobile {
    margin-right: -100% !important;
  }
  .u-flex-1\/8\@mobile {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/8\@mobile {
    margin-left: 12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/8\@mobile {
    margin-right: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/8\@mobile {
    margin-left: -12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/8\@mobile {
    margin-right: -12.5% !important;
  }
  .u-flex-2\/8\@mobile {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/8\@mobile {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/8\@mobile {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/8\@mobile {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/8\@mobile {
    margin-right: -25% !important;
  }
  .u-flex-3\/8\@mobile {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/8\@mobile {
    margin-left: 37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/8\@mobile {
    margin-right: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/8\@mobile {
    margin-left: -37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/8\@mobile {
    margin-right: -37.5% !important;
  }
  .u-flex-4\/8\@mobile {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/8\@mobile {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/8\@mobile {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/8\@mobile {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/8\@mobile {
    margin-right: -50% !important;
  }
  .u-flex-5\/8\@mobile {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/8\@mobile {
    margin-left: 62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/8\@mobile {
    margin-right: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/8\@mobile {
    margin-left: -62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/8\@mobile {
    margin-right: -62.5% !important;
  }
  .u-flex-6\/8\@mobile {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/8\@mobile {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/8\@mobile {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/8\@mobile {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/8\@mobile {
    margin-right: -75% !important;
  }
  .u-flex-7\/8\@mobile {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/8\@mobile {
    margin-left: 87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/8\@mobile {
    margin-right: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/8\@mobile {
    margin-left: -87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/8\@mobile {
    margin-right: -87.5% !important;
  }
  .u-flex-8\/8\@mobile {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/8\@mobile {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/8\@mobile {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/8\@mobile {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/8\@mobile {
    margin-right: -100% !important;
  }
  .u-flex-1\/9\@mobile {
    flex: 0 0 11.11111% !important;
    max-width: 11.11111% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/9\@mobile {
    margin-left: 11.11111% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/9\@mobile {
    margin-right: 11.11111% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/9\@mobile {
    margin-left: -11.11111% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/9\@mobile {
    margin-right: -11.11111% !important;
  }
  .u-flex-2\/9\@mobile {
    flex: 0 0 22.22222% !important;
    max-width: 22.22222% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/9\@mobile {
    margin-left: 22.22222% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/9\@mobile {
    margin-right: 22.22222% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/9\@mobile {
    margin-left: -22.22222% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/9\@mobile {
    margin-right: -22.22222% !important;
  }
  .u-flex-3\/9\@mobile {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/9\@mobile {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/9\@mobile {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/9\@mobile {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/9\@mobile {
    margin-right: -33.33333% !important;
  }
  .u-flex-4\/9\@mobile {
    flex: 0 0 44.44444% !important;
    max-width: 44.44444% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/9\@mobile {
    margin-left: 44.44444% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/9\@mobile {
    margin-right: 44.44444% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/9\@mobile {
    margin-left: -44.44444% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/9\@mobile {
    margin-right: -44.44444% !important;
  }
  .u-flex-5\/9\@mobile {
    flex: 0 0 55.55556% !important;
    max-width: 55.55556% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/9\@mobile {
    margin-left: 55.55556% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/9\@mobile {
    margin-right: 55.55556% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/9\@mobile {
    margin-left: -55.55556% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/9\@mobile {
    margin-right: -55.55556% !important;
  }
  .u-flex-6\/9\@mobile {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/9\@mobile {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/9\@mobile {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/9\@mobile {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/9\@mobile {
    margin-right: -66.66667% !important;
  }
  .u-flex-7\/9\@mobile {
    flex: 0 0 77.77778% !important;
    max-width: 77.77778% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/9\@mobile {
    margin-left: 77.77778% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/9\@mobile {
    margin-right: 77.77778% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/9\@mobile {
    margin-left: -77.77778% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/9\@mobile {
    margin-right: -77.77778% !important;
  }
  .u-flex-8\/9\@mobile {
    flex: 0 0 88.88889% !important;
    max-width: 88.88889% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/9\@mobile {
    margin-left: 88.88889% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/9\@mobile {
    margin-right: 88.88889% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/9\@mobile {
    margin-left: -88.88889% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/9\@mobile {
    margin-right: -88.88889% !important;
  }
  .u-flex-9\/9\@mobile {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/9\@mobile {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/9\@mobile {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/9\@mobile {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/9\@mobile {
    margin-right: -100% !important;
  }
  .u-flex-1\/10\@mobile {
    flex: 0 0 10% !important;
    max-width: 10% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/10\@mobile {
    margin-left: 10% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/10\@mobile {
    margin-right: 10% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/10\@mobile {
    margin-left: -10% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/10\@mobile {
    margin-right: -10% !important;
  }
  .u-flex-2\/10\@mobile {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/10\@mobile {
    margin-left: 20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/10\@mobile {
    margin-right: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/10\@mobile {
    margin-left: -20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/10\@mobile {
    margin-right: -20% !important;
  }
  .u-flex-3\/10\@mobile {
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/10\@mobile {
    margin-left: 30% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/10\@mobile {
    margin-right: 30% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/10\@mobile {
    margin-left: -30% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/10\@mobile {
    margin-right: -30% !important;
  }
  .u-flex-4\/10\@mobile {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/10\@mobile {
    margin-left: 40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/10\@mobile {
    margin-right: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/10\@mobile {
    margin-left: -40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/10\@mobile {
    margin-right: -40% !important;
  }
  .u-flex-5\/10\@mobile {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/10\@mobile {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/10\@mobile {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/10\@mobile {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/10\@mobile {
    margin-right: -50% !important;
  }
  .u-flex-6\/10\@mobile {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/10\@mobile {
    margin-left: 60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/10\@mobile {
    margin-right: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/10\@mobile {
    margin-left: -60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/10\@mobile {
    margin-right: -60% !important;
  }
  .u-flex-7\/10\@mobile {
    flex: 0 0 70% !important;
    max-width: 70% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/10\@mobile {
    margin-left: 70% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/10\@mobile {
    margin-right: 70% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/10\@mobile {
    margin-left: -70% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/10\@mobile {
    margin-right: -70% !important;
  }
  .u-flex-8\/10\@mobile {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/10\@mobile {
    margin-left: 80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/10\@mobile {
    margin-right: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/10\@mobile {
    margin-left: -80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/10\@mobile {
    margin-right: -80% !important;
  }
  .u-flex-9\/10\@mobile {
    flex: 0 0 90% !important;
    max-width: 90% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/10\@mobile {
    margin-left: 90% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/10\@mobile {
    margin-right: 90% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/10\@mobile {
    margin-left: -90% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/10\@mobile {
    margin-right: -90% !important;
  }
  .u-flex-10\/10\@mobile {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/10\@mobile {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/10\@mobile {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/10\@mobile {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/10\@mobile {
    margin-right: -100% !important;
  }
  .u-flex-1\/11\@mobile {
    flex: 0 0 9.09091% !important;
    max-width: 9.09091% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/11\@mobile {
    margin-left: 9.09091% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/11\@mobile {
    margin-right: 9.09091% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/11\@mobile {
    margin-left: -9.09091% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/11\@mobile {
    margin-right: -9.09091% !important;
  }
  .u-flex-2\/11\@mobile {
    flex: 0 0 18.18182% !important;
    max-width: 18.18182% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/11\@mobile {
    margin-left: 18.18182% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/11\@mobile {
    margin-right: 18.18182% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/11\@mobile {
    margin-left: -18.18182% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/11\@mobile {
    margin-right: -18.18182% !important;
  }
  .u-flex-3\/11\@mobile {
    flex: 0 0 27.27273% !important;
    max-width: 27.27273% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/11\@mobile {
    margin-left: 27.27273% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/11\@mobile {
    margin-right: 27.27273% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/11\@mobile {
    margin-left: -27.27273% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/11\@mobile {
    margin-right: -27.27273% !important;
  }
  .u-flex-4\/11\@mobile {
    flex: 0 0 36.36364% !important;
    max-width: 36.36364% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/11\@mobile {
    margin-left: 36.36364% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/11\@mobile {
    margin-right: 36.36364% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/11\@mobile {
    margin-left: -36.36364% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/11\@mobile {
    margin-right: -36.36364% !important;
  }
  .u-flex-5\/11\@mobile {
    flex: 0 0 45.45455% !important;
    max-width: 45.45455% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/11\@mobile {
    margin-left: 45.45455% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/11\@mobile {
    margin-right: 45.45455% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/11\@mobile {
    margin-left: -45.45455% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/11\@mobile {
    margin-right: -45.45455% !important;
  }
  .u-flex-6\/11\@mobile {
    flex: 0 0 54.54545% !important;
    max-width: 54.54545% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/11\@mobile {
    margin-left: 54.54545% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/11\@mobile {
    margin-right: 54.54545% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/11\@mobile {
    margin-left: -54.54545% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/11\@mobile {
    margin-right: -54.54545% !important;
  }
  .u-flex-7\/11\@mobile {
    flex: 0 0 63.63636% !important;
    max-width: 63.63636% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/11\@mobile {
    margin-left: 63.63636% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/11\@mobile {
    margin-right: 63.63636% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/11\@mobile {
    margin-left: -63.63636% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/11\@mobile {
    margin-right: -63.63636% !important;
  }
  .u-flex-8\/11\@mobile {
    flex: 0 0 72.72727% !important;
    max-width: 72.72727% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/11\@mobile {
    margin-left: 72.72727% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/11\@mobile {
    margin-right: 72.72727% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/11\@mobile {
    margin-left: -72.72727% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/11\@mobile {
    margin-right: -72.72727% !important;
  }
  .u-flex-9\/11\@mobile {
    flex: 0 0 81.81818% !important;
    max-width: 81.81818% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/11\@mobile {
    margin-left: 81.81818% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/11\@mobile {
    margin-right: 81.81818% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/11\@mobile {
    margin-left: -81.81818% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/11\@mobile {
    margin-right: -81.81818% !important;
  }
  .u-flex-10\/11\@mobile {
    flex: 0 0 90.90909% !important;
    max-width: 90.90909% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/11\@mobile {
    margin-left: 90.90909% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/11\@mobile {
    margin-right: 90.90909% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/11\@mobile {
    margin-left: -90.90909% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/11\@mobile {
    margin-right: -90.90909% !important;
  }
  .u-flex-11\/11\@mobile {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/11\@mobile {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/11\@mobile {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/11\@mobile {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/11\@mobile {
    margin-right: -100% !important;
  }
  .u-flex-1\/12\@mobile {
    flex: 0 0 8.33333% !important;
    max-width: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/12\@mobile {
    margin-left: 8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/12\@mobile {
    margin-right: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/12\@mobile {
    margin-left: -8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/12\@mobile {
    margin-right: -8.33333% !important;
  }
  .u-flex-2\/12\@mobile {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/12\@mobile {
    margin-left: 16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/12\@mobile {
    margin-right: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/12\@mobile {
    margin-left: -16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/12\@mobile {
    margin-right: -16.66667% !important;
  }
  .u-flex-3\/12\@mobile {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/12\@mobile {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/12\@mobile {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/12\@mobile {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/12\@mobile {
    margin-right: -25% !important;
  }
  .u-flex-4\/12\@mobile {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/12\@mobile {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/12\@mobile {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/12\@mobile {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/12\@mobile {
    margin-right: -33.33333% !important;
  }
  .u-flex-5\/12\@mobile {
    flex: 0 0 41.66667% !important;
    max-width: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/12\@mobile {
    margin-left: 41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/12\@mobile {
    margin-right: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/12\@mobile {
    margin-left: -41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/12\@mobile {
    margin-right: -41.66667% !important;
  }
  .u-flex-6\/12\@mobile {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/12\@mobile {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/12\@mobile {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/12\@mobile {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/12\@mobile {
    margin-right: -50% !important;
  }
  .u-flex-7\/12\@mobile {
    flex: 0 0 58.33333% !important;
    max-width: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/12\@mobile {
    margin-left: 58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/12\@mobile {
    margin-right: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/12\@mobile {
    margin-left: -58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/12\@mobile {
    margin-right: -58.33333% !important;
  }
  .u-flex-8\/12\@mobile {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/12\@mobile {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/12\@mobile {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/12\@mobile {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/12\@mobile {
    margin-right: -66.66667% !important;
  }
  .u-flex-9\/12\@mobile {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/12\@mobile {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/12\@mobile {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/12\@mobile {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/12\@mobile {
    margin-right: -75% !important;
  }
  .u-flex-10\/12\@mobile {
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/12\@mobile {
    margin-left: 83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/12\@mobile {
    margin-right: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/12\@mobile {
    margin-left: -83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/12\@mobile {
    margin-right: -83.33333% !important;
  }
  .u-flex-11\/12\@mobile {
    flex: 0 0 91.66667% !important;
    max-width: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/12\@mobile {
    margin-left: 91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/12\@mobile {
    margin-right: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/12\@mobile {
    margin-left: -91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/12\@mobile {
    margin-right: -91.66667% !important;
  }
  .u-flex-12\/12\@mobile {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-12\/12\@mobile {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-12\/12\@mobile {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-12\/12\@mobile {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-12\/12\@mobile {
    margin-right: -100% !important;
  }
  .u-flex-1\/24\@mobile {
    flex: 0 0 4.16667% !important;
    max-width: 4.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/24\@mobile {
    margin-left: 4.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/24\@mobile {
    margin-right: 4.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/24\@mobile {
    margin-left: -4.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/24\@mobile {
    margin-right: -4.16667% !important;
  }
  .u-flex-2\/24\@mobile {
    flex: 0 0 8.33333% !important;
    max-width: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/24\@mobile {
    margin-left: 8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/24\@mobile {
    margin-right: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/24\@mobile {
    margin-left: -8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/24\@mobile {
    margin-right: -8.33333% !important;
  }
  .u-flex-3\/24\@mobile {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/24\@mobile {
    margin-left: 12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/24\@mobile {
    margin-right: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/24\@mobile {
    margin-left: -12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/24\@mobile {
    margin-right: -12.5% !important;
  }
  .u-flex-4\/24\@mobile {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/24\@mobile {
    margin-left: 16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/24\@mobile {
    margin-right: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/24\@mobile {
    margin-left: -16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/24\@mobile {
    margin-right: -16.66667% !important;
  }
  .u-flex-5\/24\@mobile {
    flex: 0 0 20.83333% !important;
    max-width: 20.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/24\@mobile {
    margin-left: 20.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/24\@mobile {
    margin-right: 20.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/24\@mobile {
    margin-left: -20.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/24\@mobile {
    margin-right: -20.83333% !important;
  }
  .u-flex-6\/24\@mobile {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/24\@mobile {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/24\@mobile {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/24\@mobile {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/24\@mobile {
    margin-right: -25% !important;
  }
  .u-flex-7\/24\@mobile {
    flex: 0 0 29.16667% !important;
    max-width: 29.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/24\@mobile {
    margin-left: 29.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/24\@mobile {
    margin-right: 29.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/24\@mobile {
    margin-left: -29.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/24\@mobile {
    margin-right: -29.16667% !important;
  }
  .u-flex-8\/24\@mobile {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/24\@mobile {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/24\@mobile {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/24\@mobile {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/24\@mobile {
    margin-right: -33.33333% !important;
  }
  .u-flex-9\/24\@mobile {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/24\@mobile {
    margin-left: 37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/24\@mobile {
    margin-right: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/24\@mobile {
    margin-left: -37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/24\@mobile {
    margin-right: -37.5% !important;
  }
  .u-flex-10\/24\@mobile {
    flex: 0 0 41.66667% !important;
    max-width: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/24\@mobile {
    margin-left: 41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/24\@mobile {
    margin-right: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/24\@mobile {
    margin-left: -41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/24\@mobile {
    margin-right: -41.66667% !important;
  }
  .u-flex-11\/24\@mobile {
    flex: 0 0 45.83333% !important;
    max-width: 45.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/24\@mobile {
    margin-left: 45.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/24\@mobile {
    margin-right: 45.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/24\@mobile {
    margin-left: -45.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/24\@mobile {
    margin-right: -45.83333% !important;
  }
  .u-flex-12\/24\@mobile {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-12\/24\@mobile {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-12\/24\@mobile {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-12\/24\@mobile {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-12\/24\@mobile {
    margin-right: -50% !important;
  }
  .u-flex-13\/24\@mobile {
    flex: 0 0 54.16667% !important;
    max-width: 54.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-13\/24\@mobile {
    margin-left: 54.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-13\/24\@mobile {
    margin-right: 54.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-13\/24\@mobile {
    margin-left: -54.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-13\/24\@mobile {
    margin-right: -54.16667% !important;
  }
  .u-flex-14\/24\@mobile {
    flex: 0 0 58.33333% !important;
    max-width: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-14\/24\@mobile {
    margin-left: 58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-14\/24\@mobile {
    margin-right: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-14\/24\@mobile {
    margin-left: -58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-14\/24\@mobile {
    margin-right: -58.33333% !important;
  }
  .u-flex-15\/24\@mobile {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-15\/24\@mobile {
    margin-left: 62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-15\/24\@mobile {
    margin-right: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-15\/24\@mobile {
    margin-left: -62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-15\/24\@mobile {
    margin-right: -62.5% !important;
  }
  .u-flex-16\/24\@mobile {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-16\/24\@mobile {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-16\/24\@mobile {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-16\/24\@mobile {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-16\/24\@mobile {
    margin-right: -66.66667% !important;
  }
  .u-flex-17\/24\@mobile {
    flex: 0 0 70.83333% !important;
    max-width: 70.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-17\/24\@mobile {
    margin-left: 70.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-17\/24\@mobile {
    margin-right: 70.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-17\/24\@mobile {
    margin-left: -70.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-17\/24\@mobile {
    margin-right: -70.83333% !important;
  }
  .u-flex-18\/24\@mobile {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-18\/24\@mobile {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-18\/24\@mobile {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-18\/24\@mobile {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-18\/24\@mobile {
    margin-right: -75% !important;
  }
  .u-flex-19\/24\@mobile {
    flex: 0 0 79.16667% !important;
    max-width: 79.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-19\/24\@mobile {
    margin-left: 79.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-19\/24\@mobile {
    margin-right: 79.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-19\/24\@mobile {
    margin-left: -79.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-19\/24\@mobile {
    margin-right: -79.16667% !important;
  }
  .u-flex-20\/24\@mobile {
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-20\/24\@mobile {
    margin-left: 83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-20\/24\@mobile {
    margin-right: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-20\/24\@mobile {
    margin-left: -83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-20\/24\@mobile {
    margin-right: -83.33333% !important;
  }
  .u-flex-21\/24\@mobile {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-21\/24\@mobile {
    margin-left: 87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-21\/24\@mobile {
    margin-right: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-21\/24\@mobile {
    margin-left: -87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-21\/24\@mobile {
    margin-right: -87.5% !important;
  }
  .u-flex-22\/24\@mobile {
    flex: 0 0 91.66667% !important;
    max-width: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-22\/24\@mobile {
    margin-left: 91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-22\/24\@mobile {
    margin-right: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-22\/24\@mobile {
    margin-left: -91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-22\/24\@mobile {
    margin-right: -91.66667% !important;
  }
  .u-flex-23\/24\@mobile {
    flex: 0 0 95.83333% !important;
    max-width: 95.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-23\/24\@mobile {
    margin-left: 95.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-23\/24\@mobile {
    margin-right: 95.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-23\/24\@mobile {
    margin-left: -95.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-23\/24\@mobile {
    margin-right: -95.83333% !important;
  }
  .u-flex-24\/24\@mobile {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-24\/24\@mobile {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-24\/24\@mobile {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-24\/24\@mobile {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-24\/24\@mobile {
    margin-right: -100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-none\@mobile {
    margin-left: 0 !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-none\@mobile {
    margin-right: 0 !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-none\@mobile {
    margin-left: 0 !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-none\@mobile {
    margin-right: 0 !important;
  }
}

@media (min-width: 46.25em) {
  .u-flex-1\/1\@tablet {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/1\@tablet {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/1\@tablet {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/1\@tablet {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/1\@tablet {
    margin-right: -100% !important;
  }
  .u-flex-1\/2\@tablet {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/2\@tablet {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/2\@tablet {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/2\@tablet {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/2\@tablet {
    margin-right: -50% !important;
  }
  .u-flex-2\/2\@tablet {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/2\@tablet {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/2\@tablet {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/2\@tablet {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/2\@tablet {
    margin-right: -100% !important;
  }
  .u-flex-1\/3\@tablet {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/3\@tablet {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/3\@tablet {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/3\@tablet {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/3\@tablet {
    margin-right: -33.33333% !important;
  }
  .u-flex-2\/3\@tablet {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/3\@tablet {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/3\@tablet {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/3\@tablet {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/3\@tablet {
    margin-right: -66.66667% !important;
  }
  .u-flex-3\/3\@tablet {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/3\@tablet {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/3\@tablet {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/3\@tablet {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/3\@tablet {
    margin-right: -100% !important;
  }
  .u-flex-1\/4\@tablet {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/4\@tablet {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/4\@tablet {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/4\@tablet {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/4\@tablet {
    margin-right: -25% !important;
  }
  .u-flex-2\/4\@tablet {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/4\@tablet {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/4\@tablet {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/4\@tablet {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/4\@tablet {
    margin-right: -50% !important;
  }
  .u-flex-3\/4\@tablet {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/4\@tablet {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/4\@tablet {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/4\@tablet {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/4\@tablet {
    margin-right: -75% !important;
  }
  .u-flex-4\/4\@tablet {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/4\@tablet {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/4\@tablet {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/4\@tablet {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/4\@tablet {
    margin-right: -100% !important;
  }
  .u-flex-1\/5\@tablet {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/5\@tablet {
    margin-left: 20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/5\@tablet {
    margin-right: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/5\@tablet {
    margin-left: -20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/5\@tablet {
    margin-right: -20% !important;
  }
  .u-flex-2\/5\@tablet {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/5\@tablet {
    margin-left: 40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/5\@tablet {
    margin-right: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/5\@tablet {
    margin-left: -40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/5\@tablet {
    margin-right: -40% !important;
  }
  .u-flex-3\/5\@tablet {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/5\@tablet {
    margin-left: 60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/5\@tablet {
    margin-right: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/5\@tablet {
    margin-left: -60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/5\@tablet {
    margin-right: -60% !important;
  }
  .u-flex-4\/5\@tablet {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/5\@tablet {
    margin-left: 80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/5\@tablet {
    margin-right: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/5\@tablet {
    margin-left: -80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/5\@tablet {
    margin-right: -80% !important;
  }
  .u-flex-5\/5\@tablet {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/5\@tablet {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/5\@tablet {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/5\@tablet {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/5\@tablet {
    margin-right: -100% !important;
  }
  .u-flex-1\/6\@tablet {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/6\@tablet {
    margin-left: 16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/6\@tablet {
    margin-right: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/6\@tablet {
    margin-left: -16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/6\@tablet {
    margin-right: -16.66667% !important;
  }
  .u-flex-2\/6\@tablet {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/6\@tablet {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/6\@tablet {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/6\@tablet {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/6\@tablet {
    margin-right: -33.33333% !important;
  }
  .u-flex-3\/6\@tablet {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/6\@tablet {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/6\@tablet {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/6\@tablet {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/6\@tablet {
    margin-right: -50% !important;
  }
  .u-flex-4\/6\@tablet {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/6\@tablet {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/6\@tablet {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/6\@tablet {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/6\@tablet {
    margin-right: -66.66667% !important;
  }
  .u-flex-5\/6\@tablet {
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/6\@tablet {
    margin-left: 83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/6\@tablet {
    margin-right: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/6\@tablet {
    margin-left: -83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/6\@tablet {
    margin-right: -83.33333% !important;
  }
  .u-flex-6\/6\@tablet {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/6\@tablet {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/6\@tablet {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/6\@tablet {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/6\@tablet {
    margin-right: -100% !important;
  }
  .u-flex-1\/7\@tablet {
    flex: 0 0 14.28571% !important;
    max-width: 14.28571% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/7\@tablet {
    margin-left: 14.28571% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/7\@tablet {
    margin-right: 14.28571% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/7\@tablet {
    margin-left: -14.28571% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/7\@tablet {
    margin-right: -14.28571% !important;
  }
  .u-flex-2\/7\@tablet {
    flex: 0 0 28.57143% !important;
    max-width: 28.57143% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/7\@tablet {
    margin-left: 28.57143% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/7\@tablet {
    margin-right: 28.57143% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/7\@tablet {
    margin-left: -28.57143% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/7\@tablet {
    margin-right: -28.57143% !important;
  }
  .u-flex-3\/7\@tablet {
    flex: 0 0 42.85714% !important;
    max-width: 42.85714% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/7\@tablet {
    margin-left: 42.85714% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/7\@tablet {
    margin-right: 42.85714% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/7\@tablet {
    margin-left: -42.85714% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/7\@tablet {
    margin-right: -42.85714% !important;
  }
  .u-flex-4\/7\@tablet {
    flex: 0 0 57.14286% !important;
    max-width: 57.14286% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/7\@tablet {
    margin-left: 57.14286% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/7\@tablet {
    margin-right: 57.14286% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/7\@tablet {
    margin-left: -57.14286% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/7\@tablet {
    margin-right: -57.14286% !important;
  }
  .u-flex-5\/7\@tablet {
    flex: 0 0 71.42857% !important;
    max-width: 71.42857% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/7\@tablet {
    margin-left: 71.42857% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/7\@tablet {
    margin-right: 71.42857% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/7\@tablet {
    margin-left: -71.42857% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/7\@tablet {
    margin-right: -71.42857% !important;
  }
  .u-flex-6\/7\@tablet {
    flex: 0 0 85.71429% !important;
    max-width: 85.71429% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/7\@tablet {
    margin-left: 85.71429% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/7\@tablet {
    margin-right: 85.71429% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/7\@tablet {
    margin-left: -85.71429% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/7\@tablet {
    margin-right: -85.71429% !important;
  }
  .u-flex-7\/7\@tablet {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/7\@tablet {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/7\@tablet {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/7\@tablet {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/7\@tablet {
    margin-right: -100% !important;
  }
  .u-flex-1\/8\@tablet {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/8\@tablet {
    margin-left: 12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/8\@tablet {
    margin-right: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/8\@tablet {
    margin-left: -12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/8\@tablet {
    margin-right: -12.5% !important;
  }
  .u-flex-2\/8\@tablet {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/8\@tablet {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/8\@tablet {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/8\@tablet {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/8\@tablet {
    margin-right: -25% !important;
  }
  .u-flex-3\/8\@tablet {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/8\@tablet {
    margin-left: 37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/8\@tablet {
    margin-right: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/8\@tablet {
    margin-left: -37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/8\@tablet {
    margin-right: -37.5% !important;
  }
  .u-flex-4\/8\@tablet {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/8\@tablet {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/8\@tablet {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/8\@tablet {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/8\@tablet {
    margin-right: -50% !important;
  }
  .u-flex-5\/8\@tablet {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/8\@tablet {
    margin-left: 62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/8\@tablet {
    margin-right: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/8\@tablet {
    margin-left: -62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/8\@tablet {
    margin-right: -62.5% !important;
  }
  .u-flex-6\/8\@tablet {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/8\@tablet {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/8\@tablet {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/8\@tablet {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/8\@tablet {
    margin-right: -75% !important;
  }
  .u-flex-7\/8\@tablet {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/8\@tablet {
    margin-left: 87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/8\@tablet {
    margin-right: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/8\@tablet {
    margin-left: -87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/8\@tablet {
    margin-right: -87.5% !important;
  }
  .u-flex-8\/8\@tablet {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/8\@tablet {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/8\@tablet {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/8\@tablet {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/8\@tablet {
    margin-right: -100% !important;
  }
  .u-flex-1\/9\@tablet {
    flex: 0 0 11.11111% !important;
    max-width: 11.11111% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/9\@tablet {
    margin-left: 11.11111% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/9\@tablet {
    margin-right: 11.11111% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/9\@tablet {
    margin-left: -11.11111% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/9\@tablet {
    margin-right: -11.11111% !important;
  }
  .u-flex-2\/9\@tablet {
    flex: 0 0 22.22222% !important;
    max-width: 22.22222% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/9\@tablet {
    margin-left: 22.22222% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/9\@tablet {
    margin-right: 22.22222% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/9\@tablet {
    margin-left: -22.22222% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/9\@tablet {
    margin-right: -22.22222% !important;
  }
  .u-flex-3\/9\@tablet {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/9\@tablet {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/9\@tablet {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/9\@tablet {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/9\@tablet {
    margin-right: -33.33333% !important;
  }
  .u-flex-4\/9\@tablet {
    flex: 0 0 44.44444% !important;
    max-width: 44.44444% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/9\@tablet {
    margin-left: 44.44444% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/9\@tablet {
    margin-right: 44.44444% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/9\@tablet {
    margin-left: -44.44444% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/9\@tablet {
    margin-right: -44.44444% !important;
  }
  .u-flex-5\/9\@tablet {
    flex: 0 0 55.55556% !important;
    max-width: 55.55556% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/9\@tablet {
    margin-left: 55.55556% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/9\@tablet {
    margin-right: 55.55556% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/9\@tablet {
    margin-left: -55.55556% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/9\@tablet {
    margin-right: -55.55556% !important;
  }
  .u-flex-6\/9\@tablet {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/9\@tablet {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/9\@tablet {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/9\@tablet {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/9\@tablet {
    margin-right: -66.66667% !important;
  }
  .u-flex-7\/9\@tablet {
    flex: 0 0 77.77778% !important;
    max-width: 77.77778% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/9\@tablet {
    margin-left: 77.77778% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/9\@tablet {
    margin-right: 77.77778% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/9\@tablet {
    margin-left: -77.77778% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/9\@tablet {
    margin-right: -77.77778% !important;
  }
  .u-flex-8\/9\@tablet {
    flex: 0 0 88.88889% !important;
    max-width: 88.88889% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/9\@tablet {
    margin-left: 88.88889% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/9\@tablet {
    margin-right: 88.88889% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/9\@tablet {
    margin-left: -88.88889% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/9\@tablet {
    margin-right: -88.88889% !important;
  }
  .u-flex-9\/9\@tablet {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/9\@tablet {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/9\@tablet {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/9\@tablet {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/9\@tablet {
    margin-right: -100% !important;
  }
  .u-flex-1\/10\@tablet {
    flex: 0 0 10% !important;
    max-width: 10% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/10\@tablet {
    margin-left: 10% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/10\@tablet {
    margin-right: 10% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/10\@tablet {
    margin-left: -10% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/10\@tablet {
    margin-right: -10% !important;
  }
  .u-flex-2\/10\@tablet {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/10\@tablet {
    margin-left: 20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/10\@tablet {
    margin-right: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/10\@tablet {
    margin-left: -20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/10\@tablet {
    margin-right: -20% !important;
  }
  .u-flex-3\/10\@tablet {
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/10\@tablet {
    margin-left: 30% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/10\@tablet {
    margin-right: 30% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/10\@tablet {
    margin-left: -30% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/10\@tablet {
    margin-right: -30% !important;
  }
  .u-flex-4\/10\@tablet {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/10\@tablet {
    margin-left: 40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/10\@tablet {
    margin-right: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/10\@tablet {
    margin-left: -40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/10\@tablet {
    margin-right: -40% !important;
  }
  .u-flex-5\/10\@tablet {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/10\@tablet {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/10\@tablet {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/10\@tablet {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/10\@tablet {
    margin-right: -50% !important;
  }
  .u-flex-6\/10\@tablet {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/10\@tablet {
    margin-left: 60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/10\@tablet {
    margin-right: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/10\@tablet {
    margin-left: -60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/10\@tablet {
    margin-right: -60% !important;
  }
  .u-flex-7\/10\@tablet {
    flex: 0 0 70% !important;
    max-width: 70% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/10\@tablet {
    margin-left: 70% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/10\@tablet {
    margin-right: 70% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/10\@tablet {
    margin-left: -70% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/10\@tablet {
    margin-right: -70% !important;
  }
  .u-flex-8\/10\@tablet {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/10\@tablet {
    margin-left: 80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/10\@tablet {
    margin-right: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/10\@tablet {
    margin-left: -80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/10\@tablet {
    margin-right: -80% !important;
  }
  .u-flex-9\/10\@tablet {
    flex: 0 0 90% !important;
    max-width: 90% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/10\@tablet {
    margin-left: 90% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/10\@tablet {
    margin-right: 90% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/10\@tablet {
    margin-left: -90% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/10\@tablet {
    margin-right: -90% !important;
  }
  .u-flex-10\/10\@tablet {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/10\@tablet {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/10\@tablet {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/10\@tablet {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/10\@tablet {
    margin-right: -100% !important;
  }
  .u-flex-1\/11\@tablet {
    flex: 0 0 9.09091% !important;
    max-width: 9.09091% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/11\@tablet {
    margin-left: 9.09091% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/11\@tablet {
    margin-right: 9.09091% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/11\@tablet {
    margin-left: -9.09091% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/11\@tablet {
    margin-right: -9.09091% !important;
  }
  .u-flex-2\/11\@tablet {
    flex: 0 0 18.18182% !important;
    max-width: 18.18182% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/11\@tablet {
    margin-left: 18.18182% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/11\@tablet {
    margin-right: 18.18182% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/11\@tablet {
    margin-left: -18.18182% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/11\@tablet {
    margin-right: -18.18182% !important;
  }
  .u-flex-3\/11\@tablet {
    flex: 0 0 27.27273% !important;
    max-width: 27.27273% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/11\@tablet {
    margin-left: 27.27273% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/11\@tablet {
    margin-right: 27.27273% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/11\@tablet {
    margin-left: -27.27273% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/11\@tablet {
    margin-right: -27.27273% !important;
  }
  .u-flex-4\/11\@tablet {
    flex: 0 0 36.36364% !important;
    max-width: 36.36364% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/11\@tablet {
    margin-left: 36.36364% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/11\@tablet {
    margin-right: 36.36364% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/11\@tablet {
    margin-left: -36.36364% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/11\@tablet {
    margin-right: -36.36364% !important;
  }
  .u-flex-5\/11\@tablet {
    flex: 0 0 45.45455% !important;
    max-width: 45.45455% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/11\@tablet {
    margin-left: 45.45455% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/11\@tablet {
    margin-right: 45.45455% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/11\@tablet {
    margin-left: -45.45455% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/11\@tablet {
    margin-right: -45.45455% !important;
  }
  .u-flex-6\/11\@tablet {
    flex: 0 0 54.54545% !important;
    max-width: 54.54545% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/11\@tablet {
    margin-left: 54.54545% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/11\@tablet {
    margin-right: 54.54545% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/11\@tablet {
    margin-left: -54.54545% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/11\@tablet {
    margin-right: -54.54545% !important;
  }
  .u-flex-7\/11\@tablet {
    flex: 0 0 63.63636% !important;
    max-width: 63.63636% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/11\@tablet {
    margin-left: 63.63636% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/11\@tablet {
    margin-right: 63.63636% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/11\@tablet {
    margin-left: -63.63636% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/11\@tablet {
    margin-right: -63.63636% !important;
  }
  .u-flex-8\/11\@tablet {
    flex: 0 0 72.72727% !important;
    max-width: 72.72727% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/11\@tablet {
    margin-left: 72.72727% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/11\@tablet {
    margin-right: 72.72727% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/11\@tablet {
    margin-left: -72.72727% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/11\@tablet {
    margin-right: -72.72727% !important;
  }
  .u-flex-9\/11\@tablet {
    flex: 0 0 81.81818% !important;
    max-width: 81.81818% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/11\@tablet {
    margin-left: 81.81818% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/11\@tablet {
    margin-right: 81.81818% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/11\@tablet {
    margin-left: -81.81818% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/11\@tablet {
    margin-right: -81.81818% !important;
  }
  .u-flex-10\/11\@tablet {
    flex: 0 0 90.90909% !important;
    max-width: 90.90909% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/11\@tablet {
    margin-left: 90.90909% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/11\@tablet {
    margin-right: 90.90909% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/11\@tablet {
    margin-left: -90.90909% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/11\@tablet {
    margin-right: -90.90909% !important;
  }
  .u-flex-11\/11\@tablet {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/11\@tablet {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/11\@tablet {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/11\@tablet {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/11\@tablet {
    margin-right: -100% !important;
  }
  .u-flex-1\/12\@tablet {
    flex: 0 0 8.33333% !important;
    max-width: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/12\@tablet {
    margin-left: 8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/12\@tablet {
    margin-right: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/12\@tablet {
    margin-left: -8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/12\@tablet {
    margin-right: -8.33333% !important;
  }
  .u-flex-2\/12\@tablet {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/12\@tablet {
    margin-left: 16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/12\@tablet {
    margin-right: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/12\@tablet {
    margin-left: -16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/12\@tablet {
    margin-right: -16.66667% !important;
  }
  .u-flex-3\/12\@tablet {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/12\@tablet {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/12\@tablet {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/12\@tablet {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/12\@tablet {
    margin-right: -25% !important;
  }
  .u-flex-4\/12\@tablet {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/12\@tablet {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/12\@tablet {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/12\@tablet {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/12\@tablet {
    margin-right: -33.33333% !important;
  }
  .u-flex-5\/12\@tablet {
    flex: 0 0 41.66667% !important;
    max-width: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/12\@tablet {
    margin-left: 41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/12\@tablet {
    margin-right: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/12\@tablet {
    margin-left: -41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/12\@tablet {
    margin-right: -41.66667% !important;
  }
  .u-flex-6\/12\@tablet {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/12\@tablet {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/12\@tablet {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/12\@tablet {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/12\@tablet {
    margin-right: -50% !important;
  }
  .u-flex-7\/12\@tablet {
    flex: 0 0 58.33333% !important;
    max-width: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/12\@tablet {
    margin-left: 58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/12\@tablet {
    margin-right: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/12\@tablet {
    margin-left: -58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/12\@tablet {
    margin-right: -58.33333% !important;
  }
  .u-flex-8\/12\@tablet {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/12\@tablet {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/12\@tablet {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/12\@tablet {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/12\@tablet {
    margin-right: -66.66667% !important;
  }
  .u-flex-9\/12\@tablet {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/12\@tablet {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/12\@tablet {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/12\@tablet {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/12\@tablet {
    margin-right: -75% !important;
  }
  .u-flex-10\/12\@tablet {
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/12\@tablet {
    margin-left: 83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/12\@tablet {
    margin-right: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/12\@tablet {
    margin-left: -83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/12\@tablet {
    margin-right: -83.33333% !important;
  }
  .u-flex-11\/12\@tablet {
    flex: 0 0 91.66667% !important;
    max-width: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/12\@tablet {
    margin-left: 91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/12\@tablet {
    margin-right: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/12\@tablet {
    margin-left: -91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/12\@tablet {
    margin-right: -91.66667% !important;
  }
  .u-flex-12\/12\@tablet {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-12\/12\@tablet {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-12\/12\@tablet {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-12\/12\@tablet {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-12\/12\@tablet {
    margin-right: -100% !important;
  }
  .u-flex-1\/24\@tablet {
    flex: 0 0 4.16667% !important;
    max-width: 4.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/24\@tablet {
    margin-left: 4.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/24\@tablet {
    margin-right: 4.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/24\@tablet {
    margin-left: -4.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/24\@tablet {
    margin-right: -4.16667% !important;
  }
  .u-flex-2\/24\@tablet {
    flex: 0 0 8.33333% !important;
    max-width: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/24\@tablet {
    margin-left: 8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/24\@tablet {
    margin-right: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/24\@tablet {
    margin-left: -8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/24\@tablet {
    margin-right: -8.33333% !important;
  }
  .u-flex-3\/24\@tablet {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/24\@tablet {
    margin-left: 12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/24\@tablet {
    margin-right: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/24\@tablet {
    margin-left: -12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/24\@tablet {
    margin-right: -12.5% !important;
  }
  .u-flex-4\/24\@tablet {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/24\@tablet {
    margin-left: 16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/24\@tablet {
    margin-right: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/24\@tablet {
    margin-left: -16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/24\@tablet {
    margin-right: -16.66667% !important;
  }
  .u-flex-5\/24\@tablet {
    flex: 0 0 20.83333% !important;
    max-width: 20.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/24\@tablet {
    margin-left: 20.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/24\@tablet {
    margin-right: 20.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/24\@tablet {
    margin-left: -20.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/24\@tablet {
    margin-right: -20.83333% !important;
  }
  .u-flex-6\/24\@tablet {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/24\@tablet {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/24\@tablet {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/24\@tablet {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/24\@tablet {
    margin-right: -25% !important;
  }
  .u-flex-7\/24\@tablet {
    flex: 0 0 29.16667% !important;
    max-width: 29.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/24\@tablet {
    margin-left: 29.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/24\@tablet {
    margin-right: 29.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/24\@tablet {
    margin-left: -29.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/24\@tablet {
    margin-right: -29.16667% !important;
  }
  .u-flex-8\/24\@tablet {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/24\@tablet {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/24\@tablet {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/24\@tablet {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/24\@tablet {
    margin-right: -33.33333% !important;
  }
  .u-flex-9\/24\@tablet {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/24\@tablet {
    margin-left: 37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/24\@tablet {
    margin-right: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/24\@tablet {
    margin-left: -37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/24\@tablet {
    margin-right: -37.5% !important;
  }
  .u-flex-10\/24\@tablet {
    flex: 0 0 41.66667% !important;
    max-width: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/24\@tablet {
    margin-left: 41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/24\@tablet {
    margin-right: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/24\@tablet {
    margin-left: -41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/24\@tablet {
    margin-right: -41.66667% !important;
  }
  .u-flex-11\/24\@tablet {
    flex: 0 0 45.83333% !important;
    max-width: 45.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/24\@tablet {
    margin-left: 45.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/24\@tablet {
    margin-right: 45.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/24\@tablet {
    margin-left: -45.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/24\@tablet {
    margin-right: -45.83333% !important;
  }
  .u-flex-12\/24\@tablet {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-12\/24\@tablet {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-12\/24\@tablet {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-12\/24\@tablet {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-12\/24\@tablet {
    margin-right: -50% !important;
  }
  .u-flex-13\/24\@tablet {
    flex: 0 0 54.16667% !important;
    max-width: 54.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-13\/24\@tablet {
    margin-left: 54.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-13\/24\@tablet {
    margin-right: 54.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-13\/24\@tablet {
    margin-left: -54.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-13\/24\@tablet {
    margin-right: -54.16667% !important;
  }
  .u-flex-14\/24\@tablet {
    flex: 0 0 58.33333% !important;
    max-width: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-14\/24\@tablet {
    margin-left: 58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-14\/24\@tablet {
    margin-right: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-14\/24\@tablet {
    margin-left: -58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-14\/24\@tablet {
    margin-right: -58.33333% !important;
  }
  .u-flex-15\/24\@tablet {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-15\/24\@tablet {
    margin-left: 62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-15\/24\@tablet {
    margin-right: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-15\/24\@tablet {
    margin-left: -62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-15\/24\@tablet {
    margin-right: -62.5% !important;
  }
  .u-flex-16\/24\@tablet {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-16\/24\@tablet {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-16\/24\@tablet {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-16\/24\@tablet {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-16\/24\@tablet {
    margin-right: -66.66667% !important;
  }
  .u-flex-17\/24\@tablet {
    flex: 0 0 70.83333% !important;
    max-width: 70.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-17\/24\@tablet {
    margin-left: 70.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-17\/24\@tablet {
    margin-right: 70.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-17\/24\@tablet {
    margin-left: -70.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-17\/24\@tablet {
    margin-right: -70.83333% !important;
  }
  .u-flex-18\/24\@tablet {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-18\/24\@tablet {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-18\/24\@tablet {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-18\/24\@tablet {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-18\/24\@tablet {
    margin-right: -75% !important;
  }
  .u-flex-19\/24\@tablet {
    flex: 0 0 79.16667% !important;
    max-width: 79.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-19\/24\@tablet {
    margin-left: 79.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-19\/24\@tablet {
    margin-right: 79.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-19\/24\@tablet {
    margin-left: -79.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-19\/24\@tablet {
    margin-right: -79.16667% !important;
  }
  .u-flex-20\/24\@tablet {
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-20\/24\@tablet {
    margin-left: 83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-20\/24\@tablet {
    margin-right: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-20\/24\@tablet {
    margin-left: -83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-20\/24\@tablet {
    margin-right: -83.33333% !important;
  }
  .u-flex-21\/24\@tablet {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-21\/24\@tablet {
    margin-left: 87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-21\/24\@tablet {
    margin-right: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-21\/24\@tablet {
    margin-left: -87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-21\/24\@tablet {
    margin-right: -87.5% !important;
  }
  .u-flex-22\/24\@tablet {
    flex: 0 0 91.66667% !important;
    max-width: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-22\/24\@tablet {
    margin-left: 91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-22\/24\@tablet {
    margin-right: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-22\/24\@tablet {
    margin-left: -91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-22\/24\@tablet {
    margin-right: -91.66667% !important;
  }
  .u-flex-23\/24\@tablet {
    flex: 0 0 95.83333% !important;
    max-width: 95.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-23\/24\@tablet {
    margin-left: 95.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-23\/24\@tablet {
    margin-right: 95.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-23\/24\@tablet {
    margin-left: -95.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-23\/24\@tablet {
    margin-right: -95.83333% !important;
  }
  .u-flex-24\/24\@tablet {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-24\/24\@tablet {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-24\/24\@tablet {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-24\/24\@tablet {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-24\/24\@tablet {
    margin-right: -100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-none\@tablet {
    margin-left: 0 !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-none\@tablet {
    margin-right: 0 !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-none\@tablet {
    margin-left: 0 !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-none\@tablet {
    margin-right: 0 !important;
  }
}

@media (min-width: 61.25em) {
  .u-flex-1\/1\@desktop {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/1\@desktop {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/1\@desktop {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/1\@desktop {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/1\@desktop {
    margin-right: -100% !important;
  }
  .u-flex-1\/2\@desktop {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/2\@desktop {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/2\@desktop {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/2\@desktop {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/2\@desktop {
    margin-right: -50% !important;
  }
  .u-flex-2\/2\@desktop {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/2\@desktop {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/2\@desktop {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/2\@desktop {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/2\@desktop {
    margin-right: -100% !important;
  }
  .u-flex-1\/3\@desktop {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/3\@desktop {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/3\@desktop {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/3\@desktop {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/3\@desktop {
    margin-right: -33.33333% !important;
  }
  .u-flex-2\/3\@desktop {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/3\@desktop {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/3\@desktop {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/3\@desktop {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/3\@desktop {
    margin-right: -66.66667% !important;
  }
  .u-flex-3\/3\@desktop {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/3\@desktop {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/3\@desktop {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/3\@desktop {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/3\@desktop {
    margin-right: -100% !important;
  }
  .u-flex-1\/4\@desktop {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/4\@desktop {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/4\@desktop {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/4\@desktop {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/4\@desktop {
    margin-right: -25% !important;
  }
  .u-flex-2\/4\@desktop {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/4\@desktop {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/4\@desktop {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/4\@desktop {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/4\@desktop {
    margin-right: -50% !important;
  }
  .u-flex-3\/4\@desktop {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/4\@desktop {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/4\@desktop {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/4\@desktop {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/4\@desktop {
    margin-right: -75% !important;
  }
  .u-flex-4\/4\@desktop {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/4\@desktop {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/4\@desktop {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/4\@desktop {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/4\@desktop {
    margin-right: -100% !important;
  }
  .u-flex-1\/5\@desktop {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/5\@desktop {
    margin-left: 20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/5\@desktop {
    margin-right: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/5\@desktop {
    margin-left: -20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/5\@desktop {
    margin-right: -20% !important;
  }
  .u-flex-2\/5\@desktop {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/5\@desktop {
    margin-left: 40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/5\@desktop {
    margin-right: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/5\@desktop {
    margin-left: -40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/5\@desktop {
    margin-right: -40% !important;
  }
  .u-flex-3\/5\@desktop {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/5\@desktop {
    margin-left: 60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/5\@desktop {
    margin-right: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/5\@desktop {
    margin-left: -60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/5\@desktop {
    margin-right: -60% !important;
  }
  .u-flex-4\/5\@desktop {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/5\@desktop {
    margin-left: 80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/5\@desktop {
    margin-right: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/5\@desktop {
    margin-left: -80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/5\@desktop {
    margin-right: -80% !important;
  }
  .u-flex-5\/5\@desktop {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/5\@desktop {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/5\@desktop {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/5\@desktop {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/5\@desktop {
    margin-right: -100% !important;
  }
  .u-flex-1\/6\@desktop {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/6\@desktop {
    margin-left: 16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/6\@desktop {
    margin-right: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/6\@desktop {
    margin-left: -16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/6\@desktop {
    margin-right: -16.66667% !important;
  }
  .u-flex-2\/6\@desktop {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/6\@desktop {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/6\@desktop {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/6\@desktop {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/6\@desktop {
    margin-right: -33.33333% !important;
  }
  .u-flex-3\/6\@desktop {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/6\@desktop {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/6\@desktop {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/6\@desktop {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/6\@desktop {
    margin-right: -50% !important;
  }
  .u-flex-4\/6\@desktop {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/6\@desktop {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/6\@desktop {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/6\@desktop {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/6\@desktop {
    margin-right: -66.66667% !important;
  }
  .u-flex-5\/6\@desktop {
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/6\@desktop {
    margin-left: 83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/6\@desktop {
    margin-right: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/6\@desktop {
    margin-left: -83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/6\@desktop {
    margin-right: -83.33333% !important;
  }
  .u-flex-6\/6\@desktop {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/6\@desktop {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/6\@desktop {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/6\@desktop {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/6\@desktop {
    margin-right: -100% !important;
  }
  .u-flex-1\/7\@desktop {
    flex: 0 0 14.28571% !important;
    max-width: 14.28571% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/7\@desktop {
    margin-left: 14.28571% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/7\@desktop {
    margin-right: 14.28571% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/7\@desktop {
    margin-left: -14.28571% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/7\@desktop {
    margin-right: -14.28571% !important;
  }
  .u-flex-2\/7\@desktop {
    flex: 0 0 28.57143% !important;
    max-width: 28.57143% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/7\@desktop {
    margin-left: 28.57143% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/7\@desktop {
    margin-right: 28.57143% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/7\@desktop {
    margin-left: -28.57143% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/7\@desktop {
    margin-right: -28.57143% !important;
  }
  .u-flex-3\/7\@desktop {
    flex: 0 0 42.85714% !important;
    max-width: 42.85714% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/7\@desktop {
    margin-left: 42.85714% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/7\@desktop {
    margin-right: 42.85714% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/7\@desktop {
    margin-left: -42.85714% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/7\@desktop {
    margin-right: -42.85714% !important;
  }
  .u-flex-4\/7\@desktop {
    flex: 0 0 57.14286% !important;
    max-width: 57.14286% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/7\@desktop {
    margin-left: 57.14286% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/7\@desktop {
    margin-right: 57.14286% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/7\@desktop {
    margin-left: -57.14286% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/7\@desktop {
    margin-right: -57.14286% !important;
  }
  .u-flex-5\/7\@desktop {
    flex: 0 0 71.42857% !important;
    max-width: 71.42857% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/7\@desktop {
    margin-left: 71.42857% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/7\@desktop {
    margin-right: 71.42857% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/7\@desktop {
    margin-left: -71.42857% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/7\@desktop {
    margin-right: -71.42857% !important;
  }
  .u-flex-6\/7\@desktop {
    flex: 0 0 85.71429% !important;
    max-width: 85.71429% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/7\@desktop {
    margin-left: 85.71429% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/7\@desktop {
    margin-right: 85.71429% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/7\@desktop {
    margin-left: -85.71429% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/7\@desktop {
    margin-right: -85.71429% !important;
  }
  .u-flex-7\/7\@desktop {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/7\@desktop {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/7\@desktop {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/7\@desktop {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/7\@desktop {
    margin-right: -100% !important;
  }
  .u-flex-1\/8\@desktop {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/8\@desktop {
    margin-left: 12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/8\@desktop {
    margin-right: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/8\@desktop {
    margin-left: -12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/8\@desktop {
    margin-right: -12.5% !important;
  }
  .u-flex-2\/8\@desktop {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/8\@desktop {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/8\@desktop {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/8\@desktop {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/8\@desktop {
    margin-right: -25% !important;
  }
  .u-flex-3\/8\@desktop {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/8\@desktop {
    margin-left: 37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/8\@desktop {
    margin-right: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/8\@desktop {
    margin-left: -37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/8\@desktop {
    margin-right: -37.5% !important;
  }
  .u-flex-4\/8\@desktop {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/8\@desktop {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/8\@desktop {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/8\@desktop {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/8\@desktop {
    margin-right: -50% !important;
  }
  .u-flex-5\/8\@desktop {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/8\@desktop {
    margin-left: 62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/8\@desktop {
    margin-right: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/8\@desktop {
    margin-left: -62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/8\@desktop {
    margin-right: -62.5% !important;
  }
  .u-flex-6\/8\@desktop {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/8\@desktop {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/8\@desktop {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/8\@desktop {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/8\@desktop {
    margin-right: -75% !important;
  }
  .u-flex-7\/8\@desktop {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/8\@desktop {
    margin-left: 87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/8\@desktop {
    margin-right: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/8\@desktop {
    margin-left: -87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/8\@desktop {
    margin-right: -87.5% !important;
  }
  .u-flex-8\/8\@desktop {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/8\@desktop {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/8\@desktop {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/8\@desktop {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/8\@desktop {
    margin-right: -100% !important;
  }
  .u-flex-1\/9\@desktop {
    flex: 0 0 11.11111% !important;
    max-width: 11.11111% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/9\@desktop {
    margin-left: 11.11111% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/9\@desktop {
    margin-right: 11.11111% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/9\@desktop {
    margin-left: -11.11111% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/9\@desktop {
    margin-right: -11.11111% !important;
  }
  .u-flex-2\/9\@desktop {
    flex: 0 0 22.22222% !important;
    max-width: 22.22222% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/9\@desktop {
    margin-left: 22.22222% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/9\@desktop {
    margin-right: 22.22222% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/9\@desktop {
    margin-left: -22.22222% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/9\@desktop {
    margin-right: -22.22222% !important;
  }
  .u-flex-3\/9\@desktop {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/9\@desktop {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/9\@desktop {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/9\@desktop {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/9\@desktop {
    margin-right: -33.33333% !important;
  }
  .u-flex-4\/9\@desktop {
    flex: 0 0 44.44444% !important;
    max-width: 44.44444% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/9\@desktop {
    margin-left: 44.44444% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/9\@desktop {
    margin-right: 44.44444% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/9\@desktop {
    margin-left: -44.44444% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/9\@desktop {
    margin-right: -44.44444% !important;
  }
  .u-flex-5\/9\@desktop {
    flex: 0 0 55.55556% !important;
    max-width: 55.55556% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/9\@desktop {
    margin-left: 55.55556% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/9\@desktop {
    margin-right: 55.55556% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/9\@desktop {
    margin-left: -55.55556% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/9\@desktop {
    margin-right: -55.55556% !important;
  }
  .u-flex-6\/9\@desktop {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/9\@desktop {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/9\@desktop {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/9\@desktop {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/9\@desktop {
    margin-right: -66.66667% !important;
  }
  .u-flex-7\/9\@desktop {
    flex: 0 0 77.77778% !important;
    max-width: 77.77778% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/9\@desktop {
    margin-left: 77.77778% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/9\@desktop {
    margin-right: 77.77778% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/9\@desktop {
    margin-left: -77.77778% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/9\@desktop {
    margin-right: -77.77778% !important;
  }
  .u-flex-8\/9\@desktop {
    flex: 0 0 88.88889% !important;
    max-width: 88.88889% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/9\@desktop {
    margin-left: 88.88889% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/9\@desktop {
    margin-right: 88.88889% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/9\@desktop {
    margin-left: -88.88889% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/9\@desktop {
    margin-right: -88.88889% !important;
  }
  .u-flex-9\/9\@desktop {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/9\@desktop {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/9\@desktop {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/9\@desktop {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/9\@desktop {
    margin-right: -100% !important;
  }
  .u-flex-1\/10\@desktop {
    flex: 0 0 10% !important;
    max-width: 10% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/10\@desktop {
    margin-left: 10% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/10\@desktop {
    margin-right: 10% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/10\@desktop {
    margin-left: -10% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/10\@desktop {
    margin-right: -10% !important;
  }
  .u-flex-2\/10\@desktop {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/10\@desktop {
    margin-left: 20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/10\@desktop {
    margin-right: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/10\@desktop {
    margin-left: -20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/10\@desktop {
    margin-right: -20% !important;
  }
  .u-flex-3\/10\@desktop {
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/10\@desktop {
    margin-left: 30% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/10\@desktop {
    margin-right: 30% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/10\@desktop {
    margin-left: -30% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/10\@desktop {
    margin-right: -30% !important;
  }
  .u-flex-4\/10\@desktop {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/10\@desktop {
    margin-left: 40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/10\@desktop {
    margin-right: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/10\@desktop {
    margin-left: -40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/10\@desktop {
    margin-right: -40% !important;
  }
  .u-flex-5\/10\@desktop {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/10\@desktop {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/10\@desktop {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/10\@desktop {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/10\@desktop {
    margin-right: -50% !important;
  }
  .u-flex-6\/10\@desktop {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/10\@desktop {
    margin-left: 60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/10\@desktop {
    margin-right: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/10\@desktop {
    margin-left: -60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/10\@desktop {
    margin-right: -60% !important;
  }
  .u-flex-7\/10\@desktop {
    flex: 0 0 70% !important;
    max-width: 70% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/10\@desktop {
    margin-left: 70% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/10\@desktop {
    margin-right: 70% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/10\@desktop {
    margin-left: -70% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/10\@desktop {
    margin-right: -70% !important;
  }
  .u-flex-8\/10\@desktop {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/10\@desktop {
    margin-left: 80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/10\@desktop {
    margin-right: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/10\@desktop {
    margin-left: -80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/10\@desktop {
    margin-right: -80% !important;
  }
  .u-flex-9\/10\@desktop {
    flex: 0 0 90% !important;
    max-width: 90% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/10\@desktop {
    margin-left: 90% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/10\@desktop {
    margin-right: 90% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/10\@desktop {
    margin-left: -90% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/10\@desktop {
    margin-right: -90% !important;
  }
  .u-flex-10\/10\@desktop {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/10\@desktop {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/10\@desktop {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/10\@desktop {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/10\@desktop {
    margin-right: -100% !important;
  }
  .u-flex-1\/11\@desktop {
    flex: 0 0 9.09091% !important;
    max-width: 9.09091% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/11\@desktop {
    margin-left: 9.09091% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/11\@desktop {
    margin-right: 9.09091% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/11\@desktop {
    margin-left: -9.09091% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/11\@desktop {
    margin-right: -9.09091% !important;
  }
  .u-flex-2\/11\@desktop {
    flex: 0 0 18.18182% !important;
    max-width: 18.18182% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/11\@desktop {
    margin-left: 18.18182% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/11\@desktop {
    margin-right: 18.18182% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/11\@desktop {
    margin-left: -18.18182% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/11\@desktop {
    margin-right: -18.18182% !important;
  }
  .u-flex-3\/11\@desktop {
    flex: 0 0 27.27273% !important;
    max-width: 27.27273% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/11\@desktop {
    margin-left: 27.27273% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/11\@desktop {
    margin-right: 27.27273% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/11\@desktop {
    margin-left: -27.27273% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/11\@desktop {
    margin-right: -27.27273% !important;
  }
  .u-flex-4\/11\@desktop {
    flex: 0 0 36.36364% !important;
    max-width: 36.36364% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/11\@desktop {
    margin-left: 36.36364% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/11\@desktop {
    margin-right: 36.36364% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/11\@desktop {
    margin-left: -36.36364% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/11\@desktop {
    margin-right: -36.36364% !important;
  }
  .u-flex-5\/11\@desktop {
    flex: 0 0 45.45455% !important;
    max-width: 45.45455% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/11\@desktop {
    margin-left: 45.45455% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/11\@desktop {
    margin-right: 45.45455% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/11\@desktop {
    margin-left: -45.45455% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/11\@desktop {
    margin-right: -45.45455% !important;
  }
  .u-flex-6\/11\@desktop {
    flex: 0 0 54.54545% !important;
    max-width: 54.54545% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/11\@desktop {
    margin-left: 54.54545% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/11\@desktop {
    margin-right: 54.54545% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/11\@desktop {
    margin-left: -54.54545% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/11\@desktop {
    margin-right: -54.54545% !important;
  }
  .u-flex-7\/11\@desktop {
    flex: 0 0 63.63636% !important;
    max-width: 63.63636% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/11\@desktop {
    margin-left: 63.63636% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/11\@desktop {
    margin-right: 63.63636% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/11\@desktop {
    margin-left: -63.63636% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/11\@desktop {
    margin-right: -63.63636% !important;
  }
  .u-flex-8\/11\@desktop {
    flex: 0 0 72.72727% !important;
    max-width: 72.72727% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/11\@desktop {
    margin-left: 72.72727% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/11\@desktop {
    margin-right: 72.72727% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/11\@desktop {
    margin-left: -72.72727% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/11\@desktop {
    margin-right: -72.72727% !important;
  }
  .u-flex-9\/11\@desktop {
    flex: 0 0 81.81818% !important;
    max-width: 81.81818% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/11\@desktop {
    margin-left: 81.81818% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/11\@desktop {
    margin-right: 81.81818% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/11\@desktop {
    margin-left: -81.81818% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/11\@desktop {
    margin-right: -81.81818% !important;
  }
  .u-flex-10\/11\@desktop {
    flex: 0 0 90.90909% !important;
    max-width: 90.90909% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/11\@desktop {
    margin-left: 90.90909% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/11\@desktop {
    margin-right: 90.90909% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/11\@desktop {
    margin-left: -90.90909% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/11\@desktop {
    margin-right: -90.90909% !important;
  }
  .u-flex-11\/11\@desktop {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/11\@desktop {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/11\@desktop {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/11\@desktop {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/11\@desktop {
    margin-right: -100% !important;
  }
  .u-flex-1\/12\@desktop {
    flex: 0 0 8.33333% !important;
    max-width: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/12\@desktop {
    margin-left: 8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/12\@desktop {
    margin-right: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/12\@desktop {
    margin-left: -8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/12\@desktop {
    margin-right: -8.33333% !important;
  }
  .u-flex-2\/12\@desktop {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/12\@desktop {
    margin-left: 16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/12\@desktop {
    margin-right: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/12\@desktop {
    margin-left: -16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/12\@desktop {
    margin-right: -16.66667% !important;
  }
  .u-flex-3\/12\@desktop {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/12\@desktop {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/12\@desktop {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/12\@desktop {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/12\@desktop {
    margin-right: -25% !important;
  }
  .u-flex-4\/12\@desktop {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/12\@desktop {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/12\@desktop {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/12\@desktop {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/12\@desktop {
    margin-right: -33.33333% !important;
  }
  .u-flex-5\/12\@desktop {
    flex: 0 0 41.66667% !important;
    max-width: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/12\@desktop {
    margin-left: 41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/12\@desktop {
    margin-right: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/12\@desktop {
    margin-left: -41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/12\@desktop {
    margin-right: -41.66667% !important;
  }
  .u-flex-6\/12\@desktop {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/12\@desktop {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/12\@desktop {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/12\@desktop {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/12\@desktop {
    margin-right: -50% !important;
  }
  .u-flex-7\/12\@desktop {
    flex: 0 0 58.33333% !important;
    max-width: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/12\@desktop {
    margin-left: 58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/12\@desktop {
    margin-right: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/12\@desktop {
    margin-left: -58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/12\@desktop {
    margin-right: -58.33333% !important;
  }
  .u-flex-8\/12\@desktop {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/12\@desktop {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/12\@desktop {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/12\@desktop {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/12\@desktop {
    margin-right: -66.66667% !important;
  }
  .u-flex-9\/12\@desktop {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/12\@desktop {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/12\@desktop {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/12\@desktop {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/12\@desktop {
    margin-right: -75% !important;
  }
  .u-flex-10\/12\@desktop {
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/12\@desktop {
    margin-left: 83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/12\@desktop {
    margin-right: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/12\@desktop {
    margin-left: -83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/12\@desktop {
    margin-right: -83.33333% !important;
  }
  .u-flex-11\/12\@desktop {
    flex: 0 0 91.66667% !important;
    max-width: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/12\@desktop {
    margin-left: 91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/12\@desktop {
    margin-right: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/12\@desktop {
    margin-left: -91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/12\@desktop {
    margin-right: -91.66667% !important;
  }
  .u-flex-12\/12\@desktop {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-12\/12\@desktop {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-12\/12\@desktop {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-12\/12\@desktop {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-12\/12\@desktop {
    margin-right: -100% !important;
  }
  .u-flex-1\/24\@desktop {
    flex: 0 0 4.16667% !important;
    max-width: 4.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/24\@desktop {
    margin-left: 4.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/24\@desktop {
    margin-right: 4.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/24\@desktop {
    margin-left: -4.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/24\@desktop {
    margin-right: -4.16667% !important;
  }
  .u-flex-2\/24\@desktop {
    flex: 0 0 8.33333% !important;
    max-width: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/24\@desktop {
    margin-left: 8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/24\@desktop {
    margin-right: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/24\@desktop {
    margin-left: -8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/24\@desktop {
    margin-right: -8.33333% !important;
  }
  .u-flex-3\/24\@desktop {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/24\@desktop {
    margin-left: 12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/24\@desktop {
    margin-right: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/24\@desktop {
    margin-left: -12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/24\@desktop {
    margin-right: -12.5% !important;
  }
  .u-flex-4\/24\@desktop {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/24\@desktop {
    margin-left: 16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/24\@desktop {
    margin-right: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/24\@desktop {
    margin-left: -16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/24\@desktop {
    margin-right: -16.66667% !important;
  }
  .u-flex-5\/24\@desktop {
    flex: 0 0 20.83333% !important;
    max-width: 20.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/24\@desktop {
    margin-left: 20.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/24\@desktop {
    margin-right: 20.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/24\@desktop {
    margin-left: -20.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/24\@desktop {
    margin-right: -20.83333% !important;
  }
  .u-flex-6\/24\@desktop {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/24\@desktop {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/24\@desktop {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/24\@desktop {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/24\@desktop {
    margin-right: -25% !important;
  }
  .u-flex-7\/24\@desktop {
    flex: 0 0 29.16667% !important;
    max-width: 29.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/24\@desktop {
    margin-left: 29.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/24\@desktop {
    margin-right: 29.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/24\@desktop {
    margin-left: -29.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/24\@desktop {
    margin-right: -29.16667% !important;
  }
  .u-flex-8\/24\@desktop {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/24\@desktop {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/24\@desktop {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/24\@desktop {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/24\@desktop {
    margin-right: -33.33333% !important;
  }
  .u-flex-9\/24\@desktop {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/24\@desktop {
    margin-left: 37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/24\@desktop {
    margin-right: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/24\@desktop {
    margin-left: -37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/24\@desktop {
    margin-right: -37.5% !important;
  }
  .u-flex-10\/24\@desktop {
    flex: 0 0 41.66667% !important;
    max-width: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/24\@desktop {
    margin-left: 41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/24\@desktop {
    margin-right: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/24\@desktop {
    margin-left: -41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/24\@desktop {
    margin-right: -41.66667% !important;
  }
  .u-flex-11\/24\@desktop {
    flex: 0 0 45.83333% !important;
    max-width: 45.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/24\@desktop {
    margin-left: 45.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/24\@desktop {
    margin-right: 45.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/24\@desktop {
    margin-left: -45.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/24\@desktop {
    margin-right: -45.83333% !important;
  }
  .u-flex-12\/24\@desktop {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-12\/24\@desktop {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-12\/24\@desktop {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-12\/24\@desktop {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-12\/24\@desktop {
    margin-right: -50% !important;
  }
  .u-flex-13\/24\@desktop {
    flex: 0 0 54.16667% !important;
    max-width: 54.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-13\/24\@desktop {
    margin-left: 54.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-13\/24\@desktop {
    margin-right: 54.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-13\/24\@desktop {
    margin-left: -54.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-13\/24\@desktop {
    margin-right: -54.16667% !important;
  }
  .u-flex-14\/24\@desktop {
    flex: 0 0 58.33333% !important;
    max-width: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-14\/24\@desktop {
    margin-left: 58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-14\/24\@desktop {
    margin-right: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-14\/24\@desktop {
    margin-left: -58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-14\/24\@desktop {
    margin-right: -58.33333% !important;
  }
  .u-flex-15\/24\@desktop {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-15\/24\@desktop {
    margin-left: 62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-15\/24\@desktop {
    margin-right: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-15\/24\@desktop {
    margin-left: -62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-15\/24\@desktop {
    margin-right: -62.5% !important;
  }
  .u-flex-16\/24\@desktop {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-16\/24\@desktop {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-16\/24\@desktop {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-16\/24\@desktop {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-16\/24\@desktop {
    margin-right: -66.66667% !important;
  }
  .u-flex-17\/24\@desktop {
    flex: 0 0 70.83333% !important;
    max-width: 70.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-17\/24\@desktop {
    margin-left: 70.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-17\/24\@desktop {
    margin-right: 70.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-17\/24\@desktop {
    margin-left: -70.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-17\/24\@desktop {
    margin-right: -70.83333% !important;
  }
  .u-flex-18\/24\@desktop {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-18\/24\@desktop {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-18\/24\@desktop {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-18\/24\@desktop {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-18\/24\@desktop {
    margin-right: -75% !important;
  }
  .u-flex-19\/24\@desktop {
    flex: 0 0 79.16667% !important;
    max-width: 79.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-19\/24\@desktop {
    margin-left: 79.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-19\/24\@desktop {
    margin-right: 79.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-19\/24\@desktop {
    margin-left: -79.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-19\/24\@desktop {
    margin-right: -79.16667% !important;
  }
  .u-flex-20\/24\@desktop {
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-20\/24\@desktop {
    margin-left: 83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-20\/24\@desktop {
    margin-right: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-20\/24\@desktop {
    margin-left: -83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-20\/24\@desktop {
    margin-right: -83.33333% !important;
  }
  .u-flex-21\/24\@desktop {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-21\/24\@desktop {
    margin-left: 87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-21\/24\@desktop {
    margin-right: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-21\/24\@desktop {
    margin-left: -87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-21\/24\@desktop {
    margin-right: -87.5% !important;
  }
  .u-flex-22\/24\@desktop {
    flex: 0 0 91.66667% !important;
    max-width: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-22\/24\@desktop {
    margin-left: 91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-22\/24\@desktop {
    margin-right: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-22\/24\@desktop {
    margin-left: -91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-22\/24\@desktop {
    margin-right: -91.66667% !important;
  }
  .u-flex-23\/24\@desktop {
    flex: 0 0 95.83333% !important;
    max-width: 95.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-23\/24\@desktop {
    margin-left: 95.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-23\/24\@desktop {
    margin-right: 95.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-23\/24\@desktop {
    margin-left: -95.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-23\/24\@desktop {
    margin-right: -95.83333% !important;
  }
  .u-flex-24\/24\@desktop {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-24\/24\@desktop {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-24\/24\@desktop {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-24\/24\@desktop {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-24\/24\@desktop {
    margin-right: -100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-none\@desktop {
    margin-left: 0 !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-none\@desktop {
    margin-right: 0 !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-none\@desktop {
    margin-left: 0 !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-none\@desktop {
    margin-right: 0 !important;
  }
}

@media (min-width: 81.25em) {
  .u-flex-1\/1\@wide {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/1\@wide {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/1\@wide {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/1\@wide {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/1\@wide {
    margin-right: -100% !important;
  }
  .u-flex-1\/2\@wide {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/2\@wide {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/2\@wide {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/2\@wide {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/2\@wide {
    margin-right: -50% !important;
  }
  .u-flex-2\/2\@wide {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/2\@wide {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/2\@wide {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/2\@wide {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/2\@wide {
    margin-right: -100% !important;
  }
  .u-flex-1\/3\@wide {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/3\@wide {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/3\@wide {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/3\@wide {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/3\@wide {
    margin-right: -33.33333% !important;
  }
  .u-flex-2\/3\@wide {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/3\@wide {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/3\@wide {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/3\@wide {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/3\@wide {
    margin-right: -66.66667% !important;
  }
  .u-flex-3\/3\@wide {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/3\@wide {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/3\@wide {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/3\@wide {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/3\@wide {
    margin-right: -100% !important;
  }
  .u-flex-1\/4\@wide {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/4\@wide {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/4\@wide {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/4\@wide {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/4\@wide {
    margin-right: -25% !important;
  }
  .u-flex-2\/4\@wide {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/4\@wide {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/4\@wide {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/4\@wide {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/4\@wide {
    margin-right: -50% !important;
  }
  .u-flex-3\/4\@wide {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/4\@wide {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/4\@wide {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/4\@wide {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/4\@wide {
    margin-right: -75% !important;
  }
  .u-flex-4\/4\@wide {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/4\@wide {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/4\@wide {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/4\@wide {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/4\@wide {
    margin-right: -100% !important;
  }
  .u-flex-1\/5\@wide {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/5\@wide {
    margin-left: 20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/5\@wide {
    margin-right: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/5\@wide {
    margin-left: -20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/5\@wide {
    margin-right: -20% !important;
  }
  .u-flex-2\/5\@wide {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/5\@wide {
    margin-left: 40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/5\@wide {
    margin-right: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/5\@wide {
    margin-left: -40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/5\@wide {
    margin-right: -40% !important;
  }
  .u-flex-3\/5\@wide {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/5\@wide {
    margin-left: 60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/5\@wide {
    margin-right: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/5\@wide {
    margin-left: -60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/5\@wide {
    margin-right: -60% !important;
  }
  .u-flex-4\/5\@wide {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/5\@wide {
    margin-left: 80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/5\@wide {
    margin-right: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/5\@wide {
    margin-left: -80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/5\@wide {
    margin-right: -80% !important;
  }
  .u-flex-5\/5\@wide {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/5\@wide {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/5\@wide {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/5\@wide {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/5\@wide {
    margin-right: -100% !important;
  }
  .u-flex-1\/6\@wide {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/6\@wide {
    margin-left: 16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/6\@wide {
    margin-right: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/6\@wide {
    margin-left: -16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/6\@wide {
    margin-right: -16.66667% !important;
  }
  .u-flex-2\/6\@wide {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/6\@wide {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/6\@wide {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/6\@wide {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/6\@wide {
    margin-right: -33.33333% !important;
  }
  .u-flex-3\/6\@wide {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/6\@wide {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/6\@wide {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/6\@wide {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/6\@wide {
    margin-right: -50% !important;
  }
  .u-flex-4\/6\@wide {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/6\@wide {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/6\@wide {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/6\@wide {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/6\@wide {
    margin-right: -66.66667% !important;
  }
  .u-flex-5\/6\@wide {
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/6\@wide {
    margin-left: 83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/6\@wide {
    margin-right: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/6\@wide {
    margin-left: -83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/6\@wide {
    margin-right: -83.33333% !important;
  }
  .u-flex-6\/6\@wide {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/6\@wide {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/6\@wide {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/6\@wide {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/6\@wide {
    margin-right: -100% !important;
  }
  .u-flex-1\/7\@wide {
    flex: 0 0 14.28571% !important;
    max-width: 14.28571% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/7\@wide {
    margin-left: 14.28571% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/7\@wide {
    margin-right: 14.28571% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/7\@wide {
    margin-left: -14.28571% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/7\@wide {
    margin-right: -14.28571% !important;
  }
  .u-flex-2\/7\@wide {
    flex: 0 0 28.57143% !important;
    max-width: 28.57143% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/7\@wide {
    margin-left: 28.57143% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/7\@wide {
    margin-right: 28.57143% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/7\@wide {
    margin-left: -28.57143% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/7\@wide {
    margin-right: -28.57143% !important;
  }
  .u-flex-3\/7\@wide {
    flex: 0 0 42.85714% !important;
    max-width: 42.85714% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/7\@wide {
    margin-left: 42.85714% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/7\@wide {
    margin-right: 42.85714% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/7\@wide {
    margin-left: -42.85714% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/7\@wide {
    margin-right: -42.85714% !important;
  }
  .u-flex-4\/7\@wide {
    flex: 0 0 57.14286% !important;
    max-width: 57.14286% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/7\@wide {
    margin-left: 57.14286% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/7\@wide {
    margin-right: 57.14286% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/7\@wide {
    margin-left: -57.14286% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/7\@wide {
    margin-right: -57.14286% !important;
  }
  .u-flex-5\/7\@wide {
    flex: 0 0 71.42857% !important;
    max-width: 71.42857% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/7\@wide {
    margin-left: 71.42857% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/7\@wide {
    margin-right: 71.42857% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/7\@wide {
    margin-left: -71.42857% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/7\@wide {
    margin-right: -71.42857% !important;
  }
  .u-flex-6\/7\@wide {
    flex: 0 0 85.71429% !important;
    max-width: 85.71429% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/7\@wide {
    margin-left: 85.71429% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/7\@wide {
    margin-right: 85.71429% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/7\@wide {
    margin-left: -85.71429% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/7\@wide {
    margin-right: -85.71429% !important;
  }
  .u-flex-7\/7\@wide {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/7\@wide {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/7\@wide {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/7\@wide {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/7\@wide {
    margin-right: -100% !important;
  }
  .u-flex-1\/8\@wide {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/8\@wide {
    margin-left: 12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/8\@wide {
    margin-right: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/8\@wide {
    margin-left: -12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/8\@wide {
    margin-right: -12.5% !important;
  }
  .u-flex-2\/8\@wide {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/8\@wide {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/8\@wide {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/8\@wide {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/8\@wide {
    margin-right: -25% !important;
  }
  .u-flex-3\/8\@wide {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/8\@wide {
    margin-left: 37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/8\@wide {
    margin-right: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/8\@wide {
    margin-left: -37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/8\@wide {
    margin-right: -37.5% !important;
  }
  .u-flex-4\/8\@wide {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/8\@wide {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/8\@wide {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/8\@wide {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/8\@wide {
    margin-right: -50% !important;
  }
  .u-flex-5\/8\@wide {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/8\@wide {
    margin-left: 62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/8\@wide {
    margin-right: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/8\@wide {
    margin-left: -62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/8\@wide {
    margin-right: -62.5% !important;
  }
  .u-flex-6\/8\@wide {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/8\@wide {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/8\@wide {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/8\@wide {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/8\@wide {
    margin-right: -75% !important;
  }
  .u-flex-7\/8\@wide {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/8\@wide {
    margin-left: 87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/8\@wide {
    margin-right: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/8\@wide {
    margin-left: -87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/8\@wide {
    margin-right: -87.5% !important;
  }
  .u-flex-8\/8\@wide {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/8\@wide {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/8\@wide {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/8\@wide {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/8\@wide {
    margin-right: -100% !important;
  }
  .u-flex-1\/9\@wide {
    flex: 0 0 11.11111% !important;
    max-width: 11.11111% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/9\@wide {
    margin-left: 11.11111% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/9\@wide {
    margin-right: 11.11111% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/9\@wide {
    margin-left: -11.11111% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/9\@wide {
    margin-right: -11.11111% !important;
  }
  .u-flex-2\/9\@wide {
    flex: 0 0 22.22222% !important;
    max-width: 22.22222% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/9\@wide {
    margin-left: 22.22222% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/9\@wide {
    margin-right: 22.22222% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/9\@wide {
    margin-left: -22.22222% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/9\@wide {
    margin-right: -22.22222% !important;
  }
  .u-flex-3\/9\@wide {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/9\@wide {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/9\@wide {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/9\@wide {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/9\@wide {
    margin-right: -33.33333% !important;
  }
  .u-flex-4\/9\@wide {
    flex: 0 0 44.44444% !important;
    max-width: 44.44444% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/9\@wide {
    margin-left: 44.44444% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/9\@wide {
    margin-right: 44.44444% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/9\@wide {
    margin-left: -44.44444% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/9\@wide {
    margin-right: -44.44444% !important;
  }
  .u-flex-5\/9\@wide {
    flex: 0 0 55.55556% !important;
    max-width: 55.55556% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/9\@wide {
    margin-left: 55.55556% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/9\@wide {
    margin-right: 55.55556% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/9\@wide {
    margin-left: -55.55556% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/9\@wide {
    margin-right: -55.55556% !important;
  }
  .u-flex-6\/9\@wide {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/9\@wide {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/9\@wide {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/9\@wide {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/9\@wide {
    margin-right: -66.66667% !important;
  }
  .u-flex-7\/9\@wide {
    flex: 0 0 77.77778% !important;
    max-width: 77.77778% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/9\@wide {
    margin-left: 77.77778% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/9\@wide {
    margin-right: 77.77778% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/9\@wide {
    margin-left: -77.77778% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/9\@wide {
    margin-right: -77.77778% !important;
  }
  .u-flex-8\/9\@wide {
    flex: 0 0 88.88889% !important;
    max-width: 88.88889% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/9\@wide {
    margin-left: 88.88889% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/9\@wide {
    margin-right: 88.88889% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/9\@wide {
    margin-left: -88.88889% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/9\@wide {
    margin-right: -88.88889% !important;
  }
  .u-flex-9\/9\@wide {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/9\@wide {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/9\@wide {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/9\@wide {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/9\@wide {
    margin-right: -100% !important;
  }
  .u-flex-1\/10\@wide {
    flex: 0 0 10% !important;
    max-width: 10% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/10\@wide {
    margin-left: 10% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/10\@wide {
    margin-right: 10% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/10\@wide {
    margin-left: -10% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/10\@wide {
    margin-right: -10% !important;
  }
  .u-flex-2\/10\@wide {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/10\@wide {
    margin-left: 20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/10\@wide {
    margin-right: 20% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/10\@wide {
    margin-left: -20% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/10\@wide {
    margin-right: -20% !important;
  }
  .u-flex-3\/10\@wide {
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/10\@wide {
    margin-left: 30% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/10\@wide {
    margin-right: 30% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/10\@wide {
    margin-left: -30% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/10\@wide {
    margin-right: -30% !important;
  }
  .u-flex-4\/10\@wide {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/10\@wide {
    margin-left: 40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/10\@wide {
    margin-right: 40% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/10\@wide {
    margin-left: -40% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/10\@wide {
    margin-right: -40% !important;
  }
  .u-flex-5\/10\@wide {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/10\@wide {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/10\@wide {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/10\@wide {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/10\@wide {
    margin-right: -50% !important;
  }
  .u-flex-6\/10\@wide {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/10\@wide {
    margin-left: 60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/10\@wide {
    margin-right: 60% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/10\@wide {
    margin-left: -60% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/10\@wide {
    margin-right: -60% !important;
  }
  .u-flex-7\/10\@wide {
    flex: 0 0 70% !important;
    max-width: 70% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/10\@wide {
    margin-left: 70% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/10\@wide {
    margin-right: 70% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/10\@wide {
    margin-left: -70% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/10\@wide {
    margin-right: -70% !important;
  }
  .u-flex-8\/10\@wide {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/10\@wide {
    margin-left: 80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/10\@wide {
    margin-right: 80% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/10\@wide {
    margin-left: -80% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/10\@wide {
    margin-right: -80% !important;
  }
  .u-flex-9\/10\@wide {
    flex: 0 0 90% !important;
    max-width: 90% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/10\@wide {
    margin-left: 90% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/10\@wide {
    margin-right: 90% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/10\@wide {
    margin-left: -90% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/10\@wide {
    margin-right: -90% !important;
  }
  .u-flex-10\/10\@wide {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/10\@wide {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/10\@wide {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/10\@wide {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/10\@wide {
    margin-right: -100% !important;
  }
  .u-flex-1\/11\@wide {
    flex: 0 0 9.09091% !important;
    max-width: 9.09091% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/11\@wide {
    margin-left: 9.09091% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/11\@wide {
    margin-right: 9.09091% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/11\@wide {
    margin-left: -9.09091% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/11\@wide {
    margin-right: -9.09091% !important;
  }
  .u-flex-2\/11\@wide {
    flex: 0 0 18.18182% !important;
    max-width: 18.18182% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/11\@wide {
    margin-left: 18.18182% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/11\@wide {
    margin-right: 18.18182% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/11\@wide {
    margin-left: -18.18182% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/11\@wide {
    margin-right: -18.18182% !important;
  }
  .u-flex-3\/11\@wide {
    flex: 0 0 27.27273% !important;
    max-width: 27.27273% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/11\@wide {
    margin-left: 27.27273% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/11\@wide {
    margin-right: 27.27273% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/11\@wide {
    margin-left: -27.27273% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/11\@wide {
    margin-right: -27.27273% !important;
  }
  .u-flex-4\/11\@wide {
    flex: 0 0 36.36364% !important;
    max-width: 36.36364% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/11\@wide {
    margin-left: 36.36364% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/11\@wide {
    margin-right: 36.36364% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/11\@wide {
    margin-left: -36.36364% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/11\@wide {
    margin-right: -36.36364% !important;
  }
  .u-flex-5\/11\@wide {
    flex: 0 0 45.45455% !important;
    max-width: 45.45455% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/11\@wide {
    margin-left: 45.45455% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/11\@wide {
    margin-right: 45.45455% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/11\@wide {
    margin-left: -45.45455% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/11\@wide {
    margin-right: -45.45455% !important;
  }
  .u-flex-6\/11\@wide {
    flex: 0 0 54.54545% !important;
    max-width: 54.54545% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/11\@wide {
    margin-left: 54.54545% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/11\@wide {
    margin-right: 54.54545% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/11\@wide {
    margin-left: -54.54545% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/11\@wide {
    margin-right: -54.54545% !important;
  }
  .u-flex-7\/11\@wide {
    flex: 0 0 63.63636% !important;
    max-width: 63.63636% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/11\@wide {
    margin-left: 63.63636% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/11\@wide {
    margin-right: 63.63636% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/11\@wide {
    margin-left: -63.63636% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/11\@wide {
    margin-right: -63.63636% !important;
  }
  .u-flex-8\/11\@wide {
    flex: 0 0 72.72727% !important;
    max-width: 72.72727% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/11\@wide {
    margin-left: 72.72727% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/11\@wide {
    margin-right: 72.72727% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/11\@wide {
    margin-left: -72.72727% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/11\@wide {
    margin-right: -72.72727% !important;
  }
  .u-flex-9\/11\@wide {
    flex: 0 0 81.81818% !important;
    max-width: 81.81818% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/11\@wide {
    margin-left: 81.81818% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/11\@wide {
    margin-right: 81.81818% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/11\@wide {
    margin-left: -81.81818% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/11\@wide {
    margin-right: -81.81818% !important;
  }
  .u-flex-10\/11\@wide {
    flex: 0 0 90.90909% !important;
    max-width: 90.90909% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/11\@wide {
    margin-left: 90.90909% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/11\@wide {
    margin-right: 90.90909% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/11\@wide {
    margin-left: -90.90909% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/11\@wide {
    margin-right: -90.90909% !important;
  }
  .u-flex-11\/11\@wide {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/11\@wide {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/11\@wide {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/11\@wide {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/11\@wide {
    margin-right: -100% !important;
  }
  .u-flex-1\/12\@wide {
    flex: 0 0 8.33333% !important;
    max-width: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/12\@wide {
    margin-left: 8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/12\@wide {
    margin-right: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/12\@wide {
    margin-left: -8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/12\@wide {
    margin-right: -8.33333% !important;
  }
  .u-flex-2\/12\@wide {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/12\@wide {
    margin-left: 16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/12\@wide {
    margin-right: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/12\@wide {
    margin-left: -16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/12\@wide {
    margin-right: -16.66667% !important;
  }
  .u-flex-3\/12\@wide {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/12\@wide {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/12\@wide {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/12\@wide {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/12\@wide {
    margin-right: -25% !important;
  }
  .u-flex-4\/12\@wide {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/12\@wide {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/12\@wide {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/12\@wide {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/12\@wide {
    margin-right: -33.33333% !important;
  }
  .u-flex-5\/12\@wide {
    flex: 0 0 41.66667% !important;
    max-width: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/12\@wide {
    margin-left: 41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/12\@wide {
    margin-right: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/12\@wide {
    margin-left: -41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/12\@wide {
    margin-right: -41.66667% !important;
  }
  .u-flex-6\/12\@wide {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/12\@wide {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/12\@wide {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/12\@wide {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/12\@wide {
    margin-right: -50% !important;
  }
  .u-flex-7\/12\@wide {
    flex: 0 0 58.33333% !important;
    max-width: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/12\@wide {
    margin-left: 58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/12\@wide {
    margin-right: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/12\@wide {
    margin-left: -58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/12\@wide {
    margin-right: -58.33333% !important;
  }
  .u-flex-8\/12\@wide {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/12\@wide {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/12\@wide {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/12\@wide {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/12\@wide {
    margin-right: -66.66667% !important;
  }
  .u-flex-9\/12\@wide {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/12\@wide {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/12\@wide {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/12\@wide {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/12\@wide {
    margin-right: -75% !important;
  }
  .u-flex-10\/12\@wide {
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/12\@wide {
    margin-left: 83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/12\@wide {
    margin-right: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/12\@wide {
    margin-left: -83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/12\@wide {
    margin-right: -83.33333% !important;
  }
  .u-flex-11\/12\@wide {
    flex: 0 0 91.66667% !important;
    max-width: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/12\@wide {
    margin-left: 91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/12\@wide {
    margin-right: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/12\@wide {
    margin-left: -91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/12\@wide {
    margin-right: -91.66667% !important;
  }
  .u-flex-12\/12\@wide {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-12\/12\@wide {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-12\/12\@wide {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-12\/12\@wide {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-12\/12\@wide {
    margin-right: -100% !important;
  }
  .u-flex-1\/24\@wide {
    flex: 0 0 4.16667% !important;
    max-width: 4.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/24\@wide {
    margin-left: 4.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/24\@wide {
    margin-right: 4.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/24\@wide {
    margin-left: -4.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/24\@wide {
    margin-right: -4.16667% !important;
  }
  .u-flex-2\/24\@wide {
    flex: 0 0 8.33333% !important;
    max-width: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-2\/24\@wide {
    margin-left: 8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-2\/24\@wide {
    margin-right: 8.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-2\/24\@wide {
    margin-left: -8.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-2\/24\@wide {
    margin-right: -8.33333% !important;
  }
  .u-flex-3\/24\@wide {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-3\/24\@wide {
    margin-left: 12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-3\/24\@wide {
    margin-right: 12.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-3\/24\@wide {
    margin-left: -12.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-3\/24\@wide {
    margin-right: -12.5% !important;
  }
  .u-flex-4\/24\@wide {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-4\/24\@wide {
    margin-left: 16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-4\/24\@wide {
    margin-right: 16.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-4\/24\@wide {
    margin-left: -16.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-4\/24\@wide {
    margin-right: -16.66667% !important;
  }
  .u-flex-5\/24\@wide {
    flex: 0 0 20.83333% !important;
    max-width: 20.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-5\/24\@wide {
    margin-left: 20.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-5\/24\@wide {
    margin-right: 20.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-5\/24\@wide {
    margin-left: -20.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-5\/24\@wide {
    margin-right: -20.83333% !important;
  }
  .u-flex-6\/24\@wide {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-6\/24\@wide {
    margin-left: 25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-6\/24\@wide {
    margin-right: 25% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-6\/24\@wide {
    margin-left: -25% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-6\/24\@wide {
    margin-right: -25% !important;
  }
  .u-flex-7\/24\@wide {
    flex: 0 0 29.16667% !important;
    max-width: 29.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-7\/24\@wide {
    margin-left: 29.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-7\/24\@wide {
    margin-right: 29.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-7\/24\@wide {
    margin-left: -29.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-7\/24\@wide {
    margin-right: -29.16667% !important;
  }
  .u-flex-8\/24\@wide {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-8\/24\@wide {
    margin-left: 33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-8\/24\@wide {
    margin-right: 33.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-8\/24\@wide {
    margin-left: -33.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-8\/24\@wide {
    margin-right: -33.33333% !important;
  }
  .u-flex-9\/24\@wide {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-9\/24\@wide {
    margin-left: 37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-9\/24\@wide {
    margin-right: 37.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-9\/24\@wide {
    margin-left: -37.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-9\/24\@wide {
    margin-right: -37.5% !important;
  }
  .u-flex-10\/24\@wide {
    flex: 0 0 41.66667% !important;
    max-width: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-10\/24\@wide {
    margin-left: 41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-10\/24\@wide {
    margin-right: 41.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-10\/24\@wide {
    margin-left: -41.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-10\/24\@wide {
    margin-right: -41.66667% !important;
  }
  .u-flex-11\/24\@wide {
    flex: 0 0 45.83333% !important;
    max-width: 45.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-11\/24\@wide {
    margin-left: 45.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-11\/24\@wide {
    margin-right: 45.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-11\/24\@wide {
    margin-left: -45.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-11\/24\@wide {
    margin-right: -45.83333% !important;
  }
  .u-flex-12\/24\@wide {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-12\/24\@wide {
    margin-left: 50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-12\/24\@wide {
    margin-right: 50% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-12\/24\@wide {
    margin-left: -50% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-12\/24\@wide {
    margin-right: -50% !important;
  }
  .u-flex-13\/24\@wide {
    flex: 0 0 54.16667% !important;
    max-width: 54.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-13\/24\@wide {
    margin-left: 54.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-13\/24\@wide {
    margin-right: 54.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-13\/24\@wide {
    margin-left: -54.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-13\/24\@wide {
    margin-right: -54.16667% !important;
  }
  .u-flex-14\/24\@wide {
    flex: 0 0 58.33333% !important;
    max-width: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-14\/24\@wide {
    margin-left: 58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-14\/24\@wide {
    margin-right: 58.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-14\/24\@wide {
    margin-left: -58.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-14\/24\@wide {
    margin-right: -58.33333% !important;
  }
  .u-flex-15\/24\@wide {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-15\/24\@wide {
    margin-left: 62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-15\/24\@wide {
    margin-right: 62.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-15\/24\@wide {
    margin-left: -62.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-15\/24\@wide {
    margin-right: -62.5% !important;
  }
  .u-flex-16\/24\@wide {
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-16\/24\@wide {
    margin-left: 66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-16\/24\@wide {
    margin-right: 66.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-16\/24\@wide {
    margin-left: -66.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-16\/24\@wide {
    margin-right: -66.66667% !important;
  }
  .u-flex-17\/24\@wide {
    flex: 0 0 70.83333% !important;
    max-width: 70.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-17\/24\@wide {
    margin-left: 70.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-17\/24\@wide {
    margin-right: 70.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-17\/24\@wide {
    margin-left: -70.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-17\/24\@wide {
    margin-right: -70.83333% !important;
  }
  .u-flex-18\/24\@wide {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-18\/24\@wide {
    margin-left: 75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-18\/24\@wide {
    margin-right: 75% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-18\/24\@wide {
    margin-left: -75% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-18\/24\@wide {
    margin-right: -75% !important;
  }
  .u-flex-19\/24\@wide {
    flex: 0 0 79.16667% !important;
    max-width: 79.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-19\/24\@wide {
    margin-left: 79.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-19\/24\@wide {
    margin-right: 79.16667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-19\/24\@wide {
    margin-left: -79.16667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-19\/24\@wide {
    margin-right: -79.16667% !important;
  }
  .u-flex-20\/24\@wide {
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-20\/24\@wide {
    margin-left: 83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-20\/24\@wide {
    margin-right: 83.33333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-20\/24\@wide {
    margin-left: -83.33333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-20\/24\@wide {
    margin-right: -83.33333% !important;
  }
  .u-flex-21\/24\@wide {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-21\/24\@wide {
    margin-left: 87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-21\/24\@wide {
    margin-right: 87.5% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-21\/24\@wide {
    margin-left: -87.5% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-21\/24\@wide {
    margin-right: -87.5% !important;
  }
  .u-flex-22\/24\@wide {
    flex: 0 0 91.66667% !important;
    max-width: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-22\/24\@wide {
    margin-left: 91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-22\/24\@wide {
    margin-right: 91.66667% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-22\/24\@wide {
    margin-left: -91.66667% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-22\/24\@wide {
    margin-right: -91.66667% !important;
  }
  .u-flex-23\/24\@wide {
    flex: 0 0 95.83333% !important;
    max-width: 95.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-23\/24\@wide {
    margin-left: 95.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-23\/24\@wide {
    margin-right: 95.83333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-23\/24\@wide {
    margin-left: -95.83333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-23\/24\@wide {
    margin-right: -95.83333% !important;
  }
  .u-flex-24\/24\@wide {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-24\/24\@wide {
    margin-left: 100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-24\/24\@wide {
    margin-right: 100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-24\/24\@wide {
    margin-left: -100% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-24\/24\@wide {
    margin-right: -100% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-none\@wide {
    margin-left: 0 !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-none\@wide {
    margin-right: 0 !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-none\@wide {
    margin-left: 0 !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-none\@wide {
    margin-right: 0 !important;
  }
}
