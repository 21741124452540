.u-space {
    &--large {
        padding: $large-margin 0;
        @include bp-down('medium'){
            padding: $medium-margin 0;
        }
    }
    &--medium {
        padding: $medium-margin 0;
        @include bp-down('medium'){
            padding: $common-margin 0;
        }
    }
    &--common {
        padding: $common-margin 0;
    }
    &--small {
        padding: $small-margin 0;
    }
    &--small\@small {
        @include bp-down('medium'){
            padding: $small-margin 0;
        }
    }

    &--x-large {
        @include bp('large'){
            padding: 0 $large-margin;
        }
    }
    &--x-minimal\@small {
        @include bp-down('medium'){
            padding: 0 $minimal-margin; 
        }
    }

    &--bottom-none {
        @include bp('medium'){
            padding-bottom: 0;
        }
    }
    &--left-large {
        @include bp('medium'){
            padding-left: $large-margin;
        }
    }

    &--info-mobile {
        @include bp-down('medium'){
            padding: 0 $common-margin;
        }
    }
}