.c-chessboard {
    &__header{
        padding-top: $small-margin;
    }
    &__box{
        background: $gray-halfwhite;
        padding: $small-margin;

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(9),
        &:nth-of-type(10) {
            @include bp('medium'){
                background: $gray-medium;
            }
        }

        &:nth-of-type(odd){
            @include bp('medium'){
                padding-left: $common-margin;
                padding-right: $common-margin;
            }
        }
        &:nth-of-type(even){
            @include bp('medium'){
                padding-left: $small-margin;
                padding-right: $common-margin;
            }
            @include bp-down('medium'){
                background: $gray-medium;
            }
        }
    }
    &__top{
        margin-bottom: $small-margin;
        @include font(sm, semibold, left, $color-gs);
    }
    &__title{
        margin-left: $small-margin;
        
    }
    &__icon{
        width: 55px;
        height: 55px;
    }
}