@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showMenu {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes hideMenu {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}