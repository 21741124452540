.c-hero {
  position: relative;

  &--full {
    min-height: calc(100vh - #{$top-header-height});
    height: calc(100vh - #{$top-header-height});
    min-width: 100%;
    width: auto;
    background-size: cover;
    background-position: center center;

    .c-hero__small-wrapper {
      padding: 4%;
    }
  }

  &--small {
    background: transparent;
    overflow-y: visible;
    margin: $medium-margin 0 0 0;
    @include bp-down('medium'){
      margin: 70px 0 0 0;
    }

    .c-hero__title,
    .c-hero__subtitle {
      @include bp('medium'){
        @include font(xl, bold, center);
      }
      @include bp-down('medium'){
        font-size: 16px;
        line-height: 24px;
        padding: 10px 10px;
      }
    }

    .c-hero__container {
      max-height: 200px;
      @include bp('medium'){
        max-height: unset;
        background: transparent;
      }
    }

    .c-hero__small-wrapper {
      padding: 4%;
    }
  }

  &--with-margin {
    margin-bottom: $medium-margin;
  }

  &__image {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    height: auto;

    &.baner {
      @include bp-down('medium'){
        transform: scale(1.75);
      }
      @include bp-down('small'){
        transform: scale(2.5);
      }
    }
  }

  &__container {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    color: $color-white;
    text-align: center !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include bp('medium'){
      background: transparent;
    }
  }

  &__title,
  &__subtitle {
    margin: 0;
    padding: $minimal-margin $small-margin;
    background: rgba(0,0,0,0.6);
    @include font(md, bold, center);
    @include bp('medium'){
      max-width: max-content;
      margin: auto;
      padding: $minimal-margin $small-margin;
    }
  }

  &__title {
    @include bp('medium'){
      @include font(super, bold, center);
    }
  }

  &__subtitle {
    @include bp('medium'){
      @include font(super, normal, center);
    }
  }

  &__buttons{
    display: flex;
    flex-direction: column;
    margin: $minimal-margin;
    @include bp('small'){
      flex-direction: row;
    }
  }

  &__download-btn{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 50px auto;
    justify-content: center;
    @include bp-down('medium'){
      margin: 30px 0 0;
    }
  }

  &__content {
    display: block;
    width: 100%;
    background: $gray-halfwhite;
  }

  &__introduction {
    padding: $medium-margin $minimal-margin;
    @include bp('medium'){
      padding: $large-margin 0;
      @include font(md, normal, left)
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &--product {
    .c-hero__title {
      background: none;
      @include font(super, bold, left);
      padding: 0;
      margin: 0 0 $small-margin 0;
      @include bp-down('medium'){
        @include font(lg, bold, left);  
      } 
    }
    .c-hero__subtitle {
      background: none;
      @include font(lg, bold, left);
      margin: 0;
      padding: 0;
      @include bp-down('medium'){
        @include font(sm, semibold, left);
      }
    }
    .product-attribute {
      margin-bottom: $common-margin;
      line-height: 1.3;
    }
    .c-hero__image {
      position: static;
      max-width: 100%;
      width: auto;
      height: auto;
      margin: auto;
      @include bp('medium'){
        position: absolute;
        left: 0;
        height: 100%;
        max-height: 100%;
        width: auto;
        max-width: unset;
      }
    }

    @include bp-down('medium'){
      .c-wing:nth-of-type(1){
        order: 2;
      }
      .c-wing:nth-of-type(1){
        order: 1;
      }

      .c-wing::before {
        content: none;
      }
    }
  }

  &__content-wrapper {
    padding: $common-margin $small-margin;
    @include bp('medium'){
      padding: calc(#{$common-margin} * 2) 0;
      margin-right: calc(#{$common-margin} * 2);
    }
  }

  &__short-desc {
      margin: $common-margin 0;
  }

  &__production-icons {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: $common-margin;
    @include bp('medium'){
      margin-bottom: $medium-margin;
      transform: scale(1.8);
    }
  }
}

body.home .c-hero__small-wrapper {
  @include bp('medium'){
    margin-top: 250px;
  }
}
