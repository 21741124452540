.video {
  margin-bottom:  $medium-margin;

  @include bp('medium') {
    margin-bottom: 25px;
  }

  &__player {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: auto;
    width: 100%;
    height: auto;
    position: relative;

    @include bp('medium') {
      max-width: 570px;
      height: 320px;
    }

    iframe {
      animation: fadeIn $global-transition-time;
      animation: fadeIn $global-transition-time;
    }

    &.done {
      transition: $global-transition;
      animation: fadeIn $global-transition-time;
    }
  }

  &__header {
    @include bp('medium') {
      margin-bottom: 90px;
    }
  }

  &--higher {
    @include bp('medium') {
      margin-top: -100px;
    }
  }

  &__c-btn {
    width: 85px;
    height: 85px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    cursor: pointer;
    transition: $global-transition;

    & .oval {
      transition: $global-transition;
    }

    &:hover .oval {
      fill: $color-dark-grey;
    }
  }
}


.c-video {
  &__container {
    .video__player {
      max-width: 770px;
      height: auto;
    }
  }
}