.c-breadcrumbs {
    @include font("xs", "normal", null, $color-gs);
    position: absolute;
    top: -$common-margin;
    z-index: 10;
    @include bp-down('medium'){
        line-height: 24px;
        top: -60px;
    }

    &--static {
      position: static;
    }

    &__separator {
        @include font("xs", "bold", null, $color-gs);
    }

    a {
      transition: $global-transition;
      color: $gray-border;
      text-decoration: none;

      &:hover {
        color: $color-gs;
      }
    }

    & span span * {
      margin-left: 2px;
      margin-right: 2px;
    }

    & span span:first-child {
      margin-left: 0;
    }
}