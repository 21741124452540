.c-photo-file {
    
    &__container {
        width: 100%;
        max-width: 770px;
    }

    &__header {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 35px;
        text-align: center;
        color: white !important;
    }

    &__btn {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
        margin: 0;
        padding: 18px 20px;

        .c-btn__icon {
            margin-left: 7px;
            svg {
                height: 10px;
                width: 10px;
            }
        }
    }

    &__image {
        min-height: 279px;
        height: 279px;
        width: 100%;
        position: relative;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
    }

    &__right {
        height: 100%;
        justify-content: space-evenly;
    }
}