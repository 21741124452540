.c-carousel-timeline {
    position: relative;
    transition: $global-transition;
    
    &::before {
        transition: $global-transition;
        content: '';
        width: 43%;
        height: 30%;
        background: #e1e2e3;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        top: 350px;
        @include bp-down('medium'){
            display: none;
        }
    }


    &__header {
        transition: $global-transition;
        margin: 0 auto;
        text-align: center;
        margin-bottom: $medium-margin;
        @include bp('medium'){
            padding-bottom: $medium-margin !important;
        }
    }
    &__line{
        transition: $global-transition;
        position: relative;
        height: 1px;
        overflow: visible;
        background: $color-light-grey;
        width: 85%;
        margin: 10px auto;
        padding: 0 $minimal-margin;
        align-items: flex-end;
        @include bp-down('medium'){
            width: 95%;
            padding: 0;
            margin-bottom: 30px;
        }
    }
    &__nav {
        transition: $global-transition;
        position: relative;
    }

    &__arrow{
        transition: $global-transition;
        position: absolute;
        bottom: -13px;
        cursor: pointer;
        display: block;
        @include bp-down('medium'){
            bottom: -50px;
        }
        
        svg {
            transition: $global-transition;
            height: auto;
            width: 30px;
        }
        
        &--right {
            right: 20px;
            @include bp-down('medium'){
                right: 80px;
            }
        }    
        &--left {
            left: 20px;
            @include bp-down('medium'){
                left: 80px;
            }
        }
    }

    &__step {
        transition: $global-transition;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -50px;
        transform: translateY(12px);
        cursor: pointer;
        @include bp-down('medium'){
            margin-top: -45px;
        }

        &.is-active{
            transition: $global-transition;
            .c-carousel-timeline__point {
                pointer-events: none;
                transition: $global-transition;
                background: $color-gs;
            }
            .c-carousel-timeline__label {
                pointer-events: none;
                transition: $global-transition;
                @include font(xxs, bold, center, $color-gs);
                display: block;
                @include bp-down('medium'){
                    @include font(xxs, bold, center, $color-gs);
                }
            }
        }
    }
    &__point {
        transition: $global-transition;
        background: transparent;
        border-radius: 50%;
        margin: auto;
        margin-top: 20px;
        pointer-events: none;
        z-index: -1;
        circle{
            pointer-events: none;
            transition: $global-transition;
            fill: $color-gold;
        }
    }
    &__label {
        pointer-events: none;
        transition: $global-transition;
        max-width: min-content;
        min-width: 60px;
        @include font(xxs, semibold, center, $color-medium-grey);
        @include bp-down('medium'){
            display: none;
            @include font(xxs, normal, center, $color-medium-grey);
        }
    }
    &__image{
        transition: $global-transition;
        min-height: 330px;
        height: 330px;
        width: 100%;
        position: relative;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        @include bp-down('medium'){
            margin-bottom: $small-margin;
        }
    }
    &__caption {
        transition: $global-transition;
        @include font(sm, normal, left, $color-black);
        position: static;
        background: transparent;
        min-height: 86px;
        display: flex;
        align-items: center;
        justify-content: start;
        display: flex !important;
        align-items: center;
        padding: 15px 30px;
        @include bp-down('small'){
            align-items: flex-start;
            min-height: 30px;
            padding: 15px 0;
        }
    }
    &__slide {
        width: 100%;
        display: none;

        &.is-active {
            display: block;
        }

        .slick-track {
            display: flex;
            justify-content: center;
        }
    }
    &__container {
        min-height: 430px;
        display: flex;
        align-items: center;
        @include bp('medium'){
            margin-top: $medium-margin;
        }
    }
    &__line, &__container {
        @include bp('medium'){
            // transform: translateY($large-margin);
        }
    }

    & .c-carousel-news__items {
        background-color: transparent;
    }

    &__arrow--secondary {
        position: absolute;
        top: 40.5%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 99;
    }

    &.stepsnames-extended {
        .c-carousel-timeline__step {
            .c-carousel-timeline__label {
                font-size: 16px !important;
                font-weight: 300 !important;
                letter-spacing: 0;
                max-width: max-content !important;
            }

            &.is-active {
                .c-carousel-timeline__label {
                    font-weight: bold !important;
                }
            }
        }
    }

    &.graybar-extended {
        &::before{
            content: '';
            height: 115%;
            top: 270px;
        }
    }
}