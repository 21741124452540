.gallery {
  &__header {
    margin-bottom: $medium-margin;
  }
  &__photos {
    display: flex; 
    flex-direction: row;
    @include bp('medium'){
      justify-content: space-between;
    }
    @include bp-down('medium'){
      flex-grow: 1;
      flex-wrap: wrap;
    }
  }
  &__small-img {
    margin-bottom: $small-margin;
    max-width: 100%;
    width: 100%;
    @include bp('medium') {
      max-width: 370px;
    }
  }
  &__btn {
    display: block;
    margin: $small-margin auto;
  }

  & .c-production-gallery__item {
    @include bp-down('medium'){
      flex-grow: 1;
      max-width: 100%;
      width: 48%;
      margin: 1px;
    }
  }
} 
