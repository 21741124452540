/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();
  margin-right: auto;
  margin-left: auto;
  width: $wrapper-small;
  @include bp('xlarge'){
    max-width: $wrapper-fullhd;
    width: $wrapper-fullhd;
  }

  &--wider {
    @include bp('large'){
      width: 110%;
      transform: translateX(-4.5%);
    }
  }
}
