.c-tiles {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        position: relative;
        flex-grow: 1;
        width: 100%;
        height: 60vh;
        margin-bottom: $minimal-margin;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        @include bp('medium'){
            width: 45%;
            margin: 1px;
            height: 49vh;
            margin-bottom: 1px;
        }
    }
    &__overlay {
        position: absolute;
        height: auto;
        width: 100%;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        background: rgba(0,0,0,0.5);
        padding: $common-margin $common-margin;
        @include bp-down('full'){
            height: 70%;
            display: flex;
            flex-direction: column;
        }
    }

    &__label {
        display: block;
        background: $color-gold;
        width: max-content;
        margin: auto;
        padding: 0 $small-margin;
        border-radius: 5px;
        @include font(sm, bold, center, $color-dark-grey);
        margin-bottom: $minimal-margin;
    }

    &__title {
        @include font(lg, bold, center, $color-white);
        margin-bottom: $minimal-margin;
        
    }

    &__subtitle {
        @include font(sm, normal, center, $color-white);
    }

    &__btn {
        @include font(xs, bold, center);
        border-radius: 2px;
        display: block;
        margin: auto;
    }
}