/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  width: auto;
  letter-spacing: 0;
  background-color: $color-gs;
  text-decoration: none;
  
  transition: $global-transition;
  width: max-content;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  @include font(sm, bold, center, $color-white);

  @include bp('medium') {
    padding: 12px 35px;
  }
  
  svg {
    fill: $color-white;
  }

  /* Style variants
  ========================================================================== */
  &--larger {
    justify-content: center;
    width: 100%;
    padding: 15px 30px;
    @include font(sm, bold, center);

    @include bp('medium') {
      padding: 25px 30px;
      @include font(md, bold, center);
      width: max-content;
    }
  }

  &--extra-wide {
      display: block;
      padding: $small-margin $large-margin;
  }

  &--full-width {
    @include bp-down('small'){
      width: calc(100vw - #{$medium-margin});
      margin-top: $small-margin;
    }
  }

  &--smaller {
    padding: 7px 35px !important;
  }

  &--corner {
    position: absolute;
    bottom: 0;
    right: 0;

    @include bp('medium') {
      left: 0;
      right: auto;
    }
  }

  &--with-icon {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-left: 20px;
  }

  &--white {
    background: $color-white;
    color: $color-gs;

    svg {
      transition: $global-transition;
      fill: $color-gs !important; 
    }

    &:hover {
      transition: $global-transition;
      background: $color-gs;
      color: $color-white;
  
      svg {
        transition: $global-transition;
        fill: $color-white !important; 
      }
    }
  }

  &--marged {
    margin: $minimal-margin 0 0 0;
    @include bp('medium'){
      margin: 0 7px;
    }
  }

  &--p-less {
    padding: $minimal-margin;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-white;
  }

  &:hover,
  &:focus {
    background-color: $color-dark-grey;
  }
}
