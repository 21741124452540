/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
  @include font(lg, bold, center);
  padding-bottom: 0; 
  @include bp(medium){
    @include font(lg, bold, center);
  }
}

h2 {
  
  @include font(lg, bold, left);
  @include bp('medium'){
    @include font(lg, bold, left);
    margin-bottom: $medium-margin;
  }
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}