.c-carousel-news {
  position: relative;
  &__header {
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
  }

  &__carousel {
    position: relative;
  }

  &__item {
    width: 90% !important;
    height: 265px;
    max-width: 100%;
    padding: 0;
    margin: 0 5%;
    position: relative;
    cursor: pointer;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    
    &-desc {
      transition: $global-transition;
      @include font(lg, bold, center, $color-white);
      
      background: rgba(0,0,0,0.5);
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      padding: $common-margin;
      flex-direction: column;
      justify-content: space-between;

      @include bp-down('medium'){
        font-size: 20px;
      }
    }
    
    &.is-active {
      transition: $global-transition;
      .c-carousel-news__item-desc {
        transition: $global-transition;
        background: rgba(#FEC314,0.75);
        color: $color-black;
      }
    }

    &-subtitle{
      @include font(sm, normal, center);
      margin-top: $small-margin;
      margin-bottom: $minimal-margin;
      text-transform: none;
    }
  }

  &__items {
    background-color: #fff;

    .slick-active {
      opacity: 1;
    }

    &--second{
      position: absolute;
      width: 100%;
      top: 0;
      z-index: -1;
      opacity: 0.3;
      @include bp-down('large'){
        display: none;
      }

      .slick-slide {
        transform: scale(0.8);
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 99;

    svg {
      width: 44px;
      height: 20px;
    }
    &--right {
      right: -5px;
      @include bp('medium'){
        right: -45px;
      }
    }
    &--left {
      left: -5px;
      @include bp('medium'){
        left: -45px;
      }
    }
  }

  &__article {
    padding-left: 5% !important;
    padding-right: 5% !important;
    @include bp('medium'){
      padding-left: 1% !important;
      padding-right: 1% !important;
    }
    &-title {
      @include font(xl, bold, left);
      @include bp('medium'){
        @include font(super, bold, left);
        margin-bottom: $medium-margin;
      }
    }

    &-content {
      @include font(sm, normal, left);
      @include bp('medium'){
        @include font(md, normal, left);
      }
      p {
        margin-bottom: $small-margin;
        @include bp('medium'){
          margin-bottom: $medium-margin;
        }
      }
      h2, h3 {
        margin: $small-margin 0;
        @include font(md, bold, left);
        @include bp('medium'){
          margin: $medium-margin 0;
          @include font(lg, bold, left);
        }
      }
    }
  }

  &__date {
    @include font(xs, bold, center);
    margin-bottom: $small-margin;
  }
}

.c-carousel-timeline__slide {
  .c-carousel-news__item {
    max-width: 100%;
    @include bp('medium'){
      margin: 0 5%;
      width: 90% !important;
      height: 300px !important;
    }
    @include bp-down('medium'){
      margin: 0;
      width: 100% !important;
    }
  }

  .c-carousel-news__arrow--right {
    @include bp-down('medium'){
      right: -25px !important;
    }
  }

  .c-carousel-news__arrow--left {
    @include bp-down('medium'){
      left: -25px !important;
    }
  }
}