.c-newsletter {
  background-color: $gray-halfwhite;

  &__image {
    padding-top: 55px;
    height: 370px;
    color: white;
    background: url("../assets/images/newsletter.png");
    margin-bottom: 30px;
    width: 100%;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;

    @include bp('medium') {
      margin-bottom: 0;
      margin-right: 30px;
    }

    @include bp('xlarge') {
      width: 470px !important;
      margin-right: 128px;
    }
  }

  &__heading {
    position: absolute;
    top: 35%;
    right: unset;
    bottom: 0;
    left: 20%;
    z-index: 10;
    margin: auto;
    max-width: max-content;
    width: 100%;

    @include bp('medium') {
      width: 50%;
    }
  }

  &__header {
    margin-bottom: 15px;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
  }

  &--higher {
    @include bp('medium'){
      margin-top: -150px;
    }
  }

  &__form {
    @include bp-down('medium'){
      padding: 0 $small-margin !important;
    }
    .wpcf7-response-output {
      @include bp-down('medium'){
        transform: translateY(-30px);
      }
      transform: translateY(-10px);
    }
  }
}
