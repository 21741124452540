.product-details {
  display: flex;
  flex-direction: column;

  &__expose {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    @include bp('xlarge') {
      flex-direction: row;
    }

    &-tab {
      cursor: pointer;
      transition: $global-transition;
      @include bp-down('medium'){
        margin-bottom: $small-margin;
      }
      &:hover {
        transform: scale(1.02);
      }

      &.is-active {
        transition: $global-transition;
        .product-details__expose-name {
          background: $color-gold;
        }
      }
    }

    &-img {
      transition: $global-transition;
      pointer-events: none;
      min-height: 265px;
      height: 265px;
      width: 100%;
      position: relative;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: cover !important;
    }

    &-name {
      transition: $global-transition;
      padding: $small-margin;
      background: $color-white;
      min-height: 105px;
      height: 105px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      justify-content: center;
      align-content: center;
      pointer-events: none;
    }

    &-title {
      transition: $global-transition;
      @include font(md, bold, center, $color-black);
      
      margin-bottom: 5px;
      line-height: 1;
      pointer-events: none;
    }

    &-subtitle {
      transition: $global-transition;
      @include font(md, normal, center, $color-black);
      line-height: 1;
      pointer-events: none;
    }

    &-icons {
      transition: $global-transition;
      pointer-events: none;
      height: 100%;

      svg, img {
        max-width: 70px;
        width: 70px;
        height: auto;
        margin: $minimal-margin auto;
      }
    }
  }

  &__content {
    margin-bottom: $medium-margin;

    &.is-unactive {
      display: none !important;
    }
  }

  &__tab {
    flex-direction: column;
    display: none;
    transition: $global-transition;

    @include bp('medium') {
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }

    &.active {
      display: flex;
    }
  }

  &__tech-img {
    margin: 30px auto;
    transition: all 250ms ease-in-out;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    @include bp('xlarge'){
      margin: 0;
      max-width: 650px;
      position: absolute;
      width: auto;
      height: auto;
      max-height: 90%;
      right: -160px;
    }
  }

  &__table {
    transition: $global-transition;
    display: table;
    margin: auto;
    border-collapse: collapse;
    width: auto;
    min-width: 80vw;

    @include bp('large') {
      min-width: 800px;
    }

    tr:nth-child(odd) {
      background-color: $gray-light;
    }

    td,
    th {
      padding: 20px;

      &:first-of-type {
        font-weight: bold;
      }
    }

    th {
      background-color: $color-gs;
      color: $color-white;
    }
  }

  &__table,
  &__table th,
  &__table td {
    border: 1px solid $color-dark-grey-transparent;
  }

  &__tech-img + &__table {
    @include bp('medium') {
      max-width: 57%;
    }
  }

  &__material {
    transition: all 250ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;

    img {
      height: 90px;
      width: 90px;
      border-radius: 50%;
      margin-bottom: $small-margin; 
    }
  }

  &__file {
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid $gray-medium;
    flex-grow: 1 !important;
    max-width: 100% !important;

    &-image {
      max-width: 57px;
      max-height: 57px;
    }
    &-title {
      @include font(sm, normal, left);
      margin-left: 20px;
    }
    &-link{
      text-decoration: none;
      color: $color-black;
      transition: $global-transition;
      &:hover {
        color: $color-gs;
      }
    }

    &.o-flex--grow {
      flex-grow: 1 !important;
      max-width: 100% !important;
    }

  }

  &__file--bigger {
    @include bp-down('small'){
      flex-direction: column;
      text-align: center;
    }

    .product-details__file-image {
      max-width: 267px;
      max-height: 300px;
    }
    .product-details__file-title {
      @include bp-down('small'){
        display: block;
        text-align: center;
      }
    }
  }

  &--flex {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }

  &--files {
    flex-direction: row;
    width: 100%;
  }

  &--bottom.active {
    animation: fadeIn $global-transition-time;
  }

  &--wrapped {
    flex-wrap: wrap;

    .product-details__material {
      margin-bottom: $common-margin;
      width: 50%;
      @include bp('small'){
        width: 30%;
      }
      @include bp('medium'){
        width: auto;
      }
    }
  }
}
