/* ==========================================================================
   #FOOTER
   ========================================================================== */

.footer {

  &-blue {
    & > div {
      @include bp-down('large'){
        flex-direction: column !important;

        .c-header__login-btn {
          margin: 20px;
        }
      }
    }

    &__credits {
      transition: $global-transition;
      @include font(xs, normal, center, $color-very-light-grey);
      font-size: 12px;
      text-decoration: none;
      @include bp-down('small'){
        display: block;
        margin: $small-margin 0 0;
        text-align: left;
      }
      
      &:hover {
        transition: $global-transition;
        color: $color-gold;
      }
    }
  }

  padding: $small-margin;
  @include bp('medium'){
    padding: 0;
    margin-top: $medium-margin;
  }


  &__logo {
    margin-bottom: $common-margin;
    @include bp('medium'){
      padding: 25px 0 0 0 !important;
    }

    svg {
      display: block;
      height: auto;
      width: auto;
      @include bp('medium'){
        height: 55px;
        width: auto;
      }
    }
  }

  &__menus {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include bp('medium') {
      flex-direction: row;
    }
  }

  &__menu {
    text-decoration: none;
    list-style: none;
    padding: 0;
    margin-left: 0;
    margin-bottom:  $medium-margin;

    @include bp('medium') {
      margin-bottom: 0;
    }

    &-header {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;

      @include bp('medium') {
        margin-bottom: 50px;
      }

    }

    li a {
      font-size: 16px;
      line-height: 19px;
      color: $color-gs;
      text-decoration: underline;
    }
  }

  &__sm-links {
    display: flex;
    margin-bottom:  $medium-margin;
    @include bp('medium') {
      margin-bottom: 0;
    }

    ul {
      @include bp('medium'){
        margin-left: 0;
      }
    }

    a img {
      max-width: 24px;
    }
  }

  &__sm-link {
    margin-left: 30px;

    &:first-of-type {
      margin-left: 0;
    }
  }
}
