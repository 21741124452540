.product-attribute {
  display: flex;
  align-items: center;
  margin-bottom: $small-margin;

  @include bp('medium') {
    margin-bottom: $small-margin;
  }

  &:first-of-type {
    @include bp('medium') {
      margin-top: $common-margin;
    }
  }

  &:last-of-type {
    margin-bottom: $common-margin;
  }

  &__icon {
    width: 57px;
    height: 57px;
    margin-right: 35px;
  }

  &__value {
    display: block;
    width: calc(100% - 100px);
  }
}
