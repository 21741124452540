.c-network {
    &__map {
        text-align:right;
        svg {
            width: 85%;
            height: auto;
            
            .is-current {
                transition: $global-transition;
                fill: $color-gs !important;
                opacity: 1 !important;
            }
            
            .is-active {
                fill: $color-gold;
                cursor: pointer;
                @include bp('medium'){
                    opacity: 0.8;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
    
    &__filter-box {
        background: $color-blue;
        padding: $common-margin $medium-margin;
    }

    &__title {
        @include font(md, bold, left, $color-white);
        margin-bottom: $minimal-margin;
        
    }
    &__subtitle {
        @include font(sm, normal, left, $color-white);
    }
    &__box {
        @include font(sm, normal, left, $color-white);
    }
    &__label {
        @include font(xs, bold, left);
        margin-bottom: 17px;
    }

    &__select {
        width: 100%;
        background: transparent;
        border: 1px solid $color-white;
        padding: 15px 10px;
        color: $color-white;
    }

    &-item {
        &__title {
            background: $gray-medium;
            padding: $small-margin;
            @include font(md, bold, center, $color-gs);
        }

        &__data {
            min-height: 130px;
            @include font(xxs, normal, center);            
        }

        &__data-container {
            padding: $small-margin $minimal-margin;
            border: 1px solid $gray-medium;
        }

        &__btn {
            padding: $minimal-margin $small-margin;
        }
    }
}