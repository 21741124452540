.c-archive {
    &__container {
        position: relative;
    }

    .c-breadcrumbs {
        position: absolute;
        left: 0;
        top: $small-margin;
    }

    &__title {
        @include font(super, bold, center);
        margin-bottom: 0;
    }

    &__subtitle {
        background: none;
        @include font(xl, bold, center);
    }

    &__productions {
        display: flex;
        flex-wrap: wrap;
        margin-top: $medium-margin;

        .c-carousel-productions__item {
            max-width: 100%;
            @include bp('medium'){
                max-width: 50%;
            }
        }

        .c-carousel-productions__item-image {
            @include bp-down('medium'){
                height: 260px;
            }
        }
    }
}