.c-map-contact {
    @include bp('medium'){
        margin-bottom: -150px;
        margin-top: -230px;
        z-index: 10;
        position: relative;
    }

    &__header {
        @include bp('large'){
            margin: 120px 0 50px !important;
        }
    }

    &__canvas {
        height: 700px;
    }
}
