.c-carousel-research {
  position: relative;

  &__header {
    margin: auto;
    text-align: center;
    margin-bottom: $common-margin;
    @include bp('medium'){
      margin-bottom: $medium-margin;
    }
  }

  &__carousel {
    position: relative;
  }

  &__item {
    width: 90% !important;
    height: auto;
    max-width: 100%;
    padding: 0;
    margin: 0 5%;
    position: relative;
    cursor: pointer;
    
    &-icon{
      width: 100%;
      height: 265px;
      background-repeat: no-repeat;
      background-size: 115px;
      background-position: center;
      background-color: $color-very-light-grey;
    }
    
    &-desc {
      transition: $global-transition;
      @include font(md, bold, center, $color-gs);
      
      background: $gray-halfwhite;
      height: 180px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: $common-margin $common-margin;
      flex-direction: column;
      justify-content: space-between;
      line-height: 1.2;

      a{
        @include font(md, bold, center, $color-gs);
        text-decoration: none;
      }


    }
    
    &.is-active {
      transition: $global-transition;
    }

    &-subtitle{
      @include font(sm, normal, center);
      margin-top: $small-margin;
      text-transform: none;
    }
  }

  &__btn {
    color: $color-white !important;
  }

  &__items {
    background-color: #fff;

    .slick-active {
      opacity: 1;
    }

    .is-current-post{
      .c-carousel-research__item-desc,
      .c-carousel-research__item-icon {
        background-color: $color-gold !important;
        color: $color-black !important;
      }
    }

    &--second{
      position: absolute;
      width: 100%;
      top: 30px;
      z-index: -1;
      opacity: 0.3;
      @include bp-down('large'){
        display: none;
      }

      .slick-slide {
        opacity: 0.8 !important;
        transform: scale(0.8);
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 99;

    svg {
      width: 44px;
      height: 20px;
    }
    &--right {
      right: -5px;
      @include bp('medium'){
        right: -45px;
      }
    }
    &--left {
      left: -5px;
      @include bp('medium'){
        left: -45px;
      }
    }
  }

  &__article {
    padding-left: 5% !important;
    padding-right: 5% !important;
    @include bp('medium'){
      padding-left: 1% !important;
      padding-right: 1% !important;
    }
    &-title {
      @include font(xl, bold, left);
      @include bp('medium'){
        @include font(super, bold, left);
        margin-bottom: $medium-margin;
      }
    }

    &-content {
      @include font(sm, normal, left);
      @include bp('medium'){
        @include font(md, normal, left);
      }
      p {
        margin-bottom: $small-margin;
        @include bp('medium'){
          margin-bottom: $medium-margin;
        }
      }
      h2, h3 {
        margin: $small-margin 0;
        @include font(md, bold, left);
        @include bp('medium'){
          margin: $medium-margin 0;
          @include font(lg, bold, left);
        }
      }
    }
  }
}

body.single-research {
  .c-carousel-research {
    &__items {
      &--second{
        top: -50px;
      }

      a{
        @include font(md, bold, center, $color-gs);
        text-decoration: none;
      }
    }
  }
}