.form {
  transition: $global-transition;

  &__container {
    transition: $global-transition;
    width: 100%;
    padding: 10px !important;

    @include bp('medium') {
      max-width: 472px;
    }
  }

  label {
    font-size: 16px;
    line-height: 24px;
    display: none;
  }

  &__input {
    transition: $global-transition;
    width: 100%;
    border: 1px solid $color-dark-grey;
    margin-bottom: 35px;
    color: $color-black;
  }
  
  &__input,
  &__input::placeholder {
    background-color: $color-white;
    color: $color-black !important;
    opacity: 1;
    font-size: 16px;
    line-height: 24px;
  }

  &__input {
    color: $color-black;
    padding: 15px;
  }

  &__form-info {
    font-size: 14px;
    margin: -20px 0 20px;
  }

  &__textarea {
    height: 160px;
  }

  &__acceptance {
    transition: $global-transition;
    visibility: hidden;
    position: absolute;
    left: -3333333em;

    &~.wpcf7-list-item-label {
      transition: $global-transition;
      display: inline-block;
      font-size: 12px;
      line-height: 20px;
      width: max-content;
      margin-bottom: 0;
      cursor: pointer;
      max-width: calc(85vw - 50px);
      transform: translateY(-5px);

      @include bp('medium') {
        max-width: calc(40vw - 100px);
      }

      @include bp('xlarge') {
        max-width: 400px;
      }
    }

    &+&--faked::before {
      transition: $global-transition;
      content: "";
      display: inline-block;
      background-color: $color-white;
      background: url("../assets/images/not-checked.png");
      width: 24px;
      height: 24px;
      margin-right: 10px;
      border: 1px solid $color-dark-grey;
    }

    &:checked+&--faked::before {
      transition: $global-transition;
      content: "";
      background: url("../assets/images/checked.png");
    }
  }

}

/*
 * Contact form 7
 */
span.wpcf7-list-item {
  display: block;
  margin: 0 !important;
}

.wpcf7-response-output {
  transition: $global-transition;
  animation: fadeIn $global-transition-time;
  display: block;
  font-size: 14px;
  line-height: 1.5;
  border: none;
  font-weight: bold;
  text-align: center;
  padding: 15px 10px !important;
  margin: 0 !important;
  border: none !important;
  margin-top: 30px !important;

  .wpcf7 form.invalid &,
  .wpcf7 form.failed &,
  .wpcf7 form.unaccepted & {
    transition: $global-transition;
    animation: fadeIn $global-transition-time;
    background: $color-warning;
    color: $color-white;
  }

  .wpcf7 form.success &,
  .wpcf7 form.sent & {
    transition: $global-transition;
    animation: fadeIn $global-transition-time;
    background: $color-success;
    color: $color-white;
  }
}

.wpcf7-not-valid-tip {
  transition: $global-transition;
  animation: fadeIn $global-transition-time;
  font-size: 12px !important;
  line-height: 1.2 !important;
  color: $color-warning !important;
  text-align: right;
  margin-top: -55px;
  margin-right: 5px;
}

.wpcf7-form-control.wpcf7-submit {
  margin: 0 0 0 auto;
  display: block;

  @include bp('medium') {
    margin: 0;
    display: inline;
  }
}

.wpcf7-form-control.wpcf7-acceptance label {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.contact-form__submit {
  margin: 30px auto 0 !important;
  display: flex !important;
}