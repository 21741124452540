.c-tbg {
    &__background {
        background-position-y: center;
        background-position-x: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__wrapper {
        padding: $large-margin 0;
        @include bp('medium'){
            width: 45%;
        }
    }

    &__header {
        margin-bottom: $common-margin;
    }

    &__text {
        @include font(md, normal, left);
        margin-bottom: $common-margin;
    }

    &__btn {
        display: block;
    }
}