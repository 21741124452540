.c-wing {
  display: block;
  width: 100%;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 2.2rem;
  padding: 0;
  position: relative;

  @include bp('large') {
    flex-direction: row;
    font-size: 20px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-items: start;
    position: relative;

    @include bp('large') {
      flex-direction: row;
    }
  }

  &--left {
    position: relative;
    background-color: $gray-light;

    @include bp('large') {
      padding: 65px 65px 65px 0;
      width: 48%;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        background-color: $gray-light;
        width: 100vw;
        transform: translateX(-100%);
      }
    }
  }

  &--right {
    position: relative;
    background-color: $gray-medium;

    @include bp('large') {
      padding: 65px 30px 65px 30px;
      width: 51%;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        background-color: $gray-medium;
        width: 100vw;
        transform: translateX(100%);
      }
    }
  }

  &--shorter {
    height: max-content;
  }

  &--flat {
    @include bp('large') {
      padding-top: $medium-margin !important;
    }
  }

  &--transparent {
    background-color: transparent;

    &::after,
    &::before {
      display: none;
    }
  }

  &--white {
    background-color: $color-white;
    &::after,
    &::before {
      background-color: $color-white;
    }
  }

  &--p-less {
      padding: 0 !important;
  }

  &--p-less-up {
      @include bp('large'){
        padding: 0 !important;
      }
  }

  &--flex {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: start;
    justify-items: start;
  }

  &--absolute-content {
    height: 100%;
    min-height: 100%;
    overflow: hidden;
  }

  &--wider {
    @include bp('large'){
      width: 55%;
    }
  }

  &--wider-left {
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100vw;
      transform: translateX(-100%);
      background-color: inherit;
    }
  }

  &--wider-right {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100vw;
      transform: translateX(100%);
    }
  }

  &--80 {
    @include bp('medium'){
      width: 75%;
    }
  }
}

