.product-nav {
  transition: $global-transition;
  list-style: none;
  display: flex;
  flex-direction: column;
  background: $gray-light;
  padding: 0;
  width: 100%;
  margin: 0;
  margin-top: $common-margin;

  @include bp('medium') {
    flex-direction: row;
    justify-content: flex-start;
    margin-top: $medium-margin;
  }

  & li {
    display: block;
    background: $gray-medium;
    color: $color-black;
    transition: $global-transition;
    @include font(sm, semibold, center);
    
    padding: 15px $small-margin;
    margin: 0;
    margin-right: 15px;
    cursor: pointer;
    @include bp-down('medium'){
      margin-bottom: $minimal-margin;
      margin-right: 0;
    }

    &.active {
      color: $color-white;
      background-color: $color-gs;
    }
  }
}
