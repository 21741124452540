.c-portrait {

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: $common-margin;
        align-items: center;
    }

    &__image,
    &__content {
        position: relative;
        flex-grow: 1;
        width: 100%;
        height: auto;
        margin-bottom: $minimal-margin;
        @include bp('medium'){
            width: 45%;
            height: auto;
        }
    }

    &__image {
        background: $gray-halfwhite;
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        justify-content: center;

        img {
            transform: translateY(-20%);
            max-width: 65%;
            height: 100%;
            width: 100%;
        }
    }

    &__content {
        @include bp-down('medium'){
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__header {
        text-align: center;
        margin-bottom: calc(#{$large-margin} * 1.75);
        @include bp-down('medium'){
            margin-bottom: calc(85px * 1);
        }
    }
    &__content {
        text-align: center;
    }

    &__name {
        @include font(md, bold, left);
        display: block;
        margin-bottom: 0;
        
    }

    &__position {
        @include font(md, normal, left);
        display: block;
        
    }

    &__text {
        @include font(sm, normal, left);
        display: block;
        margin: $small-margin 0;
    }

    &__btn {
        display: block;
        margin: 0;
        @include bp('medium'){
            margin-top: $common-margin;
        }
    }

}